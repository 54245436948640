import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";
import { MdClose } from "react-icons/md";
import { AppContext } from "../../App";
import "../css/modal-user-statistics.css";
import { getBaseUrl } from "../../baseURL";

const LearnerTableHeaderOngoing = () => {
  return (
    <div className="user-table">
      <div className="column-name">
        <div className="column-box">
          <p>SESSIONS</p>
        </div>
        <div className="column-box">
          <p>ENROLLED ON</p>
        </div>
        <div className="column-box">
          <p>COMPLETION DATE</p>
        </div>
      </div>
    </div>
  );
};
const LearnerTableHeaderUpcoming = () => {
  return (
    <div className="user-table">
      <div className="column-name">
        <div className="column-box">
          <p>SESSIONS</p>
        </div>
        <div className="column-box">
          <p>ENROLLED ON</p>
        </div>
        <div className="column-box">
          <p>COMPLETED</p>
        </div>
      </div>
    </div>
  );
};

const InstructorTableHeaderOngoing = () => {
  return (
    <div className="user-table">
      <div className="column-name">
        <div className="column-box">
          <p>SESSIONS</p>
        </div>
        <div className="column-box">
          <p>TEACHING ON</p>
        </div>
        <div className="column-box">
          <p>COMPLETION DATE</p>
        </div>
      </div>
    </div>
  );
};

const InstructorTableHeaderUpcoming = () => {
  return (
    <div className="user-table">
      <div className="column-name">
        <div className="column-box">
          <p>SESSIONS</p>
        </div>
        <div className="column-box">
          <p>TEACHED ON </p>
        </div>
        <div className="column-box">
          <p>COMPLETED</p>
        </div>
      </div>
    </div>
  );
};

const ModalUserStatistics = ({ toggleUserStat, user }) => {
  const [viewStats, setViewStats] = useState("learner");
  const [isShow, setisShow] = useState(true);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleAnimate = () => {
    setisShow(false);
    toggleUserStat();
  };

  return (
    <div
      className={`overlay ${isShow ? "fade-in" : "fade-out"}`}
      onClick={handleAnimate}
    >
      <div
        className={`modal-content ${isShow ? "fade-in" : "fade-out"}`}
        onClick={handleModalClick}
      >
        <div className="title-container">
          <MdClose
            id="back-icon"
            onClick={() => {
              setisShow(false);
              toggleUserStat();
            }}
          />
          <h1>USER</h1>
        </div>
        <div className="info-container">
          <div className="user-info">
            {user.role === "admin" || user.role === "instructor" ? (
              <div className="role-info">
                <div
                  className={`type-box ${
                    viewStats === "learner" ? "active" : ""
                  }`}
                  id="learner-type-box"
                  onClick={() => setViewStats("learner")}
                >
                  <p>learner data</p>
                </div>
                <div
                  className={`type-box ${
                    viewStats === "instructor" ? "active" : ""
                  }`}
                  id="instructor-type-box"
                  onClick={() => setViewStats("instructor")}
                >
                  <p>instructor data</p>
                </div>
              </div>
            ) : null}

            {user.role === "learner" ? (
              <div className="role-info">
                <div
                  className={`type-box ${
                    viewStats === "learner" ? "active" : ""
                  }`}
                  id="learner-type-box"
                  onClick={() => setViewStats("learner")}
                >
                  <p>learner data</p>
                </div>
              </div>
            ) : null}

            <div className="name-email-box">
              <div className="name">
                <p>{user.lastName + " " + user.firstName}</p>
              </div>
              <p>{user.email}</p>
            </div>
          </div>
          {viewStats === "learner" ? <LearnerStats user={user} /> : null}
          {viewStats === "instructor" ? <InstructorStats user={user} /> : null}
        </div>
      </div>
    </div>
  );
};

/* 

  ! - LEARNER -                    ! - LEARNER -                    ! - LEARNER -                    ! - LEARNER -

*/

const LearnerStats = ({ user }) => {
  const [pastSessions, setPastSessions] = useState([]);
  const [pendingSessions, setPendingSessions] = useState([]);
  const [viewLearnerStats, setViewLearnerStats] = useState("ongoing");
  const { config } = useContext(AppContext);

  const fetchLearnerSessions = async () => {
    const [learnerCompleted, learnerOngoing] = await Promise.all([
      axios.get(
        `${getBaseUrl()}/api/user/${user._id}/past_sessions_as_learner`,
        config
      ),
      axios.get(
        `${getBaseUrl()}/api/user/${user._id}/pending_sessions_as_learner`,
        config
      ),
    ]);
    setPastSessions(learnerCompleted.data);
    setPendingSessions(learnerOngoing.data);
  };

  useEffect(() => {
    fetchLearnerSessions();
  }, []);

  return (
    <div className="user-info-container">
      <div className="user-stats-banner">
        <div className="course-stats-box">
          <div
            className="pending-col"
            id={`${
              viewLearnerStats === "ongoing"
                ? "learner-ongoing-enable"
                : "learner-ongoing-disable"
            }`}
          >
            <div
              className="stats"
              onClick={() => setViewLearnerStats("ongoing")}
            >
              <h1>{pendingSessions.length > 0 ? pendingSessions.length : 0}</h1>{" "}
              <p>ONGOING LEARNING SESSIONS</p>
            </div>
          </div>
          <div
            className="complete-col"
            id={`${
              viewLearnerStats === "completed"
                ? "learner-completed-enable"
                : "learner-completed-disable"
            }`}
          >
            <div
              className="stats"
              onClick={() => setViewLearnerStats("completed")}
            >
              <h1>{pastSessions.length > 0 ? pastSessions.length : 0}</h1>{" "}
              <p>COMPLETED LEARNING SESSIONS</p>
            </div>
          </div>
        </div>
      </div>

      {viewLearnerStats === "ongoing" ? (
        <>
          {pendingSessions.length > 0 && (
            <>
              <LearnerTableHeaderOngoing />
              <div className="user-data">
                {pendingSessions.map((session) => (
                  <>
                    <div className="column-name" key={session._id}>
                      <div className="column-box">
                        <p>{session.sessionClass.title}</p>
                      </div>
                      <div className="column-box">
                        <p>{moment(session.startTime).format("MM/DD/YYYY")}</p>
                      </div>
                      <div className="column-box">
                        <p>{moment(session.endTime).format("MM/DD/YYYY")}</p>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {pastSessions.length > 0 && (
            <>
              <LearnerTableHeaderUpcoming />
              <div className="user-data">
                {pastSessions.map((session) => (
                  <div className="column-name" key={session._id}>
                    <div className="column-box">
                      <p>{session.sessionClass.title}</p>
                    </div>
                    <div className="column-box">
                      <p>{moment(session.startTime).format("MM/DD/YYYY")}</p>
                    </div>
                    <div className="column-box">
                      <p>{moment(session.endTime).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

/* 

  ! - INSTRUCTOR -                    ! - INSTRUCTOR -                    ! - INSTRUCTOR -                    ! - INSTRUCTOR -

*/

const InstructorStats = ({ user }) => {
  const [pastSessions, setPastSessions] = useState([]);
  const [pendingSessions, setPendingSessions] = useState([]);
  const [viewInstructorStats, setViewInstructorStats] = useState("ongoing");
  const { config } = useContext(AppContext);

  const fetchInstructorSessions = async () => {
    const [instructorPast, instructorPending] = await Promise.all([
      axios.get(
        `${getBaseUrl()}/api/user/${user._id}/past_sessions_as_instructor`,
        config
      ),
      axios.get(
        `${getBaseUrl()}/api/user/${user._id}/pending_sessions_as_instructor`,
        config
      ),
    ]);
    setPastSessions(instructorPast.data);
    setPendingSessions(instructorPending.data);
  };

  useEffect(() => {
    fetchInstructorSessions();
  }, []);

  return (
    <div className="user-info-container">
      <div className="user-stats-banner">
        <div className="course-stats-box" id="instructor-stats">
          <div
            className="pending-col"
            id={`${
              viewInstructorStats === "ongoing"
                ? "instructor-ongoing-enable"
                : "instructor-ongoing-disable"
            }`}
          >
            <div
              className="stats"
              onClick={() => setViewInstructorStats("ongoing")}
            >
              <h1>{pendingSessions.length > 0 ? pendingSessions.length : 0}</h1>
              <p>ONGOING TEACHING SESSIONS</p>
            </div>
          </div>
          <div
            className="complete-col"
            id={`${
              viewInstructorStats === "completed"
                ? "instructor-completed-enable"
                : "instructor-completed-disable"
            }`}
          >
            <div
              className="stats"
              onClick={() => setViewInstructorStats("completed")}
            >
              <h1>{pastSessions.length > 0 ? pastSessions.length : 0}</h1>
              <p>COMPLETED TEACHING SESSIONS</p>
            </div>
          </div>
        </div>
      </div>{" "}
      {viewInstructorStats === "ongoing" ? (
        <>
          {pendingSessions.length > 0 && (
            <>
              <InstructorTableHeaderOngoing />
              <div className="user-data">
                {pendingSessions.map((session) => (
                  <div className="column-name" key={session._id}>
                    <div className="column-box">
                      <p>{session.sessionClass.title}</p>
                    </div>
                    <div className="column-box">
                      <p>{moment(session.startTime).format("MM/DD/YYYY")}</p>
                    </div>
                    <div className="column-box">
                      <p>{moment(session.endTime).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {pendingSessions.length > 0 && (
            <>
              <InstructorTableHeaderUpcoming />
              <div className="user-data">
                {pastSessions.map((session) => (
                  <div className="column-name" key={session._id}>
                    <div className="column-box">
                      <p>{session.sessionClass.title}</p>
                    </div>
                    <div className="column-box">
                      <p>{moment(session.startTime).format("MM/DD/YYYY")}</p>
                    </div>
                    <div className="column-box">
                      <p>{moment(session.endTime).format("MM/DD/YYYY")}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ModalUserStatistics;
