// REACT IMPORT
import React, { useState } from "react";
import ModalClassInfo from "./ModalClassInfo";

//MODULE IMPORT

//CSS IMPORT
const CardAllClasses = ({ allClasses, closeModal }) => {
    //USE STATE
    const [classInfo, setClassInfo] = useState(false);

    //VARIABLE
    const { _id, title, description, category, createdAt } = allClasses;
    const created = new Date(createdAt);

    // APPEAR THE MODAL FOR THE CLASS INFO
    const toggleClassInfo = () => {
        setTimeout(() => {
            setClassInfo(!classInfo);
        }, 100);
    };

    if (classInfo) {
        document.body.classList.add("overlay");
    } else {
        document.body.classList.remove("overlay");
    }

    return (
        <div className="view-class" key={_id} onClick={toggleClassInfo}>
            <div className="main-info">
                <div>
                    <p className="class-title">{title}</p>
                    <p className="class-category">{category}</p>
                </div>
                <p className="class-description">{description}</p>
            </div>
            <div className="sub-info">
                <hr className="viewclass-hr"></hr>

                <div className="created-time-date">
                    <p className="date">Created {created.toLocaleDateString([], { year: "numeric", month: "short", day: "numeric" })}</p>
                    <p className="time">
                        &nbsp;&nbsp;{created.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true })}
                    </p>
                </div>
            </div>
            {classInfo && <ModalClassInfo toggleClassInfo={toggleClassInfo} classId={_id} key={_id} closeModal={closeModal} />}
        </div>
    );
};

export default CardAllClasses;
