//REACT IMPORT
import React, { useState, useContext } from "react";
import { AppContext } from "../../App";
import DatePicker from "react-datepicker";

import { PiSealWarningFill } from "react-icons/pi";
import { IoMdTrash } from "react-icons/io";
import { MdEdit } from "react-icons/md";

//CSS
import "../../Admin/css/admin-users.css";

//MODULES
import axios from "axios";
// import ModalClassView from "./ModalClassInfo";

import { getBaseUrl } from "../../baseURL";

const CardSessions = ({ session, modalRerender, setErrorMessage }) => {
  const { config } = useContext(AppContext);

  const [editSession, setEditSession] = useState(true);
  const [newSession, setNewSession] = useState(session);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { startTime, endTime, capacity, learners } = session;
  const options = { year: "numeric", month: "long", day: "numeric" };
  const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };

  const handleSubmit = async () => {
    try {
      await axios
        .put(`${getBaseUrl()}/api/session/${session._id}`, newSession, config)
        .then((res) => {
          if (res.status === 201) {
            modalRerender();
          }
          if (res.status === 400) {
            setErrorMessage("error");
          }
        });
    } catch (error) {
      setErrorMessage(error.response.data);
      modalRerender();
    }
  };

  const handleChange = (field, value) => {
    setNewSession({ ...newSession, [field]: value });
  };

  const confirmDelete = () => {
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios
        .delete(`${getBaseUrl()}/api/session/${session._id}`, config)
        .then((res) => {
          if (res.status === 201) {
            setShowConfirmation(false);
            modalRerender();
          }
        });
    } catch (error) {
      console.log(` wala na delete bcuz ${error}`);
    }
  };

  return (
    <div>
      {editSession ? (
        <div className="card-sessions">
          <div className="column-name">
            <div className="column-box">
              <p className="date">
                {new Date(startTime).toLocaleDateString(undefined, options)}
              </p>
              <p className="time">
                {new Date(startTime).toLocaleTimeString(undefined, optionsTime)}
              </p>
            </div>
            <div className="column-box">
              <p className="date">
                {new Date(session.endTime).toLocaleDateString(
                  undefined,
                  options
                )}
              </p>
              <p className="time">
                {new Date(endTime).toLocaleTimeString(undefined, optionsTime)}
              </p>
            </div>

            <div className="column-box">
              <p className="capacity">
                {learners.length}/{capacity}
              </p>
            </div>
            <div className="column-box" id="column-icons">
              <MdEdit
                className="options-icon"
                id="edit-icon"
                onClick={() => setEditSession(!editSession)}
              />
              <IoMdTrash
                className="options-icon"
                id="trash-icon"
                onClick={() => confirmDelete()}
              />

              {showConfirmation && (
                <div className="popup" id="popup-overlay">
                  <div className="popup-inner">
                    <div className="circle-box" id="warning">
                      <PiSealWarningFill className="checkicon" />
                      <h3>DELETE SESSION?</h3>
                    </div>
                    <div className="popup-txt-contr">
                      <div className="txt-cont">
                        <p className="bold-txt">
                          Are you sure you want to delete session?
                        </p>
                      </div>

                      <div className="btn-cont delete-contr">
                        <button
                          className="close-btn"
                          onClick={handleConfirmDelete}
                          id="yes"
                        >
                          YES
                        </button>
                        <button
                          className="close-btn"
                          onClick={handleCancelDelete}
                          id="cancel"
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="edit-session-container">
          <form className="session-form" onSubmit={handleSubmit}>
            <div className="start-container">
              <div className="session-container-start" id="date">
                <p>DATE START</p>
                <div className="date-container">
                  <DatePicker
                    selected={new Date(newSession.startTime)}
                    onChange={(date) => handleChange("startTime", date)}
                    className="time-date"
                    // minDate={new Date()}
                    showPopperArrow={false}
                    popperPlacement="right-start"
                  />
                </div>
              </div>
              <div className="session-container-start">
                <p>TIME START</p>
                <div className="date-container">
                  <DatePicker
                    selected={new Date(newSession.startTime)}
                    onChange={(date) => handleChange("startTime", date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Start Time"
                    dateFormat="h:mm aa"
                    className="time-date"
                    showPopperArrow={false}
                    popperPlacement="right-start"
                  />
                </div>
              </div>
            </div>
            <div className="end-container">
              <div className="session-container-end" id="date">
                <p>DATE END</p>
                <div className="date-container">
                  <DatePicker
                    selected={new Date(newSession.endTime)}
                    onChange={(date) => handleChange("endTime", date)}
                    className="time-date"
                    minDate={newSession.startTime}
                    showPopperArrow={false}
                    popperPlacement="right-start"
                  />
                </div>
              </div>

              <div className="session-container-end">
                <p>TIME END</p>
                <div className="date-container">
                  <DatePicker
                    selected={new Date(newSession.endTime)}
                    onChange={(date) => handleChange("endTime", date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="End Time"
                    dateFormat="h:mm aa"
                    className="time-date"
                    showPopperArrow={false}
                    popperPlacement="right-start"
                  />
                </div>
              </div>
            </div>
            <div className="capacity-container">
              <div className="input-container">
                <input
                  type="number"
                  value={newSession.capacity}
                  placeholder="min. 4 - max 20 learners"
                  onChange={(e) => handleChange("capacity", e.target.value)}
                  min="4"
                  max="20"
                  className="capacity"
                  required
                />
              </div>
            </div>
            <div className="options-container">
              <button
                type="submit"
                className="edit-sessionbtn"
                onClick={() => {
                  setEditSession(!editSession);
                  handleSubmit();
                }}
              >
                CONFIRM
              </button>
              <button
                className="canceledit-sessionbtn"
                onClick={() => setEditSession(!editSession)}
              >
                CANCEL
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default CardSessions;
