//REACT IMPORT
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { MdClose } from "react-icons/md";
import { FaArrowLeft } from "react-icons/fa";

//CSS
import "../css/modal-class-info.css";

//MODULES
import CardSessions from "./CardSessions";
import { AppContext } from "../../App";
import FormBodyClassEdit from "./FormBodyClassEdit";
import SkeletonModalClassInfo from "./SkeletonModalClassInfo";
import ModalClassDelete from "./ModalClassDelete";
import LinedBoxCategory from "./LinedBoxCategory";
import { getBaseUrl } from "../../baseURL";

const ModalClassInfo = ({ toggleClassInfo, classId, closeModal }) => {
  //ASSIGN A VARIABLE
  const [sessions, setSessions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [newSession, setNewSession] = useState({
    capacity: "4",
    startTime: new Date(Date.now()),
    endTime: new Date(Date.now()),
  });
  const [rerender, setRerender] = useState(false);
  const [classDetailsWindow, setClassDetailsWindow] = useState("class-details");
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [classDelete, setClassDelete] = useState(false);
  const [isShow, setisShow] = useState(true);

  //GET THE APP CONTEXT
  const { appState, config } = useContext(AppContext);
  const { currentRole } = appState;

  const classEditRender = () => {
    setCourse(null);
    getClassInfo();
  };

  const modalRerender = () => {
    setRerender(!rerender);
  };

  const collectSessions = () => {
    setTimeout(() => {
      axios
        .get(`${getBaseUrl()}/api/class/${classId}/session`, config)
        .then((res) => {
          setSessions(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000);
  };

  const getClassInfo = () => {
    setTimeout(() => {
      axios
        .get(`${getBaseUrl()}/api/class/${classId}`, config)
        .then((res) => {
          setCourse(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000);
  };

  useEffect(() => {
    collectSessions();
    getClassInfo();
    // eslint-disable-next-line
  }, [rerender]);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  }, [errorMessage]);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const handleChange = (field, value) => {
    setNewSession({ ...newSession, [field]: value });
  };

  const handleAddSession = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(
          `${getBaseUrl()}/api/class/${classId}/session`,
          newSession,
          config
        )
        .then((res) => {
          if (res.status === 201) {
            setSessions([]);
            setClassDetailsWindow("class-details");
            // setSuccessMessage("Session added successfully");
            collectSessions();
          }
        });
    } catch (error) {
      setErrorMessage(error.response.data.msg);
      // console.log(error)
    }
  };

  const toggleClassDelete = () => {
    setClassDelete(!classDelete);
  };

  const handleAnimate = () => {
    setisShow(false);
    toggleClassInfo();
  };

  // function nl2br(str) {
  //     return str.replace(/(?:\r\n|\r(?!\n)|\n(?!\r)|\n(?=.*<br>))/g, "<br>");
  // }

  return (
    <>
      <div
        className={`overlay ${isShow ? "fade-in" : "fade-out"}`}
        onClick={handleAnimate}
      >
        <div
          className={`modal-content ${isShow ? "fade-in" : "fade-out"}`}
          onClick={handleModalClick}
        >
          <div className={`${currentRole}-title-container`}>
            {classDetailsWindow === "class-details" ? (
              <>
                <div className="title-boxA">
                  <div className="option-box">
                    <MdClose
                      id="back-icon"
                      onClick={() => {
                        setisShow(false);
                        toggleClassInfo();
                        setClassDetailsWindow("class-details");
                        if (appState.currentRole === "instructor") closeModal();
                      }}
                    />
                    <p>COURSE DETAILS</p>
                  </div>
                </div>

                <div className="title-boxB">
                  {sessions.length > 0 ? (
                    <>
                      {appState.currentRole === "instructor" && (
                        <div className="option-box-grid">
                          {/* <div className="option-box"> */}
                          <button
                            className="edit-classbtn"
                            onClick={() => {
                              setClassDetailsWindow("class-edit");
                              setIsLoading(!isLoading);
                            }}
                          >
                            EDIT COURSE
                          </button>
                          {/* </div> */}
                          {/* <div className="option-box"> */}
                          <button
                            className="delete-classbtn"
                            onClick={() => toggleClassDelete()}
                          >
                            DELETE COURSE
                          </button>
                          {classDelete ? (
                            <ModalClassDelete
                              toggleClassDelete={toggleClassDelete}
                              course={course}
                              closeModal={closeModal}
                              isModalClassInfo={true}
                              toggleClassInfo={toggleClassInfo}
                            />
                          ) : null}
                          {/* </div> */}
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
            {classDetailsWindow === "class-add-session" ? (
              <div className="title-boxA">
                <div className="option-box">
                  <FaArrowLeft
                    id="back-icon"
                    onClick={() => {
                      setSuccessMessage("");
                      setClassDetailsWindow("class-details");
                    }}
                  />
                  <p>ADD SESSION</p>
                </div>
              </div>
            ) : null}
            {classDetailsWindow === "class-edit" ? (
              <div className="title-boxA">
                <div className="option-box">
                  <FaArrowLeft
                    id="back-icon"
                    onClick={() => {
                      setClassDetailsWindow("class-details");
                      setIsLoading(true);
                      //classEditRender();
                      closeModal();
                    }}
                  />
                  <p>EDIT COURSE</p>
                </div>
              </div>
            ) : null}
          </div>

          <div className="info-container">
            {/*======================= CLASS DETAILSS ==================== */}
            {classDetailsWindow === "class-details" ? (
              <>
                {course === null ? (
                  <SkeletonModalClassInfo />
                ) : (
                  <>
                    <div className="class-name">
                      <div className="category-description-box">
                        <div className="name">
                          <div className="title">
                            <p>{course.title}</p>
                          </div>
                        </div>

                        <LinedBoxCategory category={course.category} />

                        <div className="description">
                          <p className="lined-box">DESCRIPTION</p>
                          <div className="details-box">
                            {/* <p>{nl2br(course.description)}</p> */}
                            <p>{course.description}</p>
                          </div>
                        </div>

                        <div className="instructor">
                          <p className="lined-box">INSTRUCTORS </p>
                          <div className="details-box">
                            <p>
                              {course.instructor
                                .map(
                                  (inst) =>
                                    " " + inst.firstName + " " + inst.lastName
                                )
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                        <div className="session">
                          <div className="lined-box">
                            <p>SESSIONS</p>
                            <div className="title-boxB">
                              <div className="option-box">
                                <button
                                  className="add-sessionbtn"
                                  onClick={() => {
                                    setClassDetailsWindow("class-add-session");
                                  }}
                                >
                                  ADD SESSION
                                </button>
                              </div>
                            </div>
                          </div>
                          {errorMessage && (
                            <p className="error-message" style={{ margin: 10 }}>
                              {errorMessage}
                            </p>
                          )}
                          {sessions.length > 0 && (
                            <div className="sessions-table">
                              <div className="column-name">
                                <div className="column-box">
                                  <p>START</p>
                                </div>
                                <div className="column-box">
                                  <p>END</p>
                                </div>
                                <div className="column-box">
                                  <p>CAPACITY</p>
                                </div>
                                <div className="column-box">
                                  <p>OPTIONS</p>
                                </div>
                              </div>
                              <div className="sessions-data">
                                {sessions.map((session) => (
                                  <CardSessions
                                    modalRerender={modalRerender}
                                    session={session}
                                    key={session._id}
                                    setErrorMessage={setErrorMessage}
                                  />
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : null}

            {/*======================= CLASS DETAILSS ==================== */}
            {classDetailsWindow === "class-add-session" ? (
              <div className="add-session-container">
                <form className="session-form">
                  <div className="session-firstContainer">
                    <div className="start-container">
                      <div className="session-container-start" id="date">
                        <p>DATE START</p>
                        <div className="date-container">
                          <DatePicker
                            selected={newSession.startTime}
                            onChange={(date) => {
                              handleChange("startTime", date);
                              setNewSession((prevState) => {
                                return {
                                  ...prevState,
                                  endTime: date, // replace with new value for endTime
                                };
                              });
                            }}
                            className="time-date"
                            minDate={new Date()}
                            showPopperArrow={false}
                            popperPlacement="right-start"
                          />
                        </div>
                      </div>
                      <div className="session-container-start">
                        <p>TIME START</p>
                        <div className="date-container">
                          <DatePicker
                            selected={newSession.startTime}
                            onChange={(date) => {
                              handleChange("startTime", date);
                              setNewSession((prevState) => {
                                return {
                                  ...prevState,
                                  endTime: date, // replace with new value for endTime
                                };
                              });
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Start Time"
                            dateFormat="h:mm aa"
                            className="time-date"
                            showPopperArrow={false}
                            popperPlacement="right-end"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="end-container">
                      <div className="session-container-end" id="date">
                        <p>DATE END</p>
                        <div className="date-container">
                          <DatePicker
                            selected={newSession.endTime}
                            onChange={(date) => handleChange("endTime", date)}
                            className="time-date"
                            minDate={newSession.startTime}
                            showPopperArrow={false}
                            popperPlacement="left-start"
                          />
                        </div>
                      </div>

                      <div className="session-container-end">
                        <p>TIME END</p>
                        <div className="date-container">
                          <DatePicker
                            selected={newSession.endTime}
                            onChange={(date) => handleChange("endTime", date)}
                            minDate={newSession.startTime}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="End Time"
                            dateFormat="h:mm aa"
                            className="time-date"
                            showPopperArrow={false}
                            popperPlacement="left-start"
                            positionFixed={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="capacity-container">
                    <p>CAPACITY</p>
                    <div className="input-container">
                      <input
                        type="number"
                        value={newSession.capacity}
                        placeholder="min. 4 - max 20 learners"
                        onChange={(e) =>
                          handleChange("capacity", e.target.value)
                        }
                        min="4"
                        max="20"
                        className="capacity"
                        required
                      />
                    </div>
                  </div>
                  {successMessage && (
                    <p
                      className="success-message"
                      id="end-position"
                      style={{ margin: 0 }}
                    >
                      {successMessage}
                    </p>
                  )}
                  <hr></hr>

                  <div className="options-container">
                    <button
                      className="add-sessionbtn"
                      onClick={handleAddSession}
                    >
                      ADD
                    </button>
                    <button
                      className="canceledit-sessionbtn"
                      onClick={() => {
                        setSuccessMessage("");
                        setClassDetailsWindow("class-details");
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            ) : null}

            {classDetailsWindow === "class-edit" ? (
              <FormBodyClassEdit
                setClassDetailsWindow={setClassDetailsWindow}
                course={course}
                classEditRender={classEditRender}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalClassInfo;
