import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../App";
import "../css/dropdown-nav.css";

import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import InstructorDashboard from "../../Instructor/components/InstructorDashboard";
import LearnerDashboard from "../../Learner/components/LearnerDashboard";
import AdminDashboard from "../../Admin/components/AdminDashboard";

const Dropdown = ({ role }) => {
    const { appState, setAppState } = useContext(AppContext);
    const [showList, setShowList] = useState(false);
    const [selectedRole, setSelectedRole] = useState(appState.currentRole);
    const { display, setDisplay } = useContext(AppContext);
    const { instructorDisplay, setInstructorDisplay } = useContext(AppContext);
    const { learnerDisplay, setLearnerDisplay } = useContext(AppContext);

    useEffect(() => {
        setSelectedRole(appState.currentRole);
    }, [appState.currentRole]);

    const handleToggle = () => setShowList(!showList);
    const handleChange = (value) => {
        setAppState({ ...appState, currentRole: value });
        setSelectedRole(value);
        setShowList(false);
    };

    return (
        <>
            {appState.role === "learner" && (
                <div className="dropdown-learner">
                    <p
                        className="learner-nav"
                        onClick={() => {
                            handleChange("learner");
                            <LearnerDashboard />;
                            setLearnerDisplay("LearnerOverview");
                        }}
                    >
                        LEARNER
                    </p>
                </div>
            )}

            {appState.role === "admin" || appState.role === "instructor" ? (
                <>
                    <div className="dropdown">
                        <button
                            onClick={handleToggle}
                            style={
                                selectedRole === "admin"
                                    ? { color: "#24abe3", width: 130 }
                                    : selectedRole === "instructor"
                                    ? { color: "#f4941c" }
                                    : selectedRole === "learner"
                                    ? { color: "#8bc23b", width: 130 }
                                    : {}
                            }
                        >
                            {selectedRole.toUpperCase()}
                            {appState.role === "learner" ? null : showList ? (
                                <FaAngleUp id="toggle-icon" />
                            ) : (
                                <FaAngleDown id="toggle-icon" />
                            )}
                        </button>
                        <div
                            className={`dropdown-container ${
                                showList ? "show" : ""
                            }`}
                        >
                            <ul>
                                {appState.role === "admin" &&
                                selectedRole !== "admin" ? (
                                    <li
                                        onClick={() => {
                                            handleChange("admin");
                                            setDisplay("AdminOverview");
                                        }}
                                    >
                                        ADMIN
                                    </li>
                                ) : null}

                                {appState.role !== "learner" &&
                                selectedRole !== "instructor" ? (
                                    <li
                                        onClick={() => {
                                            handleChange("instructor");
                                            <InstructorDashboard />;
                                            setInstructorDisplay(
                                                "InstructorOverview"
                                            );
                                        }}
                                    >
                                        INSTRUCTOR
                                    </li>
                                ) : null}
                                {selectedRole !== "learner" && (
                                    <li
                                        onClick={() => {
                                            handleChange("learner");
                                            <LearnerDashboard />;
                                            setLearnerDisplay(
                                                "LearnerOverview"
                                            );
                                        }}
                                    >
                                        LEARNER
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Dropdown;
