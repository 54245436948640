import React, { useState, useContext } from "react";
import { AppContext } from "../../App";
import ModalUnenroll from "./ModalUnenroll";
import "../css/modal-sessions-info.css";

import { MdClose } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import LinedBoxCategory from "./LinedBoxCategory";

const ModalSessionsInfo = ({ toggleSessionInfo, viewClassCard, closeModal }) => {
    const { appState } = useContext(AppContext);
    const [showUnenroll, setShowUnenroll] = useState(false);

    const options = {
        hour: "numeric",
        minute: "numeric",
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    const { startTime, endTime, capacity, createdAt, learners } = viewClassCard;
    const { title, description, category, instructor } = viewClassCard.sessionClass;

    const startTimeConverted = new Date(startTime);
    const endTimeConverted = new Date(endTime);
    const createdTimeConverted = new Date(createdAt);
    const created = createdTimeConverted.toLocaleString(undefined, options);

    let idColor = "";
    if (appState.currentRole === "learner") {
        idColor = "learner-color";
    } else if (appState.currentRole === "admin") {
        idColor = "admin-color";
    } else if (appState.currentRole === "instructor") {
        idColor = "instructor-color";
    } else if (appState.currentRole === "today") {
        idColor = "today-color";
    }

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const toggleUnenroll = () => {
        setShowUnenroll(!showUnenroll);
        if (setShowUnenroll === true) {
            toggleSessionInfo();
        }
    };

    return (
        <div className="overlay" id="classinfo-overlay">
            <div className="modal-content" onClick={handleModalClick}>
                {appState.currentRole === "admin" ? (
                    <div className="admin-title-container">
                        <div className="title-and-icon">
                            <MdClose id="back-icon" onClick={() => toggleSessionInfo()} />
                            <h1>SESSION DETAILS</h1>
                        </div>
                        <div className="edit-delete">
                            <IoMdTrash id="trash-icon" />
                        </div>
                    </div>
                ) : null}
                {appState.currentRole === "instructor" ? (
                    <div className="instructor-title-container">
                        <div className="title-and-icon">
                            <MdClose id="back-icon" onClick={() => toggleSessionInfo()} />
                            <p>SESSION DETAILS</p>
                        </div>
                    </div>
                ) : null}
                {appState.currentRole === "learner" ? (
                    <div className="title-container" id="learner-title-container">
                        <div className="title-boxA">
                            <div className="option-box">
                                <MdClose id="back-icon" onClick={() => toggleSessionInfo()} />
                                <p>{title}</p>
                            </div>
                        </div>
                        <button onClick={() => toggleUnenroll()} className="class-unenroll">
                            UNENROLL
                        </button>
                        {showUnenroll ? <ModalUnenroll viewClassCard={viewClassCard} closeModal={closeModal} /> : null}
                    </div>
                ) : null}

                <div className="modal-card-wrapper">
                    <div className="class-card-container">
                        <div className="col-classTitle-container">
                            <p className="class-title">{title}</p>
                        </div>
                        <div className="col-container">
                            <LinedBoxCategory category={category} />
                        </div>
                        <div className="col-container">
                            <p className="col-title" id={`${idColor}`}>
                                TIME AND DATE
                            </p>
                            <div className="time-date-description">
                                <div className="session-time">
                                    <div className="time-start">
                                        <p className="time">
                                            {startTimeConverted.toLocaleTimeString([], {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })}
                                        </p>
                                        <p className="date">
                                            {startTimeConverted.toLocaleDateString([], {
                                                month: "short",
                                                day: "numeric",
                                            })}
                                        </p>
                                    </div>
                                    <div className="time-end">
                                        <p className="time">
                                            {endTimeConverted.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true })}
                                        </p>
                                        <p className="date">
                                            {endTimeConverted.toLocaleDateString([], {
                                                month: "short",
                                                day: "numeric",
                                            })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-container">
                            <p className="col-title">COURSE DESCRIPTION</p>
                            <p className="course-description">{description}</p>
                        </div>
                        <div className="col-container" id="instructor-col">
                            <p className="col-title">INSTRUCTORS</p>
                            <div className="course-description">
                                {instructor.map((i) => (
                                    <p className="instructor-list" key={i._id}>
                                        {i.firstName + " " + i.lastName} ({i.email})
                                    </p>
                                ))}
                            </div>
                        </div>

                        <div className="col-container">
                            <p className="col-title" id={`${idColor}`}>
                                NUMBER OF ENROLLED STUDENTS
                            </p>
                            <p className="course-description">
                                {learners.length}/{capacity} students
                            </p>
                        </div>
                        <p className="created">Created {created}</p>

                        {/* <div className="col-container">
                            <p className="col-title">Learners:</p>
                            {!learners ? (
                                <p>Nobody enrolled in this class</p>
                            ) : (
                                learners.map((learner) => <p>{learner.firstName + " " + learner.lastName}</p>)
                            )}
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSessionsInfo;
