import React, { useState } from "react";
import "../../Admin/css/admin-classes.css";

import { FaPen, FaTrash, FaEye } from "react-icons/fa";

import ModalClassEdit from "./ModalClassEdit";
import ModalClassDelete from "./ModalClassDelete";
import ModalClassInfo from "./ModalClassInfo";

const CardClasses = ({ course, closeModal }) => {
    const [classViewDetails, setClassViewDetails] = useState(false);
    const [classEdit, setClassEdit] = useState(false);
    const [classDelete, setClassDelete] = useState(false);

    const date = new Date(course.createdAt);

    const toggleClassInfo = () => {
        setClassViewDetails(!classViewDetails);
    };

    if (classViewDetails) {
        document.body.classList.add("overlay");
    } else {
        document.body.classList.remove("overlay");
    }

    const toggleClassEdit = () => {
        setClassEdit(!classEdit);
    };
    const toggleClassDelete = () => {
        setClassDelete(!classDelete);
    };

    return (
        <div className="classes-row">
            <div className="classes-info" id="title-info">
                <p>{course.title}</p>
            </div>
            <div className="classes-info">
                <p>{course.category}</p>
            </div>
            <div className="classes-info">
                <p>{date.toLocaleDateString()}</p>
            </div>
            <div className="classes-info">
                <div className="options-icon">
                    <FaEye title="View Details" className="user-icon" onClick={() => toggleClassInfo()} />
                    {classViewDetails && <ModalClassInfo toggleClassInfo={toggleClassInfo} classId={course._id} />}

                    <FaPen title="Edit Course" className="user-icon" id="center-icon" onClick={() => toggleClassEdit()} />
                    {classEdit ? <ModalClassEdit toggleClassEdit={toggleClassEdit} course={course} closeModal={closeModal} /> : null}
                    <FaTrash title="Delete Course" className="user-icon" onClick={() => toggleClassDelete()} />
                    {classDelete ? (
                        <ModalClassDelete
                            toggleClassDelete={toggleClassDelete}
                            course={course}
                            closeModal={closeModal}
                            isModalClassInfo={false}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default CardClasses;
