//REACT IMPORT
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { FaArrowLeft, FaSearch } from "react-icons/fa";

//CSS
import "../css/instructor-classes.css";

//MODULES
import { AppContext } from "../../App";
import CardAllClasses from "../../SpecialPages/components/CardAllClasses";
import ViewSessions from "../../SpecialPages/components/ViewSessions";
import ReactPaginate from "react-paginate";
import { getBaseUrl } from "../../baseURL";

const InstructorClasses = () => {
  const [isLoadingAll, setIsLoadingAll] = useState(true);
  const [isLoadingUpcoming, setIsLoadingUpcoming] = useState(true);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(false);

  //this is to display the options between Add Classes and View Classes because I can't do it
  // using setDisplay because there would be a bug
  const [displayUpcomingHistory, setdisplayUpcomingHistory] =
    useState("upcoming");
  const { setInstructorDisplay, config } = useContext(AppContext);
  const [allClasses, setAllClasses] = useState([]);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [historySessions, setHistorySessions] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [gettingData, setGettingData] = useState(false);

  //PAGINATION
  const [pageCountAll, setpageCountAll] = useState(0);
  const [pageCountHistory, setpageCountHistory] = useState(0);
  let limit = 10;

  const closeModal = () => {
    setShowModal(!showModal);
  };

  const handlePageClickAll = async (data) => {
    setGettingData(true);

    let currentPage = data.selected + 1;

    await axios
      .get(
        `${getBaseUrl()}/api/user/instructorClasses?page=${currentPage}&limit=${limit}`,
        config
      )
      .then((res) => {
        setAllClasses(res.data);
        setGettingData(false);
      });
  };

  const handlePageClickHistory = async (data) => {
    setGettingData(true);
    let currentPage = data.selected + 1;

    await axios
      .get(
        `${getBaseUrl()}/api/user/instructor_sessions/history?page=${currentPage}&limit=${limit}`,
        config
      )
      .then((res) => {
        setHistorySessions(res.data);
        setGettingData(false);
      });
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        if (displayUpcomingHistory === "history") {
          await axios
            .get(
              `${getBaseUrl()}/api/user/instructor_sessions/history?page=1&limit=${limit}`,
              config
            )
            .then((res) => {
              const total = res.headers.get("X-Total-Count");
              setpageCountHistory(Math.ceil(total / limit));
              setHistorySessions(res.data);
              setIsLoadingHistory(false);
            });
        } else if (displayUpcomingHistory === "upcoming") {
          await axios
            .get(
              `${getBaseUrl()}/api/user/instructor_sessions/upcoming`,
              config
            )
            .then((res) => {
              setUpcomingSessions(res.data);
              setIsLoadingUpcoming(false);
            });
        } else {
          await axios
            .get(
              `${getBaseUrl()}/api/user/instructorClasses?page=1&limit=${limit}`,
              config
            )
            .then((res) => {
              const total = res.headers.get("X-Total-Count");
              setpageCountAll(Math.ceil(total / limit));
              setAllClasses(res.data);
              setIsLoadingAll(false);
            });
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchClasses();
  }, [displayUpcomingHistory, showModal, config]);

  const ClassesTenseOptions = () => {
    return (
      <>
        <div className="classestense-options">
          <div className="option-box">
            <p
              id={`${
                displayUpcomingHistory === "all" ? "selected" : "unselect"
              }`}
              onClick={() => {
                setdisplayUpcomingHistory("all");
              }}
            >
              ALL COURSES
            </p>
          </div>
          <div className="option-box">
            <p
              id={`${
                displayUpcomingHistory === "upcoming" ? "selected" : "unselect"
              }`}
              onClick={() => {
                setdisplayUpcomingHistory("upcoming");
              }}
            >
              UPCOMING SESSIONS
            </p>
          </div>
          <div className="option-box">
            <p
              id={`${
                displayUpcomingHistory === "history" ? "selected" : "unselect"
              }`}
              onClick={() => {
                setdisplayUpcomingHistory("history");
              }}
            >
              PREVIOUS SESSIONS
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="classes-wrapper">
        <div className="title-container" id="classes-instructor">
          <div className="title-boxA">
            <div className="option-box">
              <FaArrowLeft
                id="back-icon"
                onClick={() => setInstructorDisplay("InstructorOverview")}
              />
              <p>COURSES</p>
            </div>
          </div>

          <div className="title-boxB">
            {/* <div className="option-box">
                            <button
                                className="add-classbtn"
                                onClick={() => {
                                    setInstructorDisplay("InstructorAddClass");
                                }}
                            >
                                ADD COURSE
                            </button>
                        </div> */}
            <div className="search-box">
              <div className="input-container">
                <FaSearch className="paginationbox-icon" id="search-icon" />
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                  className="input-field"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card-wrapper" id="main-card-wrapper">
          <ClassesTenseOptions />
          {displayUpcomingHistory === "all" ? (
            isLoadingAll ? (
              <div className="loading-animation" id="classes-loading">
                <img src="loading.gif" alt="loading" id="loading-gif-id" />
              </div>
            ) : !allClasses.length ? (
              <div className="empty-classes">
                <p>You have no courses yet.</p>
              </div>
            ) : (
              <>
                {!gettingData ? (
                  <div className="card-container">
                    {allClasses
                      .filter((session) => {
                        return search && search.toLowerCase() === ""
                          ? true
                          : session.title
                              .toLowerCase()
                              .includes(search.toLowerCase());
                      })
                      .map((classList) => (
                        <CardAllClasses
                          allClasses={classList}
                          key={classList._id}
                          closeModal={closeModal}
                        />
                      ))}
                    {search &&
                      search.length > 0 &&
                      !allClasses.filter((session) =>
                        session.title
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ).length && (
                        <div className="empty-classes" id="classes-container">
                          <p>No results found for "{search}".</p>
                        </div>
                      )}
                  </div>
                ) : (
                  <div className="loading-animation" id="classes-loading">
                    <img src="loading.gif" alt="loading" id="loading-gif-id" />
                  </div>
                )}
                <div className="admin-pagination-box">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={pageCountAll}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClickAll}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </>
            )
          ) : null}
          {displayUpcomingHistory === "upcoming" ? (
            isLoadingUpcoming ? (
              <div className="loading-animation" id="classes-loading">
                <img src="loading.gif" alt="loading" id="loading-gif-id" />
              </div>
            ) : !upcomingSessions.length ? (
              <div className="empty-classes">
                <p>You have no upcoming sessions.</p>
              </div>
            ) : (
              <>
                <div className="column-name" id="column-upcominghistory">
                  <div className="column-boxA">
                    <div className="column-box">
                      <p>COURSE</p>
                    </div>
                  </div>
                  <div className="column-boxB">
                    <div className="column-box" id="column-boxA">
                      <p>START</p>
                    </div>
                    <div className="column-box">
                      <p>END</p>
                    </div>
                  </div>
                </div>

                <div className="card-container" id="sessions-container">
                  {upcomingSessions
                    .filter((session) => {
                      return search && search.toLowerCase() === ""
                        ? true
                        : session.sessionClass.title
                            .toLowerCase()
                            .includes(search.toLowerCase());
                    })
                    .map((classList) => (
                      <ViewSessions
                        viewClassCard={classList}
                        key={classList._id}
                      />
                    ))}
                  {search &&
                    search.length > 0 &&
                    !upcomingSessions.filter((session) =>
                      session.sessionClass.title
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ).length && (
                      <div className="empty-classes">
                        <p>No results found for "{search}".</p>
                      </div>
                    )}
                </div>
              </>
            )
          ) : null}
          {displayUpcomingHistory === "history" ? (
            isLoadingHistory ? (
              <div className="loading-animation" id="classes-loading">
                <img src="loading.gif" alt="loading" id="loading-gif-id" />
              </div>
            ) : !historySessions.length ? (
              <div className="empty-classes">
                <p>Nothing to show here.</p>
              </div>
            ) : (
              <>
                <div className="column-name" id="column-upcominghistory">
                  <div className="column-boxA">
                    <div className="column-box">
                      <p>COURSE</p>
                    </div>
                  </div>
                  <div className="column-boxB">
                    <div className="column-box" id="column-boxA">
                      <p>START</p>
                    </div>
                    <div className="column-box">
                      <p>END</p>
                    </div>
                  </div>
                </div>
                {!gettingData ? (
                  <div className="card-container" id="sessions-container">
                    {historySessions
                      .filter((session) => {
                        return search && search.toLowerCase() === ""
                          ? true
                          : session.sessionClass.title
                              .toLowerCase()
                              .includes(search.toLowerCase());
                      })
                      .map((classList) => (
                        <ViewSessions
                          viewClassCard={classList}
                          key={classList._id}
                        />
                      ))}
                    {search &&
                      search.length > 0 &&
                      !historySessions.filter((session) =>
                        session.sessionClass.title
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ).length && (
                        <div className="empty-classes">
                          <p>No results found for "{search}".</p>
                        </div>
                      )}
                  </div>
                ) : (
                  <div className="loading-animation" id="classes-loading">
                    <img src="loading.gif" alt="loading" id="loading-gif-id" />
                  </div>
                )}

                <div className="admin-pagination-box">
                  <ReactPaginate
                    previousLabel={"<<"}
                    nextLabel={">>"}
                    breakLabel={"..."}
                    pageCount={pageCountHistory}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClickHistory}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </>
            )
          ) : null}
        </div>
      </div>
    </>
  );
};

export default InstructorClasses;
