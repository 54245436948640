import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../App";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import PopUp from "../../SpecialPages/components/PopUpHome";
import "../../SpecialPages/css/profile.css";
import { getBaseUrl } from "../../baseURL";

const AdminUpdateProfile = () => {
    const navigate = useNavigate();
    const [buttonPopUp, setButtonPopUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [user, setUser] = useState({});

    const [formData, setFormData] = useState({
        email: "",
        jobLevel: "",
        username: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        birthday: "",
        gender: "",
    });

    const { appState, config } = useContext(AppContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${getBaseUrl()}/api/user/getUser`,
                    config
                );
                const userData = response.data;
                setUser(userData);
                setFormData({
                    email: userData.email,
                    jobLevel: userData.jobLevel,
                    username: userData.username,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    phoneNumber: userData.phoneNumber || "",
                    birthday: userData.birthday
                        ? new Date(userData.birthday)
                              .toISOString()
                              .split("T")[0]
                        : "",
                    gender: userData.gender,
                });
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [config]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        try {
            const updatedFormData = { ...user, ...formData };
            const result = await axios.put(
                `${getBaseUrl()}/api/user`,
                updatedFormData,
                config
            );
            if (result.status === 201) {
                setUser(result.data);
                setButtonPopUp(true);
            }
        } catch (error) {
            setErrorMessage(error.response.data);
        }
    };

    const renderInput = (label, name, type = "text", additionalProps = {}) => (
        <div className="key-value">
            <p>{label}</p>
            <div className="input-container">
                <input
                    type={type}
                    name={name}
                    value={formData[name] || ""}
                    placeholder={user[name] || ""}
                    onChange={handleChange}
                    className="input-field"
                    {...additionalProps}
                />
            </div>
        </div>
    );

    const EditProfilePopUp = () => {
        const { setDisplay } = useContext(AppContext);

        const handleButtonClick = () => {
            setDisplay("AdminOverview");
        };

        return (
            <>
                <div className="popup">
                    <div className="popup-inner">
                        <div className="circle-box">
                            <IoCheckmarkCircleOutline className="checkicon" />
                            <h3>SUCCESS!</h3>
                        </div>
                        <div className="popup-txt-contr">
                            <div className="txt-cont">
                                <p className="bold-txt">
                                    Update Profile Successful
                                </p>
                                <p>
                                    You have successfully updated the user's
                                    profile.
                                </p>
                            </div>
                            <div className="btn-cont">
                                <button
                                    className="close-btn"
                                    onClick={handleButtonClick}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="admin-profile-page">
            <div className="profile-card">
                <div className="title-container">
                    <h1>{user ? `${user.firstName} ${user.lastName}` : " "}</h1>
                </div>
                {user && (
                    <form onSubmit={handleSubmit} className="info-container">
                        {renderInput("First Name", "firstName", "text", {
                            pattern: "[a-zA-Zs]*",
                            onInput: (e) => {
                                e.target.value = e.target.value.replace(
                                    /[^a-zA-Z\s]/g,
                                    ""
                                );
                            },
                        })}
                        {renderInput("Last Name", "lastName", "text", {
                            pattern: "[a-zA-Zs]*",
                            onInput: (e) => {
                                e.target.value = e.target.value.replace(
                                    /[^a-zA-Z\s]/g,
                                    ""
                                );
                            },
                        })}
                        {renderInput("Phone Number", "phoneNumber", "tel", {
                            pattern: "[0]{1}[9]{1}[0-9]{9}",
                            maxLength: 11,
                        })}
                        <div className="key-value">
                            <p>Gender</p>
                            <div className="input-container">
                                <select
                                    name="gender"
                                    value={formData.gender || ""}
                                    onChange={handleChange}
                                >
                                    <option value="" hidden>
                                        {user.gender}
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        {renderInput("Birthday", "birthday", "date", {
                            min: "1900-01-01",
                            max: new Date().toISOString().split("T")[0],
                        })}
                        <div className="key-value">
                            <p>Position</p>
                            <div className="input-container">
                                <select
                                    disabled
                                    required
                                    name="jobLevel"
                                    value={formData.jobLevel || ""}
                                    onChange={handleChange}
                                    className="disable-click"
                                >
                                    <option value="" hidden>
                                        {user.jobLevel}
                                    </option>
                                    <option value="" disabled>
                                        Leadership Roles
                                    </option>
                                    <option value="Admin Assistant">
                                        Admin Assistant
                                    </option>
                                    <option value="Accounts Manager">
                                        Accounts Manager
                                    </option>
                                    <option value="Ambassador">
                                        Ambassador
                                    </option>
                                    <option value="Executive Assistant">
                                        Executive Assistant
                                    </option>
                                    <option value="Quality Assurance">
                                        Quality Assurance
                                    </option>
                                    <option value="SME">SME</option>
                                    <option value="Administrator">
                                        Administrator
                                    </option>
                                    <option value="Analyst">Analyst</option>
                                    <option value="Coordinator">
                                        Coordinator
                                    </option>
                                    <option value="Director">Director</option>
                                    <option value="Lead Recruiter">
                                        Lead Recruiter
                                    </option>
                                    <option value="Manager">Manager</option>
                                    <option value="Senior Manager">
                                        Senior Manager
                                    </option>
                                    <option value="Supervisory">
                                        Supervisory
                                    </option>
                                    <option value="Trainer">Trainer</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        <hr />
                        {renderInput("Username", "username")}
                        <hr />
                        <div className="btn-container">
                            <button className="adminInfo-upd-btn" type="submit">
                                UPDATE PROFILE
                            </button>
                            <PopUp
                                trigger={buttonPopUp}
                                setTrigger={setButtonPopUp}
                                popUp={EditProfilePopUp}
                            />
                            {errorMessage && (
                                <p className="error-message">{errorMessage}</p>
                            )}
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default AdminUpdateProfile;
