import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "../../SpecialPages/css/profile.css";

import { AppContext } from "../../App";

import PopUp from "../../SpecialPages/components/PopUpHome";
import PasswordValidator from "../../SpecialPages/components/PasswordValidator";

import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FaArrowLeft, FaEyeSlash, FaEye } from "react-icons/fa";
import { getBaseUrl } from "../../baseURL";

const InstructorUpdatePassword = () => {
  const { appState, setInstructorDisplay } = useContext(AppContext);

  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [errorOldPass, setErrorOldPAss] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [setUser] = useState({});
  // const userId = appState.id;
  // const userEmail = appState.email;

  const [showOldPassword, setShowOldPassword] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const config = { headers: { authorization: `Bearer ${token}` } };
      try {
        const { id } = appState;
        const userId = id;
        const response = await axios.get(
          `${getBaseUrl()}/api/user/${userId}`,
          config
        );

        setUser(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const config = { headers: { authorization: `Bearer ${token}` } };

    if (newPassword !== confirmNewPassword) {
      setErrorConfirmPass("Passwords do not match");
    } else {
      try {
        const userId = appState.id;

        const data = {
          password: oldPassword,
          newPassword: newPassword,
        };
        const result = await axios.put(
          `${getBaseUrl()}/api/user/updatepassword`,
          data,
          config
        );
        setButtonPopUp(true);
      } catch (err) {
        setErrorOldPAss(
          <p className="error-message">{err.response.data.message}</p>
        );
      }
    }
  };

  useEffect(() => {
    if (errorOldPass || errorConfirmPass) {
      setTimeout(() => {
        setErrorConfirmPass("");
        setErrorOldPAss("");
      }, 3000);
    }
  }, [errorOldPass, errorConfirmPass]);

  const UpdatePasswordPopUp = () => {
    return (
      <>
        <div className="circle-box">
          <IoCheckmarkCircleOutline className="checkicon" />
          <h3>SUCCESS!</h3>
        </div>
        <div className="popup-txt-contr">
          <div className="txt-cont">
            <p className="bold-txt">Update Password Successfully</p>
            <p>You have successfully updated your password.</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="profile-page">
      <div className="profile-card">
        <div className="instructortitle-container">
          <div className="title-boxA">
            <div className="option-box">
              <FaArrowLeft
                className="back-icon"
                onClick={() => setInstructorDisplay("InstructorProfile")}
              />
              <p>UPDATE PASSWORD</p>
            </div>
          </div>

          <div className="update-profile-container">
            <button
              className="profile-update-btn"
              onClick={() => setInstructorDisplay("InstructorProfile")}
            >
              UPDATE PROFILE
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="info-container">
          <div className="key-value">
            <p>Old Password</p>
            <div className="input-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="input-field"
              />
              {showPassword ? (
                <FaEyeSlash
                  className="password-show"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <FaEye
                  className="password-hide"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
          </div>

          <div>{errorOldPass}</div>

          <hr />

          <div className="key-value">
            <p>New Password</p>
            <div className="input-container">
              <input
                type="password"
                placeholder={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="input-field"
              />
            </div>
          </div>

          <div className="key-value">
            <p></p>
            <div className="password-validator">
              <PasswordValidator password={newPassword} />
            </div>
          </div>

          <div className="key-value">
            <p>Confirm New Password</p>
            <div className="input-container">
              <input
                type="password"
                placeholder={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className="input-field"
              />
            </div>
          </div>

          {errorConfirmPass && (
            <p className="error-message">{"Passwords do not match"}</p>
          )}

          <hr></hr>

          <div className="btn-container">
            <button className="instructorInfo-upd-btn" type="submit">
              UPDATE
            </button>
            <br />
            <PopUp
              trigger={buttonPopUp}
              setTrigger={setButtonPopUp}
              popUp={UpdatePasswordPopUp}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default InstructorUpdatePassword;
