import React, { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";

import {
  FaEnvelope,
  FaUserAlt,
  FaLock,
  FaTransgender,
  FaBirthdayCake,
  FaBriefcase,
  FaPhoneAlt,
} from "react-icons/fa";
import { BiRename } from "react-icons/bi";
import { MdOutlineMarkEmailRead } from "react-icons/md";

import "./signupform.css";
import PasswordValidator from "../PasswordValidator";
import PopUp from "../PopUp";
import { getBaseUrl } from "../../../baseURL";

const SignUpForm = () => {
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [jobLevel, setJobLevel] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    console.log('handleSubmit called')
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    if (isLoading) {
      return;
    } else {
      setIsLoading(true);
      if (password !== confirmPassword) {
        setErrorMessage("The passwords you entered do not match");
        setIsLoading(false);
      } else {
        try {
          const data = {
            email: email,
            password: password,
            username: username,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            gender: gender,
            birthday: birthday,
            jobLevel: jobLevel,
          };
          const res = await axios.post(`${getBaseUrl()}/api/user`, data);
          console.log("res", res)
          console.log("res.data", res.data)
          setSuccessMessage("SUCCESS");
          // setSuccessMessage(res.data.message);
          setButtonPopUp(true);
          setEmail("");
          setConfirmPassword("");
          setPassword("");
          setUsername("");
          setFirstName("");
          setLastName("");
          setPhoneNumber("");
          setGender("");
          setBirthday("");
          setJobLevel("");
          setIsLoading(false);
        } catch (err) {
          setErrorMessage(err.response.data);
          setIsLoading(false);
        }
      }
    }
  };

  const handleLogin = () => {
    navigate("/");
  };

  const signupPop = () => {
    return (
      <>
        <div className="circle-box">
          <MdOutlineMarkEmailRead className="checkicon" />
          <h3>Verify Your Email</h3>
        </div>
        <div className="popup-txt-contr">
          <div className="txt-cont">
            <p className="bold-txt">You're almost finished!</p>
            <p>
              We'll be sending a verification email shortly. In order to
              complete the process and enjoy full access, please verify your
              account.
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="signup-main-container">
      <form onSubmit={handleSubmit} className="form-container">
        <div className="signup-container">
          <div id="span-items">
            <h2 className="signup-header">Take the LEAP!</h2>
            <h5 className="create-acc-header">Create your account now.</h5>
          </div>

          <div className="input-container">
            <BiRename className="icon" />
            <input
              required
              type="text"
              value={firstName}
              placeholder="first name"
              onChange={(e) => {
                // Remove non-letter and non-space characters
                const newValue = e.target.value.replace(/[^a-zA-Z\s-]+/g, "");
                // Capitalize the first letter of each word
                const capitalizedValue = newValue
                  .split(" ")
                  .map((word) => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  })
                  .join(" ");
                // Update the state
                setFirstName(capitalizedValue);
              }}
              className="input-field"
            />
          </div>

          <div className="input-container">
            <BiRename className="icon" />
            <input
              required
              type="text"
              value={lastName}
              placeholder="last name"
              onChange={(e) => {
                // Remove non-letter and non-space characters
                const newValue = e.target.value.replace(/[^a-zA-Z\s-]+/g, "");
                // Capitalize the first letter of each word
                const capitalizedValue = newValue
                  .split(" ")
                  .map((word) => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  })
                  .join(" ");
                // Update the state
                setLastName(capitalizedValue);
              }}
              className="input-field"
            />
          </div>

          <div className="input-container">
            <FaUserAlt className="icon" />
            <input
              required
              type="text"
              value={username}
              placeholder="Username"
              onChange={(e) => {
                // Remove non-letter and non-number characters from the input value
                const newValue = e.target.value.replace(/[^a-zA-Z0-9]+/g, "");
                // Update the state
                setUsername(newValue);
              }}
              className="input-field"
            />
          </div>
          <div className="input-container">
            <FaEnvelope className="icon" />
            <input
              required
              type="email"
              value={email}
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
            />
          </div>

          <div className="input-container">
            <FaTransgender className="icon" />
            <select
              required
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="" hidden>
                select gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="input-container">
            <FaBirthdayCake className="icon" />
            <input
              required
              type="date"
              value={birthday}
              placeholder="birthday"
              min="1900-01-01"
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) => setBirthday(e.target.value)}
              className="input-field"
              id="birthday"
            />
          </div>

          <div className="input-container">
            <FaBriefcase className="icon" />
            <select
              required
              value={jobLevel}
              onChange={(e) => setJobLevel(e.target.value)}
            >
              <option value="" hidden>
                select job level
              </option>
              <option value="Admin Assistant">Admin Assistant</option>
              <option value="Accounts Manager">Accounts Manager</option>
              <option value="Ambassador">Ambassador</option>
              <option value="Executive Assistant">Executive Assistant</option>
              <option value="Quality Assurance">Quality Assurance</option>
              <option value="SME">SME</option>
              <option value="Administrator">Administrator</option>
              <option value="Analyst">Analyst</option>
              <option value="Coordinator">Coordinator</option>
              <option value="Director">Director</option>
              <option value="Lead Recruiter">Lead Recruiter</option>
              <option value="Manager">Manager</option>
              <option value="Senior Manager">Senior Manager</option>
              <option value="Supervisory">Supervisory</option>
              <option value="Trainer">Trainer</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="input-container">
            <FaPhoneAlt className="icon" />
            <input
              required
              pattern="[0]{1}[9]{1}[0-9]{9}"
              type="number"
              value={phoneNumber}
              placeholder="09xx-xxx-xxxx"
              onChange={(e) => setPhoneNumber(e.target.value)}
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 11);
                setPhoneNumber(e.target.value);
              }}
              className="input-field"
            />
          </div>

          <hr id="span-items" />

          <p id="span-items">Set your password:</p>

          <div className="set-password">
            <div className="input-container">
              <FaLock className="icon" />
              <input
                required
                type="password"
                value={password}
                placeholder="create your password"
                onChange={(e) => setPassword(e.target.value)}
                className="input-field"
              />
            </div>

            <div className="input-container">
              <FaLock className="icon" />
              <input
                required
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="confirm password"
                className="input-field"
              />
            </div>
          </div>

          <div className="set-password">
            <PasswordValidator password={password} />
          </div>

          {errorMessage && (
            <p className="error-message" id="error-signup">
              {errorMessage}
            </p>
          )}
          <button className="signup-button" id="span-items" type="submit">
            SIGN UP
          </button>

          <PopUp
            trigger={buttonPopUp}
            setTrigger={setButtonPopUp}
            popUp={signupPop}
          />
          <div style={{ textAlign: "center" }} id="span-items">
            <span id="account-text"> Already have an account? </span>

            <span id="click-here" onClick={handleLogin}>
              {" "}
              Login here{" "}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
