import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../css/special-routes.css";
import { useNavigate } from "react-router";
import { getBaseUrl } from "../../baseURL";

const VerifiedEmail = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationResult, setVerificationResult] = useState("");

  const handlesLogIn = () => {
    navigate("/");
  };

  const location = useLocation();

  useEffect(() => {
    const code = location.search.split("=")[1];
    console.log(code);

    const verifyEmail = async () => {
      const response = await axios.get(
        `${getBaseUrl()}/api/verify?code=${code}`
      );
      console.log(response);

      if (response.status === 200) {
        setSuccessMessage("You have been verified");
        setVerificationResult("success");
      } else {
        setErrorMessage("Error");
      }
    };

    verifyEmail();
  }, [location.search]);

  return (
    <div className="vmain-container">
      {verificationResult === "success" ? (
        <div className="vform-container">
          <div>
            <img src="verification-success.png" alt="verification-success" />
          </div>

          <div className="vcontainer">
            <h5>Verification</h5>
            <h5>SUCCESS!</h5>

            <p className="vtext">
              You have successfully verified your account. Please proceed to
              login to enjoy full access.
            </p>

            <button className="vlogin-button" onClick={handlesLogIn}>
              LOGIN
            </button>
          </div>
        </div>
      ) : (
        <div className="vform-container">
          <div>
            <img src="verification-failed.png" alt="verification-failed" />
          </div>

          <div className="vcontainer">
            <h5>Failed</h5>
            <h5>Verification</h5>

            <p className="vtext">
              You have failed your verification. Please try to login and check
              for another verification link.
            </p>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button className="vlogin-button" onClick={handlesLogIn}>
              GO TO LOGIN
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifiedEmail;
