import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AppContext } from "../../App";

import MyCalendar from "./MyCalendarLearner";
import UpcomingHistory from "./UpcomingHistory";
import ClassesToday from "./ClassesToday";
import { getBaseUrl } from "../../baseURL";

const LearnerOverview = () => {
  const { appState, config, setLearnerDisplay } = useContext(AppContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${getBaseUrl()}/api/user/getUser`, config)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="first-container">
        {user ? (
          <div className="learner-info-wrapper">
            <div className="logo-container">
              <img src="learner.png" alt="learner" id="learner-image" />
            </div>
            <div className="learner-boxname">
              <h1 id="learner">Learner</h1>

              <div id="learner-info">
                <p id="user-name">{`${user.firstName} ${user.lastName}`}</p>
                <p id="account-name">{user.jobLevel}</p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="classes-today-info">
          <ClassesToday />
        </div>
      </div>

      <div className="second-container">
        <div className="calendar-container">
          <div className="title-container">
            <p>CALENDAR</p>
            <button
              className="class-cataloguebtn"
              onClick={() => {
                setLearnerDisplay("LearnerClasses");
              }}
            >
              COURSES
            </button>
          </div>
          <div className="calendar-container">
            <MyCalendar />
          </div>
        </div>
      </div>
    </>
  );
};

export default LearnerOverview;
