import React from "react";

const LinedBoxCategory = ({ category }) => {
    return (
        <div className="description">
            <p className="lined-box">CATEGORY</p>
            <div className="details-box">
                {category === "SZtallion Talks" && (
                    <>
                        <img
                            src="SZtallionTalks.png"
                            className="category-img"
                            alt="msz_sharedservices"
                        />
                        <p>An Open Class for all SZ Employees</p>
                    </>
                )}
                {category === "MasterclaSZ for Shared Services " && (
                    <>
                        <img
                            src="MSZ_SharedServices.png"
                            className="category-img"
                            alt="msz_sharedservices"
                        />
                        <p>
                            A MasterclaSZ session designed to address learning needs for Shared
                            Services Analysts, Specialists, Coordinators, Supevisors and Managers
                        </p>
                    </>
                )}
                {category === "MasterclaSZ for Service Delivery" && (
                    <>
                        <img
                            src="MSZ_ServiceDelivery.png"
                            className="category-img"
                            alt="msz_servicedelivery"
                        />
                        <p>
                            A MasterclaSZ session designed to address learning needs for Service
                            Delivery SMEs, Support Team Members, Team Leaders and Managers
                        </p>
                    </>
                )}
                {category === "SupportZebra Leadership Program" && (
                    <>
                        <img src="SLP.png" className="category-img" alt="msz_servicedelivery" />
                        <p>
                            An SZ's In-house leadership Certification Program for Talent Activation
                            and Succession Planning
                        </p>
                    </>
                )}
                {category === "SZTR Talks" && (
                    <>
                        <img
                            src="StarTalks.png"
                            className="category-img"
                            alt="msz_servicedelivery"
                        />
                        <p>A TRT's monthly sessions for TR related topics and FAQS</p>
                    </>
                )}

                {category === "STEP Pre-Onboarding" && (
                    <>
                        <img src="STEP.png" className="category-img" alt="msz_sharedservices" />
                        <p>
                            A pre-onboarding training program for Service Delivery's New and
                            Upcoming Team Leader/Supervisor candidates
                        </p>
                    </>
                )}

                {category === "SZU Core" && (
                    <>
                        <p>SZU Core</p>
                    </>
                )}
                {category === "SZU Mandatory" && (
                    <>
                        <p>SZU Mandatory</p>
                    </>
                )}
                {category === "SZU Elective" && (
                    <>
                        <p>SZU Elective</p>
                    </>
                )}
                {category === "STEP Pre-Onboarding" && (
                    <>
                        <p>STEP Pre-Onboarding</p>
                    </>
                )}
                {category === "MTEP Pre-Onboarding" && (
                    <>
                        <p>MTEP Pre-Onboarding</p>
                    </>
                )}
                {category === "MasterclaSZ for Service Delivery" && (
                    <>
                        <p>MasterclaSZ for Service Delivery</p>
                    </>
                )}
                {category === "MasterclaSZ for Shared Services" && (
                    <>
                        <p>MasterclaSZ for Shared Services</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default LinedBoxCategory;
