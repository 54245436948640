import React, { useState, useContext, useEffect } from "react";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import axios from "axios";
import { AppContext } from "../../App";
import "../css/learner-classes.css";
import InfiniteScroll from "react-infinite-scroll-component";
import ViewSessions from "../../SpecialPages/components/ViewSessions";
import ViewSessionUpcoming from "../../SpecialPages/components/ViewSessionUpcoming";
import ReactPaginate from "react-paginate";
import { getBaseUrl } from "../../baseURL";

const LearnerClasses = () => {
    // FOR GETTING THE ID
    const { setLearnerDisplay, config } = useContext(AppContext);
    //FOR DISPLAY
    const [displayUpcomingHistory, setdisplayUpcomingHistory] =
        useState("upcoming");
    const idRole = "learner-title-container";
    const [showModal, setShowModal] = useState(false);
    // FOR ARRAY OF CLASSES
    const [allClasses, setAllClasses] = useState([]);
    const [upcomingSessions, setUpcomingSessions] = useState([]);
    const [historySessions, setHistorySessions] = useState([]);

    // FOR LOADING
    const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [isLoadingUpcoming, setIsLoadingUpcoming] = useState(true);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const [gettingData, setGettingData] = useState(false);

    const [isAllClassesEmpty, setIsAllClassesEmpty] = useState(false);

    // FOR SEARCH
    const [search, setSearch] = useState("");

    const closeModal = () => {
        setShowModal(!showModal);
    };

    const [pageCountAll, setpageCountAll] = useState(0);
    const [pageCountHistory, setpageCountHistory] = useState(0);

    let limit = 10;

    const handlePageClickAll = async (data) => {
        setGettingData(true);
        let currentPage = data.selected + 1;

        await axios
            .get(
                `${getBaseUrl()}/api/user/learnerSessions?page=${currentPage}&limit=${limit}`,
                config
            )
            .then((res) => {
                setAllClasses(res.data);
                setGettingData(false);
            });
    };
    const handlePageClickHistory = async (data) => {
        setGettingData(true);

        let currentPage = data.selected + 1;
        await axios
            .get(
                `${getBaseUrl()}/api/user/learner_sessions/history?page=${currentPage}&limit=${limit}`,
                config
            )
            .then((res) => {
                setHistorySessions(res.data);
                setGettingData(false);
            });
    };

    useEffect(() => {
        const fetchClasses_Session = async () => {
            try {
                if (displayUpcomingHistory === "history") {
                    await axios
                        .get(
                            `${getBaseUrl()}/api/user/learner_sessions/history?page=1&limit=${limit}`,
                            config
                        )
                        .then((res) => {
                            const total = res.headers.get("X-Total-Count");
                            setpageCountHistory(Math.ceil(total / limit));
                            setHistorySessions(res.data);
                            setIsLoadingHistory(false);
                        });
                } else if (displayUpcomingHistory === "upcoming") {
                    await axios
                        .get(
                            `${getBaseUrl()}/api/user/learner_sessions/upcoming`,
                            config
                        )
                        .then((res) => {
                            setUpcomingSessions(res.data);
                            setIsLoadingUpcoming(false);
                        });
                } else if (displayUpcomingHistory === "all") {
                    await axios
                        .get(
                            `${getBaseUrl()}/api/user/learnerSessions?page=1&limit=${limit}`,
                            config
                        )
                        .then((res) => {
                            const total = res.headers.get("X-Total-Count");
                            setpageCountAll(Math.ceil(total / limit));
                            setAllClasses(res.data);
                            setIsLoadingAll(false);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchClasses_Session();
    }, [displayUpcomingHistory, showModal]);
    const ClassesTenseOptions = () => {
        return (
            <>
                <div className="classestense-options">
                    <div className="option-box">
                        <p
                            id={`${
                                displayUpcomingHistory === "all"
                                    ? "selected"
                                    : "unselect"
                            }`}
                            onClick={() => {
                                setdisplayUpcomingHistory("all");
                            }}
                        >
                            ALL SESSIONS
                        </p>
                    </div>
                    <div className="option-box">
                        <p
                            id={`${
                                displayUpcomingHistory === "upcoming"
                                    ? "selected"
                                    : "unselect"
                            }`}
                            onClick={() => {
                                setdisplayUpcomingHistory("upcoming");
                            }}
                        >
                            UPCOMING SESSIONS
                        </p>
                    </div>
                    <div className="option-box">
                        <p
                            id={`${
                                displayUpcomingHistory === "history"
                                    ? "selected"
                                    : "unselect"
                            }`}
                            onClick={() => {
                                setdisplayUpcomingHistory("history");
                            }}
                        >
                            PREVIOUS SESSIONS
                        </p>
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            <div className="classes-wrapper">
                {/* ================= TITLE CONTAINER ===================*/}
                <div className="title-container" id={`${idRole}`}>
                    <div className="title-boxA">
                        <div className="option-box">
                            <FaArrowLeft
                                id="back-icon"
                                onClick={() =>
                                    setLearnerDisplay("LearnerOverview")
                                }
                            />{" "}
                            <p>COURSES</p>
                        </div>
                    </div>
                    <div className="title-boxB">
                        <div className="search-box">
                            <div className="input-container">
                                <FaSearch
                                    className="paginationbox-icon"
                                    id="search-icon"
                                />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="input-field"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* ================= CARD WRAPPER  ===================*/}
                <div className="card-wrapper">
                    <>
                        <ClassesTenseOptions />
                        {displayUpcomingHistory === "all" ? (
                            isLoadingAll ? (
                                <div
                                    className="loading-animation"
                                    id="classes-loading"
                                >
                                    <img
                                        src="loading.gif"
                                        alt="loading"
                                        id="loading-gif-id"
                                    />
                                </div>
                            ) : !allClasses.length ? (
                                <div className="empty-classes">
                                    <p>
                                        You are not enrolled to any courses yet.
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className="column-name"
                                        id="column-upcominghistory"
                                    >
                                        <div className="column-boxA">
                                            <div className="column-box">
                                                <p>COURSE</p>
                                            </div>
                                        </div>
                                        <div className="column-boxB">
                                            <div
                                                className="column-box"
                                                id="column-boxA"
                                            >
                                                <p>START</p>
                                            </div>
                                            <div className="column-box">
                                                <p>END</p>
                                            </div>
                                        </div>
                                    </div>
                                    {!gettingData ? (
                                        <div className="card-container">
                                            {allClasses
                                                .filter((session) => {
                                                    return search &&
                                                        search.toLowerCase() ===
                                                            ""
                                                        ? true
                                                        : session.sessionClass.title
                                                              .toLowerCase()
                                                              .includes(
                                                                  search.toLowerCase()
                                                              );
                                                })
                                                .map((classList) => (
                                                    <ViewSessions
                                                        viewClassCard={
                                                            classList
                                                        }
                                                        key={classList._id}
                                                    />
                                                ))}
                                            {search &&
                                                search.length > 0 &&
                                                !allClasses.filter((session) =>
                                                    session.sessionClass.title
                                                        .toLowerCase()
                                                        .includes(
                                                            search.toLowerCase()
                                                        )
                                                ).length && (
                                                    <div className="empty-classes">
                                                        <p>
                                                            No results found for
                                                            "{search}".
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                    ) : (
                                        <div
                                            className="loading-animation"
                                            id="classes-loading"
                                        >
                                            <img
                                                src="loading.gif"
                                                alt="loading"
                                                id="loading-gif-id"
                                            />
                                        </div>
                                    )}

                                    <div className="admin-pagination-box">
                                        <ReactPaginate
                                            previousLabel={"<<"}
                                            nextLabel={">>"}
                                            breakLabel={"..."}
                                            pageCount={pageCountAll}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClickAll}
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </>
                            )
                        ) : null}
                        {displayUpcomingHistory === "upcoming" ? (
                            isLoadingUpcoming ? (
                                <div
                                    className="loading-animation"
                                    id="classes-loading"
                                >
                                    <img
                                        src="loading.gif"
                                        alt="loading"
                                        id="loading-gif-id"
                                    />
                                </div>
                            ) : !upcomingSessions.length ? (
                                <div className="empty-classes">
                                    <p>You have no upcoming sessions.</p>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className="column-name"
                                        id="column-upcominghistory"
                                    >
                                        <div className="column-boxA">
                                            <div className="column-box">
                                                <p>COURSE</p>
                                            </div>
                                        </div>
                                        <div className="column-boxB">
                                            <div
                                                className="column-box"
                                                id="column-boxA"
                                            >
                                                <p>START</p>
                                            </div>
                                            <div className="column-box">
                                                <p>END</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-container">
                                        {upcomingSessions
                                            .filter((session) => {
                                                return search &&
                                                    search.toLowerCase() === ""
                                                    ? true
                                                    : session.sessionClass.title
                                                          .toLowerCase()
                                                          .includes(
                                                              search.toLowerCase()
                                                          );
                                            })
                                            .map((classList) => (
                                                <ViewSessionUpcoming
                                                    viewClassCard={classList}
                                                    key={classList._id}
                                                    closeModal={closeModal}
                                                />
                                            ))}
                                        {search &&
                                            search.length > 0 &&
                                            !upcomingSessions.filter(
                                                (session) =>
                                                    session.sessionClass.title
                                                        .toLowerCase()
                                                        .includes(
                                                            search.toLowerCase()
                                                        )
                                            ).length && (
                                                <div className="empty-classes">
                                                    <p>
                                                        No results found for "
                                                        {search}".
                                                    </p>
                                                </div>
                                            )}
                                    </div>
                                </>
                            )
                        ) : null}
                        {displayUpcomingHistory === "history" ? (
                            isLoadingHistory ? (
                                <div
                                    className="loading-animation"
                                    id="classes-loading"
                                >
                                    <img
                                        src="loading.gif"
                                        alt="loading"
                                        id="loading-gif-id"
                                    />
                                </div>
                            ) : !historySessions.length ? (
                                <div className="empty-classes">
                                    <p>Nothing to show here.</p>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className="column-name"
                                        id="column-upcominghistory"
                                    >
                                        <div className="column-boxA">
                                            <div className="column-box">
                                                <p>COURSE</p>
                                            </div>
                                        </div>
                                        <div className="column-boxB">
                                            <div
                                                className="column-box"
                                                id="column-boxA"
                                            >
                                                <p>START</p>
                                            </div>
                                            <div className="column-box">
                                                <p>END</p>
                                            </div>
                                        </div>
                                    </div>
                                    {!gettingData ? (
                                        <div className="card-container">
                                            {historySessions
                                                .filter((session) => {
                                                    return search &&
                                                        search.toLowerCase() ===
                                                            ""
                                                        ? true
                                                        : session.sessionClass.title
                                                              .toLowerCase()
                                                              .includes(
                                                                  search.toLowerCase()
                                                              );
                                                })
                                                .map((classList) => (
                                                    <ViewSessions
                                                        viewClassCard={
                                                            classList
                                                        }
                                                        key={classList._id}
                                                    />
                                                ))}
                                            {search &&
                                                search.length > 0 &&
                                                !upcomingSessions.filter(
                                                    (session) =>
                                                        session.sessionClass.title
                                                            .toLowerCase()
                                                            .includes(
                                                                search.toLowerCase()
                                                            )
                                                ).length && (
                                                    <div className="empty-classes">
                                                        <p>
                                                            No results found for
                                                            "{search}".
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                    ) : (
                                        <div
                                            className="loading-animation"
                                            id="classes-loading"
                                        >
                                            <img
                                                src="loading.gif"
                                                alt="loading"
                                                id="loading-gif-id"
                                            />
                                        </div>
                                    )}

                                    <div className="admin-pagination-box">
                                        <ReactPaginate
                                            previousLabel={"<<"}
                                            nextLabel={">>"}
                                            breakLabel={"..."}
                                            pageCount={pageCountHistory}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={
                                                handlePageClickHistory
                                            }
                                            containerClassName={"pagination"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </>
                            )
                        ) : null}
                    </>
                </div>
            </div>
        </>
    );
};
export default LearnerClasses;
