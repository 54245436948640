import React, { useState } from "react";
import axios from 'axios';
import "../../Admin/css/admin-classes.css";

import { FaEye } from "react-icons/fa";

import ModalActivityView from "./ModalActivityView";
const CardClasses = ({ activity }) => {

  const [activityViewDetails, setActivityViewDetails] = useState(false);

  const date = new Date(activity.createdAt);

  const toggleActivityView = () => {
    setActivityViewDetails(!activityViewDetails);
  };

  return (
    <div className="classes-row">
      <div className="classes-info" id="activity-column">
        <p className="activity-box">{activity.user}</p>
      </div>
      <div className="classes-info" id="activity-column">
        <p className="activity-box">{activity.action}</p>
      </div>
      <div className="classes-info" id="activity-column">
        <p className="activity-box">{activity.details}</p>
      </div>
      <div className="classes-info" id="activity-column">
        <p className="activity-box">{date.toLocaleDateString()} {date.toLocaleTimeString([], { hour12: true })}</p>
      </div>

    </div>
  );
};

export default CardClasses;
