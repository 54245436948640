import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../App";

import { PiPersonSimpleRunFill } from "react-icons/pi";
import { getBaseUrl } from "../../baseURL";

const ClassSessionCard = ({ session, closeModal }) => {
    const { appState } = useContext(AppContext);
    const { config } = useContext(AppContext);
    const [buttonPopUp, setButtonPopUp] = useState(false);
    const [isEnrolled, setIsEnrolled] = useState(false);
    const [isFull, setIsFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isExpired, setIsExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { capacity, learners } = session;

    const optionsDate = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };
    const optionsTime = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };

    useEffect(() => {
        const checkEnrollment = async () => {
            for (let i = 0; i < session.learners.length; i++) {
                const learner = session.learners[i];
                if (learner._id === appState.id) {
                    setIsEnrolled(true);
                }
            }
        };
        const checkClassIfFull = async () => {
            if (capacity === learners.length) {
                setIsFull(true);
            }
        };

        const checkClassIfExpired = async () => {
            // Get the current date
            const currentDate = new Date();
            // Get the given date
            const givenDate = new Date(session.endTime);

            // Compare the two dates
            if (currentDate.getTime() > givenDate.getTime()) {
                setIsExpired(true);
            }
        };

        checkEnrollment();
        checkClassIfFull();
        checkClassIfExpired();
    }, [appState.id, capacity, learners.length, session.endTime, session.learners]);

    const handleEnroll = () => {
        setButtonPopUp(true);
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            await axios.post(`${getBaseUrl()}/api/session/${session._id}/enroll`, {}, config).then((res) => {
                if (res.status === 400) {
                    setErrorMessage(res.data.message);
                } else {
                    setIsEnrolled(true);
                    closeModal();
                    setIsLoading(false);
                }
            });
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const ConfirmEnrollment = () => {
        return (
            <div className="popup" id="popup-overlay">
                <div className="popup-inner">
                    <div className="circle-box">
                        <div className="leaping-icon-container">
                            <PiPersonSimpleRunFill className="checkicon" />
                        </div>

                        <h3>
                            READY TO <br /> TAKE THE LEAP?
                        </h3>
                    </div>
                    <div className="popup-txt-contr">
                        <div className="txt-cont">
                            <p className="bold-txt">Confirm to enroll in this course.</p>
                        </div>
                        <div className="btn-cont">
                            {!isLoading ? (
                                <>
                                    <button className="close-btn" onClick={handleSubmit} id="confirm">
                                        CONFIRM
                                    </button>
                                    <button className="close-btn" onClick={() => setButtonPopUp(false)} id="cancel">
                                        CANCEL
                                    </button>
                                </>
                            ) : (
                                <div className="submit-loading">
                                    <img src="loading-small.gif" alt="loading" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="column-name">
            <div className="column-box">
                <p className="time">{new Date(session.startTime).toLocaleTimeString(undefined, optionsTime)}</p>
                <p className="date">{new Date(session.startTime).toLocaleDateString(undefined, optionsDate)}</p>
            </div>
            <div className="column-box">
                <p className="time">{new Date(session.endTime).toLocaleTimeString(undefined, optionsTime)}</p>
                <p className="date">{new Date(session.endTime).toLocaleDateString(undefined, optionsDate)}</p>
            </div>

            <div className="column-box">
                <p>
                    {learners.length}/{capacity}
                </p>
            </div>
            <div className="column-box">
                {isEnrolled ? (
                    // IS ENROLLED (YES)
                    <>
                        {isExpired ? (
                            // IS ENROLLED BUT EXPIRED
                            <div className="session-completed">SESSION COMPLETE</div>
                        ) : (
                            // NOT EXPIRED BUT ALREADY ENROLLED
                            <div className="enrolled-btn">ENROLLED</div>
                        )}
                    </>
                ) : (
                    // <>{isFull ? <div className="session-missed-btn">full</div> : <div className="session-missed-btn">enroll</div>}</>

                    // IS NOT ENROLLED
                    <div>
                        {isExpired ? (
                            // NOT ENROLLED AND THE CLASS EXPIRED
                            <div className="session-missed">SESSION MISSED</div>
                        ) : (
                            <>
                                {isFull ? (
                                    //NOT ENROLLED, NOT EXPIRED, BUT FULL
                                    <div className="full-class">FULL</div>
                                ) : (
                                    // NOT ENROLLED, NOT EXPIRED, READY TO ENROLL
                                    <div className="enroll-btn" onClick={handleEnroll}>
                                        ENROLL
                                    </div>
                                )}
                                {buttonPopUp && <ConfirmEnrollment />}
                            </>
                        )}
                    </div>
                )}
                {errorMessage}
            </div>
        </div>
    );
};

export default ClassSessionCard;
