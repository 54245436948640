import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../App";
import axios from "axios";

import { MdClose } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import "../css/modal-user-edit.css";
import { getBaseUrl } from "../../baseURL";

const ModalUserEdit = ({ toggleUserEdit, user, setUsers }) => {
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthday, setBirthday] = useState("");
    const [gender, setGender] = useState("");
    const [jobLevel, setjobLevel] = useState("");
    const [role, setUserRole] = useState("");
    const [userData, setUserData] = useState({});
    const { config } = useContext(AppContext);
    const [isShow, setisShow] = useState(true);

    useEffect(() => {
        setUserData(user);
    }, [user]);

    const [buttonPopUp, setButtonPopUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const EditUserPopUp = () => {
        const handleButtonClick = () => {
            setButtonPopUp(false);
        };

        return (
            <>
                <div className="popup" id="popup-overlay">
                    <div className="popup-inner">
                        <div className="circle-box">
                            <IoCheckmarkCircleOutline className="checkicon" />
                            <h3>SUCCESS!</h3>
                        </div>
                        <div className="popup-txt-contr">
                            <div className="txt-cont">
                                <p className="bold-txt">Updated Profile Successful</p>
                                <p>You have successfully updated the user's profile.</p>
                            </div>
                            <div className="btn-cont">
                                <button className="close-btn" onClick={handleButtonClick}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        try {
            const userId = userData._id;

            const updatedFormData = {
                username: username || user.username,
                firstName: firstName || user.firstName,
                lastName: lastName || user.lastName,
                phoneNumber: phoneNumber || user.phoneNumber,
                birthday: birthday || user.birthday,
                gender: gender || user.gender,
                jobLevel: jobLevel || user.jobLevel,
                role: role || user.role,
            };
            await axios.put(`${getBaseUrl()}/api/user/${userId}`, updatedFormData, config).then(async (res) => {
                if (res.status === 201) {
                    setButtonPopUp(true);
                }
            });
        } catch (error) {
            setErrorMessage(error.response.data);
        }
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const handleAnimate = () => {
        setisShow(false);
        toggleUserEdit();
    };

    return (
        <div className={`overlay ${isShow ? "fade-in" : "fade-out"}`} onClick={handleAnimate}>
            <div className={`modal-content ${isShow ? "fade-in" : "fade-out"}`} onClick={handleModalClick}>
                <div className="title-container">
                    <MdClose
                        id="back-icon"
                        onClick={async () => {
                            setisShow(false);
                            toggleUserEdit();
                            await axios.get(`${getBaseUrl()}/api/user`, config).then((res) => {
                                setUsers(res.data);
                            });
                        }}
                    />
                    <h1>{user.firstName + " " + user.lastName}</h1>
                </div>
                <form onSubmit={handleSubmit} className="info-container" id="user-edit-container">
                    <div className="key-value">
                        <p>First Name</p>
                        <div className="input-container">
                            <input
                                type="text"
                                value={firstName}
                                placeholder={user.firstName}
                                onChange={(e) => {
                                    // Remove non-letter and non-space characters
                                    const newValue = e.target.value.replace(/[^a-zA-Z\s]+/g, "");
                                    // Capitalize the first letter of each word
                                    const capitalizedValue = newValue
                                        .split(" ")
                                        .map((word) => {
                                            return word.charAt(0).toUpperCase() + word.slice(1);
                                        })
                                        .join(" ");
                                    // Update the state
                                    setFirstName(capitalizedValue);
                                }}
                                className="input-field"
                            />
                        </div>
                    </div>

                    <div className="key-value">
                        <p>Last Name</p>
                        <div className="input-container">
                            <input
                                type="text"
                                value={lastName}
                                placeholder={user.lastName}
                                onChange={(e) => {
                                    // Remove non-letter and non-space characters
                                    const newValue = e.target.value.replace(/[^a-zA-Z\s]+/g, "");
                                    // Capitalize the first letter of each word
                                    const capitalizedValue = newValue
                                        .split(" ")
                                        .map((word) => {
                                            return word.charAt(0).toUpperCase() + word.slice(1);
                                        })
                                        .join(" ");
                                    // Update the state
                                    setLastName(capitalizedValue);
                                }}
                                className="input-field"
                            />
                        </div>
                    </div>

                    <div className="key-value">
                        <p>Phone #</p>
                        <div className="input-container">
                            <input
                                type="tel"
                                pattern="[0]{1}[9]{1}[0-9]{9}"
                                maxLength={11}
                                value={phoneNumber}
                                placeholder={user.phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className="input-field"
                            />
                        </div>
                    </div>

                    <div className="key-value">
                        <p>Gender</p>
                        <div className="input-container">
                            <select value={gender} onChange={(e) => setGender(e.target.value)}>
                                <option value="" hidden>
                                    {user.gender}
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>

                    <div className="key-value">
                        <p>Birthday</p>
                        <div className="input-container">
                            <input
                                type="date"
                                value={birthday}
                                placeholder="birthday"
                                min="1900-01-01"
                                max={new Date().toISOString().split("T")[0]}
                                onChange={(e) => setBirthday(e.target.value)}
                                className="input-field"
                                id="birthday"
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="key-value">
                        <p>Username</p>
                        <div className="input-container">
                            <input
                                type="text"
                                value={username}
                                placeholder={user.username}
                                onChange={(e) => {
                                    // Remove non-letter and non-number characters from the input value
                                    const newValue = e.target.value.replace(/[^a-zA-Z0-9]+/g, "");
                                    // Update the state
                                    setUsername(newValue);
                                }}
                                className="input-field"
                            />
                        </div>
                    </div>

                    <div className="key-value">
                        <p> Job Level</p>
                        <div className="input-container">
                            <select onChange={(e) => setjobLevel(e.target.value)}>
                                <option value="" hidden>
                                    {user.jobLevel}
                                </option>
                                <option value="" disabled>
                                    Leadership Roles
                                </option>
                                <option value="Admin Assistant">Admin Assistant</option>
                                <option value="Accounts Manager">Accounts Manager</option>
                                <option value="Ambassador">Ambassador</option>
                                <option value="Executive Assistant">Executive Assistant</option>
                                <option value="Quality Assurance">Quality Assurance</option>
                                <option value="SME">SME</option>
                                <option value="Administrator">Administrator</option>
                                <option value="Analyst">Analyst</option>
                                <option value="Coordinator">Coordinator</option>
                                <option value="Director">Director</option>
                                <option value="Lead Recruiter">Lead Recruiter</option>
                                <option value="Manager">Manager</option>
                                <option value="Senior Manager">Senior Manager</option>
                                <option value="Supervisory">Supervisory</option>
                                <option value="Trainer">Trainer</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>

                    <div className="key-value">
                        <p>User Type</p>
                        <div className="input-container">
                            <select value={role} onChange={(e) => setUserRole(e.target.value)}>
                                <option value="" hidden>
                                    {user.role}
                                </option>
                                <option value="admin">administrator</option>
                                <option value="instructor">instructor</option>
                                <option value="learner">learner</option>
                            </select>
                        </div>
                    </div>

                    <hr></hr>

                    {errorMessage && (
                        <p className="error-message" id="end-position">
                            {errorMessage}
                        </p>
                    )}

                    <div className="btn-container">
                        <button className="adminInfo-upd-btn" type="submit">
                            UPDATE PROFILE
                        </button>
                    </div>
                </form>
                {buttonPopUp && <EditUserPopUp />}
            </div>
        </div>
    );
};

export default ModalUserEdit;
