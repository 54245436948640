import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import { Routes, Route, Navigate } from "react-router-dom";
import Body from "./SpecialPages/components/Body";
import Navbar from "./SpecialPages/components/Navbar";
import VerifiedEmail from "./SpecialPages/components/VerifiedEmail";
import Error from "./SpecialPages/components/Error";
import Footer from "./SpecialPages/components/Footer";
import SignUpForm from "./SpecialPages/components/Auth/SignupForm";
import ForgotPasswordForm from "./SpecialPages/components/Auth/ForgotPassword";
import ResetPasswordForm from "./SpecialPages/components/Auth/ResetPassword";
import { getBaseUrl } from "./baseURL";

export const AppContext = createContext();

function App() {
  const [appState, setAppState] = useState({
    role: null,
    currentRole: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");
  const config = { headers: { authorization: `Bearer ${token}` } };
  const [display, setDisplay] = useState("");
  const [instructorDisplay, setInstructorDisplay] = useState("");
  const [learnerDisplay, setLearnerDisplay] = useState("");

  useEffect(() => {
    setTimeout(async () => {
      if (token) {
        axios
          .get(`${getBaseUrl()}/api/verify/verify2`, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const user = res.data.user;
            setAppState({
              role: user.role,
              currentRole: user.role,
              firstName: user.firstName,
              lastName: user.lastName,
              jobLevel: user.jobLevel,
              username: user.username,
              id: user._id,
              phoneNumber: user.phoneNumber,
              birthday: user.birthday,
              gender: user.gender,
              email: user.email,
            });
            setIsLoading(false);
          })
          .catch((error) => {
            localStorage.removeItem("token");
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    }, 2000);
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <div className="main-loading-animation">
          {/* <img src="appjs-loading.gif" alt="loading" id="loading-gif-id" /> */}
          <img src="leap-loading2.gif" alt="loading" id="loading-gif-id" />
        </div>
      ) : (
        <AppContext.Provider
          value={{
            appState,
            setAppState,
            display,
            setDisplay,
            instructorDisplay,
            setInstructorDisplay,
            learnerDisplay,
            setLearnerDisplay,
            config,
          }}
        >
          <Navbar />

          <Routes>
            <Route path="*" element={<Error />} />
            <Route path="/" element={<Body />} />
            <Route
              path="/signup"
              element={
                appState.role === null ? <SignUpForm /> : <Navigate to="/" />
              }
            />
            <Route
              path="/forgot"
              element={
                appState.role === null ? (
                  <ForgotPasswordForm />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/resetpassword"
              element={
                appState.role === null ? (
                  <ResetPasswordForm />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/verifiedemail"
              element={
                appState.role === null ? <VerifiedEmail /> : <Navigate to="/" />
              }
            />
          </Routes>

          <Footer />
        </AppContext.Provider>
      )}
    </div>
  );
}

export default App;
