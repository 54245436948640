import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import { AppContext } from "../../App";
import "../css/learner-dashboard.css";
import ClassesTodayGrid from "../../SpecialPages/components/ClassesTodayGrid";
import { getBaseUrl } from "../../baseURL";

const ClassesToday = () => {
  const { config } = useContext(AppContext);
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  console.log();
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`${getBaseUrl()}/api/user/learner/sessions/today`, config)
        .then((res) => {
          setClasses(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 800);
  }, []);

  return (
    <div>
      <div className="title-container">
        <p>YOUR COURSES TODAY</p>
      </div>
      <div className="card-wrapper">
        {isLoading ? (
          <div className="loading-animation">
            <img src="loading.gif" alt="loading" id="loading-gif-id" />
          </div>
        ) : !classes.length ? (
          <div className="no-classes">
            <p>You have no sessions for Today.</p>
          </div>
        ) : (
          <div className="card-container">
            {classes.map((i) => (
              <ClassesTodayGrid sessionsToday={i} key={i._id} i={i} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassesToday;
