import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../../App";
import AdminLeftDashboard from "./AdminLeftDashboard";
import AdminSideBar from "../../SpecialPages/components/Navbar";

import AdminUpdateProfile from "./AdminUpdateProfile";
import AdminUpdatePassword from "./AdminUpdatePassword";
import AdminOverview from "./AdminOverview";
import AdminUsers from "./AdminUsers";
import AdminAddUser from "./AdminAddUser";
import AdminAddClass from "./AdminAddClass";
import AdminActivity from "./AdminActivityView";

import "../css/admin-dashboard.css";
import AdminClasses from "./AdminClasses";

const AdminDashboard = () => {
    const { display, setDisplay } = useContext(AppContext);

    useEffect(() => (
        setDisplay("AdminOverview")
    ), [])

    return (
        <div className="admin-container">
            <div className="admin-dashboard">
                <AdminLeftDashboard />
            </div>
            <div className="admin-overview">
                {display === "AdminHome" ? <AdminOverview /> : null}
                {display === "AdminUpdateProfile" ? <AdminUpdateProfile /> : null}
                {display === "AdminUpdatePassword" ? <AdminUpdatePassword /> : null}
                {display === "AdminOverview" ? <AdminOverview /> : null}
                {display === "AdminAddUser" ? <AdminAddUser /> : null}
                {display === "AdminUsers" ? <AdminUsers /> : null}
                {display === "AdminAddClass" ? <AdminAddClass /> : null}
                {display === "AdminViewClass" ? <AdminClasses /> : null}
                {display === "AdminActivity" ? <AdminActivity /> : null}
            </div>
        </div>
    );
};

export default AdminDashboard;
