// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-content .title-container {\n    display: flex;\n    font-weight: 600;\n}\n\n.modal-content .key-value {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    margin: 15px 0px;\n}\n\n.modal-content .key-value p {\n    width: 25%;\n    margin-right: 15px;\n}\n.modal-content .input-container {\n    height: -moz-fit-content;\n    height: fit-content;\n    width: 75%;\n    display: flex;\n    align-items: flex-start;\n}\n\n.modal-content .info-container .personal-info p {\n    /* background-color: paleturquoise; */\n}\n\n.info-container .dropdown-checkbox .css-1jqq78o-placeholder {\n    display: flex;\n}", "",{"version":3,"sources":["webpack://./src/SpecialPages/css/modal-user-edit.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,wBAAmB;IAAnB,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,aAAa;AACjB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap\");\n\n.modal-content .title-container {\n    display: flex;\n    font-weight: 600;\n}\n\n.modal-content .key-value {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    margin: 15px 0px;\n}\n\n.modal-content .key-value p {\n    width: 25%;\n    margin-right: 15px;\n}\n.modal-content .input-container {\n    height: fit-content;\n    width: 75%;\n    display: flex;\n    align-items: flex-start;\n}\n\n.modal-content .info-container .personal-info p {\n    /* background-color: paleturquoise; */\n}\n\n.info-container .dropdown-checkbox .css-1jqq78o-placeholder {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
