import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "../../SpecialPages/css/profile.css";

import { AppContext } from "../../App";

import PopUp from "../../SpecialPages/components/PopUpHome";
import PasswordValidator from "../../SpecialPages/components/PasswordValidator";

import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { getBaseUrl } from "../../baseURL";

const AdminUpdatePassword = () => {
  const { appState, config } = useContext(AppContext);

  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [errorOldPass, setErrorOldPAss] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [user, setUser] = useState(null);
  const userId = appState.id;
  const userEmail = appState.email;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${getBaseUrl()}/api/user/${userId}`,
          config
        );

        setUser(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setErrorConfirmPass("Passwords do not match");
    } else {
      try {
        const data = {
          password: oldPassword,
          newPassword: newPassword,
        };
        await axios
          .put(`${getBaseUrl()}/api/user/updatepassword`, data, config)
          .then(async (res) => {
            if (res.status === 201) {
              setButtonPopUp(true);
            }
          });
      } catch (err) {
        setErrorOldPAss(
          <p className="error-message">{err.response.data.message}</p>
        );
      }
    }
  };

  useEffect(() => {
    if (errorOldPass || errorConfirmPass) {
      setTimeout(() => {
        setErrorConfirmPass("");
        setErrorOldPAss("");
      }, 3000);
    }
  }, [errorOldPass, errorConfirmPass]);

  const UpdatePasswordPopUp = () => {
    const { setDisplay } = useContext(AppContext);

    const handleButtonClick = () => {
      setDisplay("AdminOverview");
    };

    return (
      <>
        <div className="popup">
          <div className="popup-inner">
            <div className="circle-box">
              <IoCheckmarkCircleOutline className="checkicon" />
              <h3>SUCCESS!</h3>
            </div>
            <div className="popup-txt-contr">
              <div className="txt-cont">
                <p className="bold-txt">Update Password Successful</p>
                <p>You have successfully updated your password.</p>
              </div>
              <div className="btn-cont">
                <button className="close-btn" onClick={handleButtonClick}>
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="admin-profile-page">
      <div className="profile-card">
        <div className="title-container">
          <h1>{user ? user.firstName + " " + user.lastName : " "}</h1>
        </div>
        <form onSubmit={handleSubmit} className="info-container">
          <div className="key-value">
            <p>Old Password</p>
            <div className="input-container">
              <input
                required
                type={showPassword ? "text" : "password"}
                placeholder={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="input-field"
              />
              {showPassword ? (
                <FaEyeSlash
                  className="password-show"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <FaEye
                  className="password-hide"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
          </div>
          <div>
            {errorOldPass && (
              <p className="error-message" id="end-position">
                {"Passwords do not match"}
              </p>
            )}
          </div>

          <hr />

          <div className="key-value">
            <p>New Password</p>
            <div className="input-container">
              <input
                required
                type="password"
                placeholder={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="input-field"
              />
            </div>
          </div>

          <div className="key-value">
            <p></p>
            <div className="password-validator">
              <PasswordValidator password={newPassword} />
            </div>
          </div>

          <div className="key-value">
            <p>Confirm New Password</p>
            <div className="input-container">
              <input
                required
                type="password"
                placeholder={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className="input-field"
              />
            </div>
          </div>
          {errorConfirmPass && (
            <p className="error-message" id="end-position">
              {"Passwords do not match"}
            </p>
          )}
          <hr></hr>

          <div className="btn-container">
            <button className="adminInfo-upd-btn" type="submit">
              UPDATE PASSWORD
            </button>
            <br />
          </div>
        </form>
        {buttonPopUp && <UpdatePasswordPopUp />}
      </div>
    </div>
  );
};

export default AdminUpdatePassword;
