import React, { useState, useContext } from "react";
import "../css/modal-user-delete.css";
import axios from "axios";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { TbFileExport } from "react-icons/tb";
import { AppContext } from "../../App";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getBaseUrl } from "../../baseURL";

const ModalExport = ({ toggleModalExport, isModalExport, exportWhat }) => {
    const [confirmed, setConfirmed] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [setShowExportSuccess] = useState(false);
    const { config } = useContext(AppContext);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    // const popupId = isModalExport ? "popup-overlay" : undefined;

    const handleExport = async () => {
        if (exportWhat === "activity") {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/export/excel/activity`, {
                    ...config,
                    responseType: "blob",
                    params: {
                        startDate: moment(startDate).startOf("day").toDate(),
                        endDate: moment(endDate).endOf("day").toDate(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const dateStartFileName = new Date(startDate).toISOString().slice(0, 10);
                const dateEndFileName = new Date(endDate).toISOString().slice(0, 10);
                link.setAttribute("download", `[(${dateStartFileName})-(${dateEndFileName})]_LEAP_ACTIVITY_LOG.xlsx`);
                document.body.appendChild(link);
                link.click();
                if (response.status === 201) {
                    setConfirmed(true);
                    setShowExportSuccess(true);
                    console.log("EXPORTED ACTIVITY LOG");
                }
            } catch (error) {
                setErrorMessage("An error has been encountered.");
            }
        } else if (exportWhat === "all-data") {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/export/excel/`, {
                    ...config,
                    responseType: "blob",
                    params: {
                        startDate: moment(startDate).startOf("day").toDate(),
                        endDate: moment(endDate).endOf("day").toDate(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const dateStartFileName = new Date(startDate).toISOString().slice(0, 10);
                const dateEndFileName = new Date(endDate).toISOString().slice(0, 10);
                link.setAttribute("download", `[(${dateStartFileName})-(${dateEndFileName})]_LDTC_All_DATA.xlsx`);
                document.body.appendChild(link);
                link.click();
                if (response.status === 201) {
                    setConfirmed(true);
                    setShowExportSuccess(true);
                    console.log("EXPORTED ALL DATA");
                }
            } catch (error) {
                setErrorMessage("An error has been encountered.");
            }
        } else if (exportWhat === "classes") {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/export/excel/class`, {
                    ...config,
                    responseType: "blob",
                    params: {
                        startDate: moment(startDate).startOf("day").toDate(),
                        endDate: moment(endDate).endOf("day").toDate(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const dateStartFileName = new Date(startDate).toISOString().slice(0, 10);
                const dateEndFileName = new Date(endDate).toISOString().slice(0, 10);
                link.setAttribute("download", `[(${dateStartFileName})-(${dateEndFileName})]_LEAP_CLASSES.xlsx`);
                document.body.appendChild(link);
                link.click();
                if (response.status === 201) {
                    setConfirmed(true);
                    setShowExportSuccess(true);
                    console.log("EXPORTED ALL CLASSES");
                }
            } catch (error) {
                setErrorMessage("An error has been encountered.");
            }
        } else if (exportWhat === "users") {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/export/excel/user`, {
                    ...config,
                    responseType: "blob",
                    params: {
                        startDate: moment(startDate).startOf("day").toDate(),
                        endDate: moment(endDate).endOf("day").toDate(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                // const today = new Date().toISOString().slice(0, 10);
                const dateStartFileName = new Date(startDate).toISOString().slice(0, 10);
                const dateEndFileName = new Date(endDate).toISOString().slice(0, 10);
                link.setAttribute("download", `[(${dateStartFileName})-(${dateEndFileName})]_LEAP_USERS.xlsx`);
                document.body.appendChild(link);
                link.click();
                if (response.status === 201) {
                    setConfirmed(true);
                    setShowExportSuccess(true);
                    console.log("EXPORTED ALL USERS");
                }
            } catch (error) {
                setErrorMessage("An error has been encountered.");
            }
        }
    };

    const handleExportAll = async () => {
        const minDate = "2023-01-01";
        if (exportWhat === "activity") {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/export/excel/activity`, {
                    ...config,
                    responseType: "blob",
                    params: {
                        startDate: moment(minDate).startOf("day").toDate(),
                        endDate: moment().endOf("day").toDate(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const today = new Date().toISOString().slice(0, 10);
                link.setAttribute("download", `[${today}]_LEAP_ALL_ACTIVITY_LOG.xlsx`);
                document.body.appendChild(link);
                link.click();
                if (response.status === 201) {
                    setConfirmed(true);
                    setShowExportSuccess(true);
                    console.log("EXPORTED ALL ACTIVITY LOG");
                }
            } catch (error) {
                setErrorMessage("An error has been encountered.");
            }
        } else if (exportWhat === "all-data") {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/export/excel/`, {
                    ...config,
                    responseType: "blob",
                    params: {
                        startDate: moment(minDate).startOf("day").toDate(),
                        endDate: moment().endOf("day").toDate(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const today = new Date().toISOString().slice(0, 10);
                link.setAttribute("download", `${today}_LDTC_ALL_DATA.xlsx`);
                document.body.appendChild(link);
                link.click();
                if (response.status === 201) {
                    setConfirmed(true);
                    setShowExportSuccess(true);
                    console.log("EXPORTED ALL DATA");
                }
            } catch (error) {
                setErrorMessage("An error has been encountered.");
            }
        } else if (exportWhat === "classes") {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/export/excel/class`, {
                    ...config,
                    responseType: "blob",
                    params: {
                        startDate: moment(minDate).startOf("day").toDate(),
                        endDate: moment().endOf("day").toDate(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const today = new Date().toISOString().slice(0, 10);
                link.setAttribute("download", `${today}_LEAP_ALL_CLASSES.xlsx`);
                document.body.appendChild(link);
                link.click();
                if (response.status === 201) {
                    setConfirmed(true);
                    setShowExportSuccess(true);
                    console.log("EXPORTED ALL CLASSES");
                }
            } catch (error) {
                setErrorMessage("An error has been encountered.");
            }
        } else if (exportWhat === "users") {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/export/excel/user`, {
                    ...config,
                    responseType: "blob",
                    params: {
                        startDate: moment(minDate).startOf("day").toDate(),
                        endDate: moment().endOf("day").toDate(),
                    },
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const today = new Date().toISOString().slice(0, 10);
                link.setAttribute("download", `${today}_LEAP_ALL_USERS.xlsx`);
                document.body.appendChild(link);
                link.click();
                if (response.status === 201) {
                    setConfirmed(true);
                    setShowExportSuccess(true);
                    console.log("EXPORTED ALL USERS");
                }
            } catch (error) {
                setErrorMessage("An error has been encountered.");
            }
        }
    };

    const ExportSuccessful = () => {
        return (
            <>
                <div className="circle-box">
                    <IoCheckmarkCircleOutline className="checkicon" />
                    <h3>Success!</h3>
                </div>
                <div className="popup-txt-contr">
                    <div className="txt-cont">
                        <p>Export Successfully</p>
                    </div>
                    <div className="btn-cont">
                        <button className="close-btn">OK</button>
                    </div>
                </div>
            </>
        );
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div className="popup" id="whole-screen-pop-up">
            <div className="popup-inner" onClick={(e) => handleModalClick(e)}>
                {!confirmed ? (
                    <>
                        <div className="circle-box" id="download">
                            <TbFileExport className="checkicon" />
                            <h3>EXPORT TO EXCEL?</h3>
                        </div>
                        <div className="popuptext">
                            <div className="export-dates-container">
                                <div className="date-selection">
                                    <div className="start-container">
                                        <div className="session-container-start" id="date">
                                            <p>DATE START</p>
                                            <div className="date-container">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    className="time-date"
                                                    showPopperArrow={false}
                                                    popperPlacement="right-start"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="end-container">
                                        <div className="session-container-end" id="date">
                                            <p>DATE END</p>
                                            <div className="date-container">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    minDate={startDate}
                                                    className="time-date"
                                                    showPopperArrow={false}
                                                    popperPlacement="right-start"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="export-btn-container">
                                    <button className="close-btn" onClick={handleExport} type="button" id="export-dates-btn">
                                        EXPORT DATES
                                    </button>
                                </div>
                            </div>
                            <div className="or-section">
                                <hr></hr>
                                <p>OR</p>
                                <hr></hr>
                            </div>

                            <div className="exportall-btn-container" id="yes-no-container">
                                {exportWhat === "classes" && (
                                    <button className="exportall-btn" onClick={handleExportAll} type="button">
                                        EXPORT ALL CLASSES
                                    </button>
                                )}
                                {exportWhat === "users" && (
                                    <button className="exportall-btn" onClick={handleExportAll} type="button">
                                        EXPORT ALL USERS
                                    </button>
                                )}
                                {exportWhat === "activity" && (
                                    <button className="exportall-btn" onClick={handleExportAll} type="button">
                                        EXPORT ALL ACTIVITY LOGS
                                    </button>
                                )}
                                {exportWhat === "all-data" && (
                                    <button className="exportall-btn" onClick={handleExportAll} type="button">
                                        EXPORT ALL DATA
                                    </button>
                                )}
                                <button className="close-btn" onClick={() => toggleModalExport()} type="button" id="cancel-export-btn">
                                    CANCEL
                                </button>
                            </div>
                            {errorMessage}
                        </div>
                    </>
                ) : (
                    <ExportSuccessful isModalExport={true} toggleModalExport={toggleModalExport} />
                )}
            </div>
        </div>
    );
};

export default ModalExport;
