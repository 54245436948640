import React, { useState, useContext } from "react";
// import "../css/modal-user-delete.css";
import axios from "axios";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { PiSealWarningFill } from "react-icons/pi";
import { AppContext } from "../../App";
import { getBaseUrl } from "../../baseURL";

const ModalUserDelete = ({ toggleUserDelete, user, closeModal }) => {
  const [confirmed, setConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const { config } = useContext(AppContext);

  const handleDelete = async () => {
    setErrorMessage("");
    try {
      await axios
        .delete(`${getBaseUrl()}/api/user/${user._id}`, config)
        .then((res) => {
          if (res.status === 201) {
            setConfirmed(true);
            setShowDeleteSuccess(true);
          }
        });
    } catch (error) {
      console.error(error);
      setErrorMessage("An error has been encountered.");
    }
  };

  return (
    <>
      {!confirmed && (
        <div className="popup">
          <div className="popup-inner">
            <div className="circle-box" id="warning">
              <PiSealWarningFill className="checkicon" />
              <h3>DELETE USER?</h3>
            </div>
            <div className="popup-txt-contr">
              <div className="txt-cont">
                <p>Are you sure you want to delete:</p>
                <p className="bold-txt">
                  {user.firstName + " " + user.lastName}
                </p>
              </div>
              <div className="btn-cont delete-contr">
                <button className="close-btn" onClick={handleDelete} id="yes">
                  YES
                </button>
                <button
                  className="close-btn"
                  onClick={() => toggleUserDelete()}
                  id="cancel"
                >
                  CANCEL
                </button>
              </div>
              {errorMessage}
            </div>
          </div>
        </div>
      )}
      {showDeleteSuccess && (
        <DeleteSuccessful closeModal={closeModal} user={user} />
      )}
    </>
  );
};

const DeleteSuccessful = ({ user, closeModal }) => {
  return (
    <>
      <div className="popup">
        <div className="popup-inner">
          <div className="circle-box">
            <IoCheckmarkCircleOutline className="checkicon" />
            <h3>SUCCESS!</h3>
          </div>
          <div className="popup-txt-contr">
            <div className="txt-cont">
              <p className="bold-text">User Deleted Successfully</p>
              <p>
                You have successfully deleted "
                {user.firstName + " " + user.lastName}".
              </p>
            </div>
            <div className="btn-cont">
              <button
                className="close-btn"
                onClick={() => {
                  closeModal();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalUserDelete;
