import React, { useState, useContext } from "react";
import { AppContext } from "../../../App";
import { FaUserAlt, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router";
import "./loginform.css";
import axios from "axios";
import PopUp from "../PopUp";
import { getBaseUrl } from "../../../baseURL";
// console.log(getBaseUrl()());

const LoginForm = () => {
  const { appState, setAppState } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();
  // State to store the form input values
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Event handler for form input changes
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  // Event handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const response = await axios.post(
        `${getBaseUrl()}/api/user/login`,
        formData
      );

      if (response.data.success) {
        setAppState({
          role: response.data.filteredUser.role,
          currentRole: response.data.filteredUser.role,
          firstName: response.data.filteredUser.firstName,
          lastName: response.data.filteredUser.lastName,
          jobLevel: response.data.filteredUser.jobLevel,
          id: response.data.filteredUser.id,
          email: response.data.filteredUser.email,
        });
        localStorage.setItem("token", response.data.token);
      } else {
        setErrorMessage("Invalid username or password.");
      }
    } catch (err) {
      setErrorMessage(
        <p className="error-message">{err.response.data.message}</p>
      );
    }
  };

  const handleSignUp = () => {
    navigate("/signup");
  };
  const handleForgot = () => {
    navigate("/forgot");
  };

  return (
    <div className="login-main-container">
      <form onSubmit={handleSubmit} className="form-container">
        <img src="LEAP-LOGIN.png" alt="LEAP LOGO" id="leap-logo" />

        <div className="login-container">
          <h2 className="login-header">Login to ENROLL!</h2>

          <div className="login-field">
            <div className="input-container">
              <FaUserAlt className="icon" />
              <input
                type="email"
                name="email"
                value={formData.email}
                placeholder="email address"
                onChange={handleChange}
                className="input-field"
              />
            </div>

            <div className="input-container">
              <FaLock className="icon" />
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                placeholder="password"
                onChange={handleChange}
                className="input-field"
              />
              {showPassword ? (
                <FaEyeSlash
                  className="password-show"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <FaEye
                  className="password-hide"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
          </div>

          <div className="error-forgot">
            <div>
              {errorMessage && (
                <p className="error-message" id="start-position">
                  {errorMessage}
                </p>
              )}
            </div>
            <p id="click-here" onClick={handleForgot}>
              Forgot Password?
            </p>
          </div>

          <button className="login-button" type="submit">
            Login
          </button>
          <br />
          <div style={{ textAlign: "center" }}>
            <span id="account-text"> Don't have an account yet? </span>
            <span id="click-here" onClick={handleSignUp}>
              {" "}
              Sign up{" "}
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
