import React, { useState, useContext } from "react";
import "../css/admin-add-user.css";
import axios from "axios";
import { AppContext } from "../../App";

import PopUp from "../../SpecialPages/components/PopUp";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { getBaseUrl } from "../../baseURL";

const AdminAddUser = () => {
  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [role, setRole] = useState("");
  const [jobLevel, setJobLevel] = useState("");
  const [birthday, setBirthday] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { config } = useContext(AppContext);
  const [successMessage, setSuccessMessage] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    const formData = {
      email,
      password,
      username,
      firstName,
      lastName,
      phoneNumber,
      gender,
      role,
      jobLevel,
      birthday,
    };
    try {
      await axios
        .post(`${getBaseUrl()}/api/user/admincreate`, formData, config)
        .then((res) => {
          if (res.status === 201) {
            setSuccessMessage(res.data.message);
            setButtonPopUp(true);
            setEmail("");
            setPassword("");
            setUsername("");
            setFirstName("");
            setLastName("");
            setPhoneNumber("");
            setGender("");
            setRole("");
            setJobLevel("");
            setBirthday("");
          }
        });
    } catch (err) {
      setErrorMessage(err.response.data);
    }
  };

  const AdminAddUserPop = () => {
    return (
      <>
        <div className="circle-box">
          <IoCheckmarkCircleOutline className="checkicon" />
          <h3>SUCCESS!</h3>
        </div>
        <div className="popup-txt-contr">
          <p className="bold-text">User Added Successfully</p>
          <p>
            The User has been successfully created. Please check the email for
            verification link.
          </p>
        </div>
      </>
    );
  };
  return (
    <div className="add-card">
      <div className="title-container">
        <p>ADD USER</p>
      </div>
      <form onSubmit={handleSubmit} className="info-wrapper">
        <div className="personal-info">
          <p> First name:</p>
          <div className="input-container">
            <input
              required
              type="text"
              value={firstName}
              placeholder="e.g John"
              onChange={(e) => {
                // Remove non-letter and non-space characters
                const newValue = e.target.value.replace(/[^a-zA-Z\s]+/g, "");
                // Capitalize the first letter of each word
                const capitalizedValue = newValue
                  .split(" ")
                  .map((word) => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  })
                  .join(" ");
                // Update the state
                setFirstName(capitalizedValue);
              }}
            />
          </div>
        </div>

        <div className="personal-info">
          <p> Last name:</p>
          <div className="input-container">
            <input
              required
              type="text"
              value={lastName}
              placeholder="e.g Dela Cruz"
              onChange={(e) => {
                // Remove non-letter and non-space characters
                const newValue = e.target.value.replace(/[^a-zA-Z\s]+/g, "");
                // Capitalize the first letter of each word
                const capitalizedValue = newValue
                  .split(" ")
                  .map((word) => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  })
                  .join(" ");
                // Update the state
                setLastName(capitalizedValue);
              }}
            />
          </div>
        </div>
        <div className="personal-info">
          <p> Phone number:</p>
          <div className="input-container">
            <input
              required
              type="tel"
              pattern="[0-9]{11}"
              value={phoneNumber}
              maxLength={11}
              placeholder="e.g 09123456789"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="personal-info">
          <p> Gender:</p>
          <div className="input-container">
            <select
              required
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="" hidden>
                Select gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="personal-info">
          <p>Birthday:</p>
          <div className="input-container">
            <input
              required
              type="date"
              value={birthday}
              placeholder="birthday"
              min="1900-01-01"
              max={new Date().toISOString().split("T")[0]}
              onChange={(e) => setBirthday(e.target.value)}
              className="input-field"
              id="birthday"
            />
          </div>
        </div>

        <div className="personal-info">
          <p> Job Level:</p>
          <div className="input-container">
            <select
              required
              value={jobLevel}
              onChange={(e) => setJobLevel(e.target.value)}
            >
              <option value="" hidden>
                Select job level
              </option>
              <option value="Admin Assistant">Admin Assistant</option>
              <option value="Accounts Manager">Accounts Manager</option>
              <option value="Ambassador">Ambassador</option>
              <option value="Executive Assistant">Executive Assistant</option>
              <option value="Quality Assurance">Quality Assurance</option>
              <option value="SME">SME</option>
              <option value="Administrator">Administrator</option>
              <option value="Analyst">Analyst</option>
              <option value="Coordinator">Coordinator</option>
              <option value="Director">Director</option>
              <option value="Lead Recruiter">Lead Recruiter</option>
              <option value="Manager">Manager</option>
              <option value="Senior Manager">Senior Manager</option>
              <option value="Supervisory">Supervisory</option>
              <option value="Trainer">Trainer</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        <hr></hr>

        <div className="personal-info">
          <p> Username:</p>
          <div className="input-container">
            <input
              required
              type="text"
              value={username}
              placeholder="e.g jdecruz"
              onChange={(e) => {
                // Remove non-letter and non-number characters from the input value
                const newValue = e.target.value.replace(/[^a-zA-Z0-9]+/g, "");
                // Update the state
                setUsername(newValue);
              }}
            />
          </div>
        </div>

        <div className="personal-info">
          <p> Role:</p>
          <div className="input-container">
            <select
              required
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="" hidden>
                Assign role
              </option>
              <option value="admin">Admin</option>
              <option value="instructor">Instructor</option>
              <option value="learner">Learner</option>
            </select>
          </div>
        </div>
        <div className="personal-info">
          <p> Email:</p>
          <div className="input-container">
            <input
              required
              type="email"
              value={email}
              placeholder="e.g john.delacruz@supportzebra.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="personal-info">
          <p> Password:</p>
          <div className="input-container">
            <input
              required
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {showPassword ? (
              <FaEyeSlash
                className="password-show"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <FaEye
                className="password-hide"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
        </div>

        <hr></hr>

        {errorMessage && (
          <p className="error-message" id="start-position">
            {errorMessage}
          </p>
        )}

        <div className="btn-container">
          <button className="adminInfo-upd-btn" type="submit">
            SUBMIT
          </button>
        </div>
      </form>
      <PopUp
        trigger={buttonPopUp}
        setTrigger={setButtonPopUp}
        popUp={AdminAddUserPop}
      />
    </div>
  );
};

export default AdminAddUser;
