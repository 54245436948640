import React, { useState } from "react";
import ClassCatalogueModal from "./ClassCatalogueModal";

// import "../css/class-catalogue-card.css";


const ClassCatalogueCard = ({ classInfo }) => {
    //USE STATE
    const [classInfoModal, setClassInfoModal] = useState(false);

    //VARIABLE
    const { _id, title, description, category, createdAt } = classInfo;
    const created = new Date(createdAt);

    const toggleClassInfo = () => {
        setTimeout(() => {
            setClassInfoModal(!classInfoModal);
        }, 100);
    };

    if (classInfoModal) {
        document.body.classList.add("overlay");
    } else {
        document.body.classList.remove("overlay");
    }

    return (
        <div className="view-class" key={_id} onClick={() => toggleClassInfo()}>
            <div className="main-info">
                <div>
                    <p className="class-title">{title}</p>
                    <p className="class-category">{category}</p>
                </div>
                <p className="class-description"><p>{description}</p></p>
            </div>
            <div className="sub-info">
                <hr className="viewclass-hr"></hr>

                {/* <p className="class-instructor">{instructor.map((inst) => " " + inst.firstName + " " + inst.lastName).join(", ")}</p> */}
                <div className="created-time-date">
                    <p className="date">Created {created.toLocaleDateString([], { year: "numeric", month: "short", day: "numeric" })}</p>
                    <p className="time">
                        &nbsp;&nbsp;{created.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true })}
                    </p>
                </div>
            </div>

            {classInfoModal ? <ClassCatalogueModal toggleClassInfo={toggleClassInfo} _id={_id} classInfo={classInfo} /> : null}
        </div>
    );
};

export default ClassCatalogueCard;
