import React, { useContext } from "react";

import LoginForm from "./Auth/LoginForm";
import "../css/App.css";
import { AppContext } from "../../App";
import AdminDashboard from "../../Admin/components/AdminDashboard";
import InstructorDashboard from "../../Instructor/components/InstructorDashboard";
import LearnerDashboard from "../../Learner/components/LearnerDashboard";

const Body = () => {
    const { appState } = useContext(AppContext);

    return (
        <div className="body-container">
            {/* {appState.currentRole === null ? <VerifiedEmail /> : null} */}
            {appState.currentRole === null ? <LoginForm /> : null}
            {appState.currentRole === "admin" ? <AdminDashboard /> : null}
            {appState.currentRole === "instructor" ? <InstructorDashboard /> : null}
            {appState.currentRole === "learner" ? <LearnerDashboard /> : null}
        </div>
    );
};

export default Body;
