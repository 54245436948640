import React, { useContext, useEffect } from "react";

import { AppContext } from "../../App";
import ClassCatalogue from "./ClassCatalogue";
import LearnerClasses from "./LearnerClasses";
import LearnerOverview from "./LearnerOverview";
import LearnerProfile from "./LearnerProfile";
import LearnerUpdatePassword from "./LearnerUpdatePassword"

const LearnerDashboard = () => {
    const { learnerDisplay, setLearnerDisplay } = useContext(AppContext);

    useEffect(() => (
        setLearnerDisplay("LearnerOverview")
    ), [])

    return (
        <div className="learner-page">
            {learnerDisplay === "LearnerOverview" ? <LearnerOverview /> : null}
            {learnerDisplay === "LearnerClasses" ? <LearnerClasses /> : null}
            {learnerDisplay === "ClassCatalogue" ? <ClassCatalogue /> : null}
            {learnerDisplay === "LearnerProfile" ? <LearnerProfile /> : null}
            {learnerDisplay === "LearnerUpdatePassword" ? <LearnerUpdatePassword /> : null}
        </div>
    );
};

export default LearnerDashboard;
