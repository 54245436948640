import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";
import "../../SpecialPages/css/profile.css";
import PopUp from "../../SpecialPages/components/PopUp";
import { AppContext } from "../../App";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { getBaseUrl } from "../../baseURL";

const InstructorProfile = () => {
    const { setInstructorDisplay } = useContext(AppContext);
    const { appState, config } = useContext(AppContext);

    const [buttonPopUp, setButtonPopUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [user, setUser] = useState({});

    const [formData, setFormData] = useState({
        email: "",
        jobLevel: "",
        username: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        birthday: "",
        gender: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${getBaseUrl()}/api/user/getUser`,
                    config
                );
                const userData = response.data;
                setUser(userData);
                setFormData({
                    email: userData.email,
                    jobLevel: userData.jobLevel,
                    username: userData.username,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    phoneNumber: userData.phoneNumber || "",
                    birthday: userData.birthday
                        ? new Date(userData.birthday)
                              .toISOString()
                              .split("T")[0]
                        : "",
                    gender: userData.gender,
                });
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [config]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        try {
            const updatedFormData = { ...user, ...formData };
            const result = await axios.put(
                `${getBaseUrl()}/api/user`,
                updatedFormData,
                config
            );
            if (result.status === 201) {
                setUser(result.data);
                setButtonPopUp(true);
            }
        } catch (error) {
            setErrorMessage(error.response.data);
        }
    };

    const renderInput = (label, name, type = "text", additionalProps = {}) => (
        <div className="key-value">
            <p>{label}</p>
            <div className="input-container">
                <input
                    type={type}
                    name={name}
                    value={formData[name] || ""}
                    placeholder={user[name] || ""}
                    onChange={handleChange}
                    className="input-field"
                    {...additionalProps}
                />
            </div>
        </div>
    );

    const EditProfilePopUp = () => (
        <>
            <div className="circle-box">
                <IoCheckmarkCircleOutline className="checkicon" />
                <h3>SUCCESS!</h3>
            </div>
            <div className="popup-txt-contr">
                <div className="txt-cont">
                    <p className="bold-txt">Update Profile Successfully</p>
                    <p>You have successfully updated your profile.</p>
                </div>
            </div>
        </>
    );

    return (
        <div className="profile-page">
            <div className="profile-card">
                <div className="instructortitle-container">
                    <div className="title-boxA">
                        <div className="option-box">
                            <FaArrowLeft
                                className="back-icon"
                                onClick={() =>
                                    setInstructorDisplay("InstructorOverview")
                                }
                            />
                            <p>UPDATE PROFILE</p>
                        </div>
                    </div>
                    <div className="update-profile-container">
                        <button
                            className="profile-update-btn"
                            onClick={() =>
                                setInstructorDisplay("InstructorUpdatePassword")
                            }
                        >
                            UPDATE PASSWORD
                        </button>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="info-container">
                    {renderInput("First Name", "firstName", "text", {
                        pattern: "[a-zA-Zs]*",
                        onInput: (e) => {
                            e.target.value = e.target.value.replace(
                                /[^a-zA-Z\s]/g,
                                ""
                            );
                        },
                    })}
                    {renderInput("Last Name", "lastName", "text", {
                        pattern: "[a-zA-Zs]*",
                        onInput: (e) => {
                            e.target.value = e.target.value.replace(
                                /[^a-zA-Z\s]/g,
                                ""
                            );
                        },
                    })}
                    {renderInput("Phone Number", "phoneNumber", "tel", {
                        pattern: "[0]{1}[9]{1}[0-9]{9}",
                        maxLength: 11,
                    })}
                    <div className="key-value">
                        <p>Gender</p>
                        <div className="input-container">
                            <select
                                name="gender"
                                value={formData.gender || ""}
                                onChange={handleChange}
                            >
                                <option value="" hidden>
                                    {user.gender}
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                    {renderInput("Birthday", "birthday", "date", {
                        min: "1900-01-01",
                        max: new Date().toISOString().split("T")[0],
                    })}
                    <div className="key-value">
                        <p>Position</p>
                        <div className="input-container">
                            <select
                                disabled
                                required
                                name="jobLevel"
                                value={formData.jobLevel || ""}
                                onChange={handleChange}
                                className="disable-click"
                            >
                                <option value="" hidden>
                                    {user.jobLevel}
                                </option>
                                <option value="" disabled>
                                    Leadership Roles:
                                </option>
                                {/* Add the rest of your options here */}
                            </select>
                        </div>
                    </div>
                    <hr />
                    {renderInput("Username", "username")}
                    <hr />
                    <div className="btn-container">
                        <button
                            className="instructorInfo-upd-btn"
                            type="submit"
                        >
                            UPDATE
                        </button>
                        <PopUp
                            trigger={buttonPopUp}
                            setTrigger={setButtonPopUp}
                            popUp={EditProfilePopUp}
                        />
                        {errorMessage && (
                            <p className="error-message">{errorMessage}</p>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InstructorProfile;
