import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import Dropdown from "./Dropdown";

import axios from "axios";

import { FaWindowClose, FaHome } from "react-icons/fa";
import {
    MdPerson,
    MdPeople,
    MdOutlineFileDownload,
    MdLogout,
    MdListAlt,
} from "react-icons/md";
import { TfiMenu } from "react-icons/tfi";
import { TbBooks } from "react-icons/tb";
import { IoBookOutline } from "react-icons/io5";
import { RxDividerVertical } from "react-icons/rx";
import { BiChevronRight, BiChevronDown, BiSolidBookAdd } from "react-icons/bi";
import "../css/navbar.css";
import ModalExport from "./ModalExport";
import { getBaseUrl } from "../../baseURL";

const useToggle = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const toggle = () => setValue(!value);
    return [value, toggle];
};

const Navbar = () => {
    const { appState, setAppState, config } = useContext(AppContext);
    const [showMenu, setShowMenu] = useState(false);
    const { setDisplay } = useContext(AppContext);
    const { setInstructorDisplay } = useContext(AppContext);
    const { setLearnerDisplay } = useContext(AppContext);

    useEffect(() => {
        return () => {
            setShowMenu(false);
        };
    }, []);

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const response = await axios.get(
                `${getBaseUrl()}/api/user/logout`,
                config
            );
            if (response.data.success) {
                setAppState({
                    role: null,
                    currentRole: null,
                });
                localStorage.removeItem("token");
                navigate("/");
            }
        } catch (err) {
            // setErrorMessage("An error occurred while trying to log out.");
            console.log(err);
        }
    };

    return (
        <nav>
            {appState.currentRole === null && (
                <img src="LEAP.png" alt="LEAP-logo" />
            )}
            {appState.currentRole !== null ? (
                <>
                    <div className="responsive-menu">
                        <TfiMenu
                            className="hamburger"
                            onClick={() => setShowMenu(!showMenu)}
                        />
                        {showMenu && (
                            <div
                                id="sideNav"
                                className="responsive-menu-content"
                            >
                                <SideBar
                                    showMenu={showMenu}
                                    setShowMenu={setShowMenu}
                                    handleLogout={handleLogout}
                                    setDisplay={setDisplay}
                                />
                            </div>
                        )}
                    </div>
                </>
            ) : null}

            {appState.currentRole === "admin" && (
                <img
                    src="LEAP.png"
                    alt="LEAP-logo"
                    onClick={() => {
                        setDisplay("AdminOverview");
                    }}
                />
            )}

            {appState.currentRole === "instructor" && (
                <img
                    src="LEAP.png"
                    alt="LEAP-logo"
                    onClick={() => {
                        setInstructorDisplay("InstructorOverview");
                    }}
                />
            )}
            {appState.currentRole === "learner" && (
                <img
                    src="LEAP.png"
                    alt="LEAP-logo"
                    onClick={() => {
                        setLearnerDisplay("LearnerOverview");
                    }}
                />
            )}

            {appState.currentRole !== null && (
                <div className="user-nav">
                    {appState.currentRole === "instructor" && (
                        <>
                            <Link
                                className="profile-class-link navenroll-btn instructor-color"
                                onClick={() => {
                                    setInstructorDisplay("InstructorAddClass");
                                }}
                            >
                                {/* <RxPlus className="navbtn-icon" /> */}
                                ADD COURSE
                            </Link>
                            <Link
                                className="profile-class-link"
                                onClick={() => {
                                    setInstructorDisplay(
                                        "InstructorViewClasses"
                                    );
                                }}
                            >
                                COURSES
                            </Link>

                            <Link
                                className="profile-class-link"
                                onClick={() => {
                                    setInstructorDisplay("InstructorProfile");
                                }}
                            >
                                PROFILE
                            </Link>

                            {/* <div className="navdivider">
                                <RxDividerVertical className="nav-icon" />
                            </div> */}
                        </>
                    )}
                    {appState.currentRole === "learner" && (
                        <>
                            <Link
                                className="profile-class-link navenroll-btn"
                                onClick={() => {
                                    setLearnerDisplay("ClassCatalogue");
                                }}
                            >
                                ENROLL
                            </Link>
                            <Link
                                className="profile-class-link"
                                onClick={() => {
                                    setLearnerDisplay("LearnerClasses");
                                }}
                            >
                                MY COURSES
                            </Link>
                            <Link
                                className="profile-class-link"
                                onClick={() => {
                                    setLearnerDisplay("LearnerProfile");
                                }}
                            >
                                PROFILE
                            </Link>
                        </>
                    )}
                    <Dropdown role="admin" />

                    <div className="navdivider">
                        <RxDividerVertical className="nav-icon" />
                    </div>

                    <Link className="profile-class-link" onClick={handleLogout}>
                        LOGOUT
                    </Link>
                </div>
            )}
        </nav>
    );
};

const SideBar = ({ showMenu, setShowMenu, handleLogout }) => {
    const { appState, setAppState } = useContext(AppContext);
    const { display, setDisplay } = useContext(AppContext);
    const { instructorDisplay, setInstructorDisplay } = useContext(AppContext);
    const { learnerDisplay, setLearnerDisplay } = useContext(AppContext);
    const [selectedRole, setSelectedRole] = useState(appState.currentRole);
    const [showRoleLinks, setShowRoleLinks] = useToggle(false);
    const [showProfileLinks, setShowProfileLinks] = useToggle(false);
    const handleChange = (value) => {
        setAppState({ ...appState, currentRole: value });
        setSelectedRole(value);
    };

    return (
        <div className={`sidebar-container ${showMenu ? "show" : ""}`}>
            <div className="close-icon">
                <FaWindowClose
                    className="icons"
                    onClick={() => setShowMenu(false)}
                />
            </div>

            <div className="sidebar-wrapper">
                <div
                    className={`role-wrapper ${
                        display === "AdminOverview" ||
                        display === "InstructorOverview"
                            ? "sidebar-selected"
                            : ""
                    }`}
                >
                    <div className="link-container" onClick={setShowRoleLinks}>
                        <FaHome className="icons" />
                        <p className="profile-class-link">
                            HOME
                            {showRoleLinks ? (
                                <BiChevronDown className="toggle-down" />
                            ) : (
                                <BiChevronRight className="toggle-up" />
                            )}
                        </p>
                    </div>

                    {showRoleLinks && (
                        <>
                            {appState.role === "admin" ? (
                                <div className="sidebar-role">
                                    <Link
                                        className={`role-link ${
                                            appState.currentRole === "admin"
                                                ? "selected-small-links"
                                                : ""
                                        }`}
                                        onClick={() => handleChange("admin")}
                                    >
                                        Admin
                                    </Link>
                                    <Link
                                        className={`role-link ${
                                            appState.currentRole ===
                                            "instructor"
                                                ? "selected-small-links"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            handleChange("instructor")
                                        }
                                    >
                                        Instructor
                                    </Link>
                                    <Link
                                        className={`role-link ${
                                            appState.currentRole === "learner"
                                                ? "selected-small-links"
                                                : ""
                                        }`}
                                        onClick={() => handleChange("learner")}
                                    >
                                        Learner
                                    </Link>
                                </div>
                            ) : null}

                            {appState.role === "instructor" &&
                            selectedRole !== "admin" ? (
                                <div className="sidebar-role">
                                    <Link
                                        className={`role-link ${
                                            appState.currentRole ===
                                            "instructor"
                                                ? "selected-small-links"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            handleChange("instructor")
                                        }
                                    >
                                        Instructor
                                    </Link>
                                    <Link
                                        className={`role-link ${
                                            appState.currentRole === "learner"
                                                ? "selected-small-links"
                                                : ""
                                        }`}
                                        onClick={() => handleChange("learner")}
                                    >
                                        Learner
                                    </Link>
                                </div>
                            ) : null}

                            {appState.role === "learner" &&
                            selectedRole !== "admin" ? (
                                <div className="sidebar-role">
                                    <Link
                                        className={`role-link ${
                                            appState.currentRole === "learner"
                                                ? "selected-small-links"
                                                : ""
                                        }`}
                                        onClick={() => handleChange("learner")}
                                    >
                                        Learner
                                    </Link>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>

                {selectedRole === "instructor" ? (
                    <>
                        <div
                            className={`role-wrapper ${
                                instructorDisplay === "InstructorViewClasses"
                                    ? "sidebar-selected"
                                    : ""
                            }`}
                        >
                            <div
                                className="link-container"
                                onClick={() => {
                                    setInstructorDisplay(
                                        "InstructorViewClasses"
                                    );
                                }}
                            >
                                <TbBooks className="icons" />
                                <p className="profile-class-link">COURSES</p>
                            </div>
                        </div>
                        <div
                            className={`role-wrapper ${
                                instructorDisplay === "InstructorViewClasses"
                                    ? "sidebar-selected"
                                    : ""
                            }`}
                        >
                            <div
                                className="link-container"
                                onClick={() => {
                                    setInstructorDisplay("InstructorAddClass");
                                }}
                            >
                                <BiSolidBookAdd className="icons" />
                                <p className="profile-class-link">
                                    ADD COURSES
                                </p>
                            </div>
                        </div>
                    </>
                ) : null}

                {selectedRole === "learner" ? (
                    <>
                        <div
                            className={`role-wrapper ${
                                learnerDisplay === "LearnerClasses"
                                    ? "sidebar-selected"
                                    : ""
                            }`}
                        >
                            <div
                                className="link-container"
                                onClick={() => {
                                    setLearnerDisplay("LearnerClasses");
                                }}
                            >
                                <TbBooks className="icons" />
                                <p className="profile-class-link">COURSES</p>
                            </div>
                        </div>
                        <div
                            className={`role-wrapper ${
                                learnerDisplay === "LearnerClasses"
                                    ? "sidebar-selected"
                                    : ""
                            }`}
                        >
                            <div
                                className="link-container"
                                onClick={() => {
                                    setLearnerDisplay("ClassCatalogue");
                                }}
                            >
                                <IoBookOutline className="icons" />
                                <p className="profile-class-link">ENROLL</p>
                            </div>
                        </div>
                    </>
                ) : null}

                <div
                    className={`role-wrapper ${
                        display === "AdminUpdateProfile" ||
                        display === "AdminUpdatePassword"
                            ? "sidebar-selected"
                            : ""
                    }`}
                >
                    <div
                        className="link-container"
                        onClick={setShowProfileLinks}
                    >
                        <MdPerson className="icons" />
                        <p className="profile-class-link">
                            PROFILE
                            {showProfileLinks ? (
                                <BiChevronDown className="toggle-down" />
                            ) : (
                                <BiChevronRight className="toggle-up" />
                            )}
                        </p>
                    </div>

                    {showProfileLinks && (
                        <>
                            {selectedRole === "admin" ? (
                                <div className="sidebar-role">
                                    <Link
                                        className={`role-link ${
                                            display === "AdminUpdateProfile"
                                                ? "selected-small-links"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setDisplay("AdminUpdateProfile")
                                        }
                                    >
                                        Edit Profile
                                    </Link>
                                    <Link
                                        className={`role-link ${
                                            display === "AdminUpdatePassword"
                                                ? "selected-small-links"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setDisplay("AdminUpdatePassword")
                                        }
                                    >
                                        Change Password
                                    </Link>
                                </div>
                            ) : null}

                            {selectedRole === "instructor" ? (
                                <div className="sidebar-role">
                                    <Link
                                        className="role-link"
                                        onClick={() =>
                                            setInstructorDisplay(
                                                "InstructorProfile"
                                            )
                                        }
                                    >
                                        Edit Profile
                                    </Link>
                                    <Link
                                        className="role-link"
                                        onClick={() =>
                                            setInstructorDisplay(
                                                "InstructorUpdatePassword"
                                            )
                                        }
                                    >
                                        Change Password
                                    </Link>
                                </div>
                            ) : null}

                            {selectedRole === "learner" ? (
                                <div className="sidebar-role">
                                    <Link
                                        className="role-link"
                                        onClick={() =>
                                            setLearnerDisplay("LearnerProfile")
                                        }
                                    >
                                        Edit Profile
                                    </Link>
                                    <Link
                                        className="role-link"
                                        onClick={() =>
                                            setLearnerDisplay(
                                                "LearnerUpdatePassword"
                                            )
                                        }
                                    >
                                        Change Password
                                    </Link>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>

                <div className="role-wrapper">
                    <div className="link-container">
                        <MdLogout className="icons" />
                        <p
                            className="profile-class-link"
                            onClick={handleLogout}
                        >
                            LOGOUT
                        </p>
                    </div>
                </div>
            </div>

            <hr />

            {selectedRole === "admin" && (
                <div className="admin-sidebar">
                    <AdminSideBar setDisplay={setDisplay} />
                </div>
            )}
        </div>
    );
};

const AdminSideBar = () => {
    const { display, setDisplay } = useContext(AppContext);
    const [showUsersLinks, setShowUsersLinks] = useToggle(false);
    const [showClassesLinks, setShowClassesLinks] = useToggle(false);
    const [showExportLink, setShowExportLink] = useToggle(false);
    const [allExport, setAllExport] = useState(false);
    const toggleModalExport = () => {
        setAllExport(!allExport);
    };

    return (
        <div className="admin-sidebar">
            <div className="sidebar-wrapper">
                <div
                    className={`role-wrapper ${
                        display === "AdminUsers" || display === "AdminAddUser"
                            ? "sidebar-selected"
                            : ""
                    }`}
                >
                    <div className="link-container" onClick={setShowUsersLinks}>
                        <MdPeople className="icons" />
                        <p className="profile-class-link" id="anti-hover">
                            {" "}
                            USERS
                            {showUsersLinks ? (
                                <BiChevronDown className="toggle-down" />
                            ) : (
                                <BiChevronRight className="toggle-up" />
                            )}
                        </p>
                    </div>

                    {showUsersLinks && (
                        <div className="sidebar-role">
                            <Link
                                className={`role-link ${
                                    display === "AdminUsers"
                                        ? "selected-small-links"
                                        : ""
                                }`}
                                onClick={() => setDisplay("AdminUsers")}
                            >
                                View Users
                            </Link>

                            <Link
                                className={`role-link ${
                                    display === "AdminAddUser"
                                        ? "selected-small-links"
                                        : ""
                                }`}
                                onClick={() => setDisplay("AdminAddUser")}
                            >
                                Add User
                            </Link>
                        </div>
                    )}
                </div>

                <div
                    className={`role-wrapper ${
                        display === "AdminViewClass" ||
                        display === "AdminAddClass"
                            ? "sidebar-selected"
                            : ""
                    }`}
                >
                    <div
                        className="link-container"
                        onClick={setShowClassesLinks}
                    >
                        <TbBooks className="icons" />
                        <p className="profile-class-link" id="anti-hover">
                            {" "}
                            COURSES
                            {showClassesLinks ? (
                                <BiChevronDown className="toggle-down" />
                            ) : (
                                <BiChevronRight className="toggle-up" />
                            )}
                        </p>
                    </div>

                    {showClassesLinks && (
                        <div className="sidebar-role">
                            <Link
                                className={`role-link ${
                                    display === "AdminViewClass"
                                        ? "selected-small-links"
                                        : ""
                                }`}
                                onClick={() => setDisplay("AdminViewClass")}
                            >
                                View Courses
                            </Link>

                            <Link
                                className={`role-link ${
                                    display === "AdminAddClass"
                                        ? "selected-small-links"
                                        : ""
                                }`}
                                onClick={() => setDisplay("AdminAddClass")}
                            >
                                Add Course
                            </Link>
                        </div>
                    )}
                </div>

                <div className="role-wrapper">
                    <div className="link-container" onClick={setShowExportLink}>
                        <MdOutlineFileDownload className="icons" />
                        <p className="profile-class-link" id="anti-hover">
                            {" "}
                            EXPORT
                            {showExportLink ? (
                                <BiChevronDown className="toggle-down" />
                            ) : (
                                <BiChevronRight className="toggle-up" />
                            )}
                        </p>
                    </div>

                    {showExportLink && (
                        <div className="sidebar-role">
                            <Link
                                className="role-link"
                                onClick={() => toggleModalExport()}
                            >
                                {allExport ? (
                                    <ModalExport
                                        toggleModalExport={toggleModalExport}
                                        isModalClassInfo={false}
                                        exportWhat={"all-data"}
                                    />
                                ) : null}
                                Export to Excel
                            </Link>
                        </div>
                    )}
                </div>

                <div
                    className={`role-wrapper ${
                        display === "AdminActivity" ? "sidebar-selected" : ""
                    }`}
                >
                    <div className="link-container">
                        <MdListAlt className="icons" />
                        <p
                            className="profile-class-link"
                            onClick={() => setDisplay("AdminActivity")}
                        >
                            ACTIVITY LOGS
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
