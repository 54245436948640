import React, { useContext, useEffect } from "react";
import "../css/instructor-dashboard.css";
import { FaArrowLeft } from "react-icons/fa";

import { AppContext } from "../../App";
import InstructorOverview from "./InstructorOverview";
import InstructorClasses from "./InstructorClasses";
import InstructorProfile from "./InstructorProfile";
import InstructorUpdatePassword from "./InstructorUpdatePassword";
import AddClassCard from "../../Admin/components/AddClassCard";

const InstructorDashboard = () => {
    const { instructorDisplay, setInstructorDisplay } = useContext(AppContext);

    useEffect(() => setInstructorDisplay("InstructorOverview"), []);

    return (
        <div className="instructor-page">
            {instructorDisplay === "InstructorOverview" ? <InstructorOverview /> : null}
            {instructorDisplay === "InstructorAddClass" ? (
                <>
                    <div className="classes-wrapper">
                        <div className="title-container" id="classes-instructor">
                            <div className="option-box">
                                {/* <FaArrowLeft id="back-icon" onClick={() => setInstructorDisplay("InstructorViewClasses")} /> */}
                                <p>ADD COURSE</p>
                            </div>
                        </div>
                        <AddClassCard />
                    </div>
                </>
            ) : null}
            {instructorDisplay === "InstructorViewClasses" ? <InstructorClasses /> : null}
            {instructorDisplay === "InstructorProfile" ? <InstructorProfile /> : null}
            {instructorDisplay === "InstructorUpdatePassword" ? <InstructorUpdatePassword /> : null}
        </div>
    );
};

export default InstructorDashboard;
