import React, { useState } from "react";
import "../../Admin/css/admin-users.css";
// import { Link } from "react-router-dom";
import { FaChartPie, FaPen, FaTrash } from "react-icons/fa";
import ModalUserStatistics from "./ModalUserStatistics";
import ModalUserEdit from "./ModalUserEdit";
import ModalUserDelete from "./ModalUserDelete";

const CardUsers = ({ user, setUsers, setRerender, rerender, closeModal }) => {
    const [userStat, setUserStat] = useState(false);
    const [userEdit, setUserEdit] = useState(false);
    const [userDelete, setUserDelete] = useState(false);

    const date = new Date(user.createdAt);

    const toggleUserStat = () => {
        setTimeout(() => {
            setUserStat(!userStat);
        }, 100);
    };
    const toggleUserEdit = () => {
        setTimeout(() => {
            setUserEdit(!userEdit);
        }, 100);
    };
    const toggleUserDelete = () => {
        setTimeout(() => {
            setUserDelete(!userDelete);
        }, 100);
    };

    if (userStat) {
        document.body.classList.add("overlay");
    } else {
        document.body.classList.remove("overlay");
    }
    if (userEdit) {
        document.body.classList.add("overlay");
    } else {
        document.body.classList.remove("overlay");
    }
    return (
        <div className="users-row" key={user._id}>
            <div className="users-info" id="name-info">
                <p>{user.firstName + " " + user.lastName}</p>
            </div>
            <div className="users-info">
                <p>{user.email}</p>
            </div>
            <div className="users-info">
                <p>{user.role}</p>
            </div>
            <div className="users-info">
                <p>{date.toLocaleDateString()}</p>
            </div>
            <div className="users-info">
                <div className="options-icon">
                    <FaChartPie title="User Statistics" className="user-icon" onClick={() => toggleUserStat()} />
                    {userStat ? <ModalUserStatistics toggleUserStat={toggleUserStat} user={user} closeModal={closeModal} /> : null}
                    <FaPen title="Edit User" className="user-icon" id="center-icon" onClick={() => toggleUserEdit()} />
                    {userEdit ? <ModalUserEdit toggleUserEdit={toggleUserEdit} user={user} setUsers={setUsers} /> : null}
                    <FaTrash title="Delete User" className="user-icon" onClick={() => toggleUserDelete()} />
                    {userDelete ? (
                        <ModalUserDelete
                            toggleUserDelete={toggleUserDelete}
                            user={user}
                            setUsers={setUsers}
                            rerender={rerender}
                            setRerender={setRerender}
                            closeModal={closeModal}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default CardUsers;
