import React from "react";
import "../css/PopUp.css";
import { useNavigate } from "react-router-dom";

function PopUp(props) {
    const navigate = useNavigate();

    const handlesdashboard = () => {
        navigate("/");
    };
    return props.trigger ? (
        <div className="popup">
            <div className="popup-inner">
                {props.popUp()}

                <div className="btn-cont btn-contpopup">
                    <button className="close-btn close-btnpopup" onClick={() => props.setTrigger(false)}>
                        OK
                    </button>
                </div>
            </div>
        </div>
    ) : (
        ""
    );
}

export default PopUp;
