import React, { useState } from "react";
import "../css/add-class.css";

import AddClassCard from "./AddClassCard.js";

const AdminAddClass = () => {
    return (
        <div className="add-card">
            <div className="title-container">ADD COURSE</div>
            <AddClassCard />
        </div>
    );
};

export default AdminAddClass;
