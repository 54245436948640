import React, { useState } from "react";

import ModalClassPPF from "./ModalClassPPF";

//CSS
import "../css/classes-today-grid.css";

const ClassesTodayGrid = ({ sessionsToday }) => {
    //USE STATE
    const [classInfo, setClassInfo] = useState(false);

    //VAIRABLE
    const { _id, title, description, category } = sessionsToday.sessionClass;
    const { startTime, endTime } = sessionsToday;
    const startTimeConverted = new Date(startTime);
    const endTimeConverted = new Date(endTime);

    // APPEAR THE MODAL FOR THE CLASS INFO
    const toggleClassInfo = () => {
        setTimeout(() => {
            setClassInfo(!classInfo);
        }, 100);
    };

    if (classInfo) {
        document.body.classList.add("overlay");
    } else {
        document.body.classList.remove("overlay");
    }

    return (
        <div className="view-class" key={_id} onClick={toggleClassInfo}>
            <div className="main-info">
                <div>
                    <p className="class-title">{title}</p>
                    <p className="class-category">{category}</p>
                </div>
                <p className="class-description">{description}</p>
            </div>
            <div className="sub-info">
                <div className="session-time">
                    <div className="time-start">
                        <p className="time">
                            {startTimeConverted.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true })}
                        </p>
                        <p className="date">
                            {startTimeConverted.toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                            })}
                        </p>
                    </div>
                    <div className="time-end">
                        <p className="time">
                            {endTimeConverted.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true })}
                        </p>
                        <p className="date">
                            {endTimeConverted.toLocaleDateString([], {
                                month: "short",
                                day: "numeric",
                            })}
                        </p>
                    </div>
                </div>
            </div>
            {classInfo && <ModalClassPPF toggleClassInfo={toggleClassInfo} session={sessionsToday} key={_id} />}
        </div>
    );
};

export default ClassesTodayGrid;
