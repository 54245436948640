// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { AppContext } from "../../App";

// import "../css/learner-dashboard.css";

// // const UpcomingHistory = () => {
// //     const { config } = useContext(AppContext);
// //     let limit = 3;
// //     const roleColor = "learner";
// //     const [upcoming, setUpcoming] = useState([]);
// //     const [history, setHistory] = useState([]);
// //     const [pageCountUpcoming, setpageCountUpcoming] = useState(0);
// //     const [pageCountHistory, setpageCountHistory] = useState(0);
// //     const [display, setDisplay] = useState("upcoming");
// //     const instructorClasses = `${getBaseUrl()}/api/user/learner/sessions/history`;

// //     useEffect(() => {
// //         setTimeout(() => {
// //             axios
// //                 .get(instructorClasses, config)
// //                 .then((res) => {
// //                     const total = res.headers.get("X-Total-Count");
// //                     setpageCountUpcoming(Math.ceil(total / limit));

// //                     // setUpcoming(active);
// //                     // setIsLoading(false);
// //                 })
// //                 .catch((error) => {
// //                     console.log(error);
// //                 });
// //         }, 1000);
// //     }, [limit]);
// // };

// const UpcomingHistory = () => {
//     const roleColor = "learner";
//     const [upcoming, setUpcoming] = useState([]);
//     const [history, setHistory] = useState([]);
//     const [pageCountUpcoming, setpageCountUpcoming] = useState(0);
//     const [pageCountHistory, setpageCountHistory] = useState(0);
//     const { appState } = useContext(AppContext);

//     const [display, setDisplay] = useState("upcoming");

//     useEffect(() => {
//         setTimeout(() => {
//             axios
//                 .get(mockUpcoming)
//                 .then((res) => {
//                     const total = res.headers.get("X-Total-Count");
//                     setpageCountUpcoming(Math.ceil(total / limit));
//                     setUpcoming(res.data);
//                     // setIsLoading(false);
//                 // })
//                 .catch((error) => {
//                     console.log(error);
//                 });
//             axios
//                 .get(mockHistory)
//                 .then((res) => {
//                     const total = res.headers.get("X-Total-Count");
//                     setpageCountHistory(Math.ceil(total / limit));
//                     setHistory(res.data);
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 });
//         }, 1000);
//     }, [limit]);

//     const fetchUpcoming = async (currentPage) => {
//         const res = await axios.get(
//             `https://my-json-server.typicode.com/darylsimene/dummyclass/upcomingClasses?_page=${currentPage}&_limit=${limit}`
//         );
//         const data = res.data;
//         return data;
//     };

//     const fetchHistory = async (currentPage) => {
//         const res = await axios.get(
//             `${getBaseUrl()}/api/user/${appState.id}/learner/history`, {}, config
//         );
//         const data = res.data;
//         return data;
//     };

//     const fetchUpcomingFirstPage = async () => {
//         axios
//             .get(mockUpcoming)
//             .then((res) => {
//                 const total = res.headers.get("X-Total-Count");
//                 setpageCountUpcoming(Math.ceil(total / limit));
//                 setUpcoming(res.data);
//                 // setIsLoading(false);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     };
//     const fetchHistoryFirstPage = async () => {
//         axios
//             .get(mockHistory)
//             .then((res) => {
//                 const total = res.headers.get("X-Total-Count");
//                 setpageCountHistory(Math.ceil(total / limit));
//                 setHistory(res.data);
//                 // setIsLoading(false);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     };

//     const handlePageClickUpcoming = async (data) => {
//         let currentPage = data.selected + 1;
//         const upcomingData = await fetchUpcoming(currentPage);

//         setUpcoming(upcomingData);
//     };

//     const handlePageClickHistory = async (data) => {
//         let currentPage = data.selected + 1;
//         const historyData = await fetchHistory(currentPage);

//         setHistory(historyData);
//     };

//     function UpcomingClassFunction() {
//         return (
//             <div className="card-container" id="learner-upcoming-container">
//                 {upcoming.map((upcoming) => (
//                     <UpcomingHistoryCard
//                         // key={upcomingCard._id}
//                         upcomingHistoryCard={upcoming}
//                         roleColor={roleColor}
//                     />
//                 ))}
//             </div>
//         );
//     }

//     function HistoryClassFunction() {
//         return (
//             <div className="card-container" id="learner-history-container">
//                 {history.map((history) => (
//                     <UpcomingHistoryCard
//                         upcomingHistoryCard={history}
//                         roleColor={roleColor}
//                     />
//                 ))}
//             </div>
//         );
//     }

//     return (
//         <div className="upcoming-history-container">
//             <div className="title-container" id="learner-title">
//                 <p
//                     className={`upcoming-button ${
//                         display === "upcoming" ? "selected" : "unselect"
//                     }`}
//                     onClick={() => {
//                         fetchUpcomingFirstPage();
//                         setDisplay("upcoming");
//                     }}
//                 >
//                     Upcoming
//                 </p>
//                 <p
//                     className={`history-button ${
//                         display === "history" ? "selected" : "unselect"
//                     }`}
//                     onClick={() => {
//                         fetchHistoryFirstPage();
//                         setDisplay("history");
//                     }}
//                 >
//                     History
//                 </p>
//             </div>
//             {display === "upcoming" ? (
//                 <div className="card-wrapper">
//                     <UpcomingClassFunction />
//                     <div className="admin-pagination-box">
//                         <ReactPaginate
//                             previousLabel={"<<"}
//                             nextLabel={">>"}
//                             breakLabel={"..."}
//                             pageCount={pageCountUpcoming}
//                             marginPagesDisplayed={2}
//                             pageRangeDisplayed={3}
//                             onPageChange={handlePageClickUpcoming}
//                             containerClassName={"pagination"}
//                             pageClassName={"page-item"}
//                             pageLinkClassName={"page-link"}
//                             previousClassName={"page-item"}
//                             previousLinkClassName={"page-link"}
//                             nextClassName={"page-item"}
//                             nextLinkClassName={"page-link"}
//                             breakClassName={"page-item"}
//                             breakLinkClassName={"page-link"}
//                             activeClassName={"active"}
//                         />
//                     </div>
//                 </div>
//             ) : (
//                 <>
//                     <div className="card-wrapper">
//                         <HistoryClassFunction />
//                         <div className="admin-pagination-box">
//                             <ReactPaginate
//                                 previousLabel={"<<"}
//                                 nextLabel={">>"}
//                                 breakLabel={"..."}
//                                 pageCount={pageCountHistory}
//                                 marginPagesDisplayed={2}
//                                 pageRangeDisplayed={3}
//                                 onPageChange={handlePageClickHistory}
//                                 containerClassName={"pagination"}
//                                 pageClassName={"page-item"}
//                                 pageLinkClassName={"page-link"}
//                                 previousClassName={"page-item"}
//                                 previousLinkClassName={"page-link"}
//                                 nextClassName={"page-item"}
//                                 nextLinkClassName={"page-link"}
//                                 breakClassName={"page-item"}
//                                 breakLinkClassName={"page-link"}
//                                 activeClassName={"active"}
//                             />
//                         </div>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// };

// export default UpcomingHistory;
