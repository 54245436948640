import React, { useState } from "react";
import ModalSessionsInfo from "./ModalSessionsInfo";

import "../css/view-sessions.css";

const ViewSessions = ({ viewClassCard }) => {
    const { title, category, instructor } = viewClassCard.sessionClass;
    const { _id, startTime, endTime } = viewClassCard;
    const startDateConverted = new Date(startTime);
    const [classInfo, setClassInfo] = useState(false);
    const endDateConverted = new Date(endTime);

    const toggleSessionInfo = () => {
        setTimeout(() => {
            setClassInfo(!classInfo);
        }, 100);
    };
    if (classInfo) {
        document.body.classList.add("overlay");
    } else {
        document.body.classList.remove("overlay");
    }

    const timeStart = startDateConverted.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });
    const startDate = startDateConverted.toLocaleDateString([], {
        month: "short",
        day: "numeric",
        year: "numeric",
    });
    const timeEnd = endDateConverted.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });
    const endDate = endDateConverted.toLocaleDateString([], {
        month: "short",
        day: "numeric",
        year: "numeric",
    });

    return (
        <div className="sessions-grid" key={_id} onClick={toggleSessionInfo}>
            <div className="main-info">
                <div className="box-info">
                    <div>
                        <p className="class-title">{title}</p>
                        <p className="class-instructor">
                            {instructor.map((inst) => " " + inst.firstName + " " + inst.lastName).join(", ")}
                        </p>
                    </div>
                    <p className="class-category">{category}</p>
                </div>
                <div className="class-time-date">
                    <div className="class-time-date-start">
                        <p className="time">{timeStart}</p>
                        <p className="class-date">{startDate}</p>
                    </div>
                    <div className="class-time-date-end">
                        <p className="time"> {timeEnd}</p>
                        <p className="class-date">{endDate}</p>
                    </div>
                </div>
            </div>

            {classInfo && <ModalSessionsInfo toggleSessionInfo={toggleSessionInfo} viewClassCard={viewClassCard} key={viewClassCard._id} />}
        </div>
    );
};

export default ViewSessions;
