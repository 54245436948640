import React, { useState, useContext } from "react";
import { AppContext } from "../../App";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BsExclamationCircle } from "react-icons/bs";
import axios from "axios";
import { getBaseUrl } from "../../baseURL";

const ModalUnenroll = ({ viewClassCard, closeModal }) => {
    const { config } = useContext(AppContext);
    const [confirmed, setConfirmed] = useState(false);
    const [unenrollSuccessful, setUnenrollSuccessful] = useState(false);

    const handleDelete = async (e) => {
        setConfirmed(true);
        setUnenrollSuccessful(true);

        try {
            await axios.post(`${getBaseUrl()}/api/session/${viewClassCard._id}/unenroll`, {}, config).then((res) => {
                if (res.status === 200) {
                    setConfirmed(true);
                    setUnenrollSuccessful(true);
                }
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleUnenrollOK = () => {
        setUnenrollSuccessful(false);
        closeModal();
    };

    const UnenrollmentSuccesful = () => {
        return (
            <>
                <div className="popup" id="popup-overlay">
                    <div className="popup-inner">
                        <div className="circle-box">
                            <IoCheckmarkCircleOutline className="checkicon" />
                            <h3>SUCCESS!</h3>
                        </div>
                        <div className="popup-txt-contr">
                            <div className="txt-cont">
                                <p className="bold-txt">Unenrolled successfully</p>
                                <p>You have successfully unenrolled from a session.</p>
                            </div>
                            <div className="btn-cont">
                                <button className="close-btn" onClick={handleUnenrollOK}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {!confirmed && (
                <div className="popup" id="popup-overlay">
                    <div className="popup-inner">
                        <div className="circle-box" id="warning">
                            <BsExclamationCircle className="checkicon" />
                            <h3>UNENROLL?</h3>
                        </div>

                        <div className="popup-txt-contr">
                            <div className="txt-cont">
                                <p className="bold-txt">Are you sure you want to unenroll?</p>
                            </div>
                            <div className="btn-cont delete-contr">
                                <button className="close-btn" onClick={handleDelete} id="yes">
                                    YES
                                </button>
                                <button className="close-btn" onClick={() => setConfirmed(!confirmed)} id="cancel">
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {unenrollSuccessful && <UnenrollmentSuccesful />}
        </>
    );
};

export default ModalUnenroll;
