import React, { Fragment, useState, useEffect, useContext } from "react";
import { AppContext } from "../../App";
import axios from "axios";
import moment from "moment";
import Calendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "../../SpecialPages/css/main-calendar.css";
import "../../SpecialPages/css/calendar-info.css";
import { getBaseUrl } from "../../baseURL";

const MyCalendarLearner = () => {
  const [events, setEvents] = useState([]);
  const { config } = useContext(AppContext);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupEvents, setShowPopupEvents] = useState(false);

  useEffect(() => {
    axios
      .get(`${getBaseUrl()}/api/user/learnerSessions`, config)
      .then(async (response) => {
        const sessions = response.data;
        // const classData = await axios
        //     .get("${getBaseUrl()}:3001/api/class")
        //     .then((res) => res.data);
        const events = sessions.map((session) => {
          // const sessionClass = classData.find(
          //     (cls) => cls._id === session.sessionClass
          // );
          let eventColor;
          switch (session.sessionClass.category) {
            case "SZU Core":
              eventColor = "#bdb0a0";
              break;
            case "MasterclaSZ for Shared Services":
              eventColor = "#7bb147";
              break;
            case "MasterclaSZ for Service Delivery":
              eventColor = "#3ba9c6";
              break;
            case "SZU Mandatory":
              eventColor = "#004b99";
              break;
            case "SZU Elective":
              eventColor = "#694730";
              break;
            case "STEP Pre-Onboarding":
              eventColor = "#7CDB54";
              break;
            case "MTEP Pre-Onboarding":
              eventColor = "#db547c";
              break;
            default:
              eventColor = "a6c085";
          }
          return {
            start: new Date(session.startTime),
            end: new Date(session.endTime),
            title: session.sessionClass
              ? session.sessionClass.title
              : "Unknown class",
            description: session.sessionClass
              ? session.sessionClass.description
              : "Unknown description",
            // instructor: session.sessionClass ? session.sessionClass.instructor : 'Unknown instructor',
            allDay: false,
            display: "auto",
            color: eventColor,
          };
        });
        setEvents(events);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDateClick = (arg) => {
    const selected = events.filter((event) =>
      moment(event.start).isSame(arg.date, "day")
    );
    setSelectedDate(arg.date);
    setSelectedEvents(selected);
    setShowPopup(true);
    setShowPopupEvents(false);
  };

  const eventTimeFormat = {
    hour: "numeric",
    minute: "2-digit",
    meridiem: "short",
  };

  const handleEventClick = (arg) => {
    setSelectedEvents([arg.event]);
    setSelectedDate(arg.event.start);
    setShowPopup(false);
    setShowPopupEvents(true);
  };

  return (
    <Fragment>
      <div>
        <Calendar
          timeZone="local"
          selectable={true}
          height={650}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          events={events}
          fixedWeekCount={false}
          dayMaxEventRows={true}
          eventColor={"#f4941c"}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          eventTimeFormat={eventTimeFormat}
          // eventLimitClick={"popover"}
          // style={{ height: "100vh" }}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,listWeek",
            // left: 'prev, next today',
            // center: 'title',
            // right: ['month, agendaWeek, agendaDay']
          }}
          initialView="dayGridMonth"
          slotDuration="00:60:00"
          buttonText={{
            today: "Today",
            month: "Month",
            week: "Week",
            list: "Agenda",
          }}
        />
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h5 className="date-title">
              {selectedDate && moment(selectedDate).format("dddd, LL")}
            </h5>
            <div className="calendar-info-container">
              {selectedEvents.length > 0 ? (
                selectedEvents.map((event, index) => (
                  <div key={index}>
                    <p className="calendar-info-title">{event.title}</p>
                    <p className="calendar-date-container">
                      {moment(event.start).format("h:mm A")} -{" "}
                      {moment(event.end).format("h:mm A")}
                    </p>
                  </div>
                ))
              ) : (
                <p className="calendar-date-container">
                  No events scheduled for this date.
                </p>
              )}
              <div className="close-calendar">
                <button onClick={() => setShowPopup(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPopupEvents && (
        <div className="popup">
          <div className="popup-content">
            <div className="card-container">
              <h5 className="date-title">
                {selectedDate ? moment(selectedDate).format("dddd, LL") : ""}
              </h5>
              <div className="calendar-info-container">
                {selectedEvents.map((event, index) => {
                  console.log(event);
                  console.log(index);
                  return (
                    <div key={index}>
                      <p className="calendar-info-title">{event.title}</p>
                      <div className="calendar-date-container">
                        <p>
                          <span style={{ fontWeight: 600 }}>Start</span>{" "}
                          {moment(event.start).format("LT")}
                        </p>
                        <p>
                          <span style={{ fontWeight: 600 }}>End</span>{" "}
                          {moment(event.end).format("LT")}
                        </p>
                        {/* <p><span style={{ fontWeight: 600 }}>Instructors: </span>{event.extendedProps.instructor}</p> */}
                        <p>
                          <span style={{ fontWeight: 600 }}>Description: </span>
                          {event.extendedProps.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
                <div className="close-calendar">
                  <button onClick={() => setShowPopupEvents(false)}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MyCalendarLearner;
