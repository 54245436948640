import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import "../css/add-class.css";
import "../css/add-instructor.css";
import { getBaseUrl } from "../../baseURL";

import Session from "../../SpecialPages/components/Session";
// import AddInstructor from "./AddInstructor";
import { AppContext } from "../../App";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

// const classCategory = [
//   { value: "SZtallion Talks", label: "SZtallion Talks" },
//   {
//     value: "MasterclaSZ for Service Delivery",
//     label: "MasterclaSZ for Service Delivery",
//   },
//   {
//     value: "MasterclaSZ for Shared Services",
//     label: "MasterclaSZ for Shared Services",
//   },
//   {
//     value: "SupportZebra Leadership Program",
//     label: "SupportZebra Leadership Program",
//   },
//   { value: "SZTR Talks", label: "SZTR Talks" },
//   { value: "STEP Pre-Onboarding", label: "STEP Pre-Onboarding" },
// ];

//New Category Changes from order of Sir Ronald Maguyon
const classCategory = [
    {
        value: "SZU Core",
        label: "SZU Core",
    },
    {
        value: "SZU Mandatory",
        label: "SZU Mandatory",
    },
    {
        value: "SZU Elective",
        label: "SZU Elective",
    },
    {
        value: "STEP Pre-Onboarding",
        label: "STEP Pre-Onboarding",
    },
    {
        value: "MTEP Pre-Onboarding",
        label: "MTEP Pre-Onboarding",
    },
    {
        value: "MasterclaSZ for Service Delivery",
        label: "MasterclaSZ for Service Delivery",
    },
    {
        value: "MasterclaSZ for Shared Services",
        label: "MasterclaSZ for Shared Services",
    },
];
const AddClassCard = () => {
    const [buttonPopUp, setButtonPopUp] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [courseName, setCourseName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [sessions, setSessions] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const { appState, config } = useContext(AppContext);
    const { setDisplay, setInstructorDisplay } = useContext(AppContext);
    const [instructors, setInstructors] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [searchName, setSearchName] = useState("");

    useEffect(() => {
        const fetchInstructors = async () => {
            const users = await axios.get(`${getBaseUrl()}/api/user/overview`, config);
            const { instructors, admins } = users.data;
            setInstructors([...instructors, ...admins]);
        };
        fetchInstructors();
    }, []);

    const searchInstructor = async () => {
        if (!searchName) {
            // check if searchName is not empty
            return;
        }
        axios
            .get(`${getBaseUrl()}/api/user/searchInstructor/${searchName}`, config)
            .then((response) => {
                setInstructors(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const handleInputChange = (inputValue) => {
        setSearchName(inputValue);
        searchInstructor(inputValue);
    };

    const handleSelectCategory = (selected) => {
        setCategory(selected.value);
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);

        const formData = {
            courseName,
            description,
            category,
            selectedOption,
            sessions,
            userId: appState.id,
        };

        try {
            const response = await axios.post(`${getBaseUrl()}/api/session`, formData, config);
            // const response = await axios.post(`http://localhost:3001/api/session`, formData, config);
            if (response.status === 201) {
                setButtonPopUp(true);
                setCourseName("");
                setDescription("");
                setCategory("");
                setSessions([]);
                setInstructors([]);
                setSelectedOption([]);
                setIsLoading(false);
            }
        } catch (err) {
            setErrorMessage(err.response.data);
        } finally {
            setIsSubmitting(false);
        }
    };

    const AddClassPopUp = () => {
        const handleAdminButtonClick = () => {
            setDisplay("AdminOverview");
        };

        const handleInstructorButtonClick = () => {
            setInstructorDisplay("InstructorViewClasses");
        };

        return (
            <>
                <div className="popup">
                    <div className="popup-inner">
                        <div className="circle-box">
                            <IoCheckmarkCircleOutline className="checkicon" />
                            <h3>SUCCESS!</h3>
                        </div>
                        <div className="popup-txt-contr">
                            <div className="txt-cont">
                                <p className="bold-txt">Course Created Successfully</p>
                                <p>
                                    A Course has been successfully created. Check your Calendar for
                                    details.
                                </p>
                            </div>
                            <div className="btn-cont">
                                {appState.role === "admin" && appState.currentRole === "admin" && (
                                    <button className="close-btn" onClick={handleAdminButtonClick}>
                                        OK
                                    </button>
                                )}
                                {appState.role === "admin" &&
                                    appState.currentRole === "instructor" && (
                                        <button
                                            className="close-btn"
                                            onClick={handleInstructorButtonClick}
                                        >
                                            OK
                                        </button>
                                    )}

                                {appState.role === "instructor" &&
                                    appState.currentRole === "instructor" && (
                                        <button
                                            className="close-btn"
                                            onClick={handleInstructorButtonClick}
                                        >
                                            OK
                                        </button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="info-wrapper">
                <div className="personal-info">
                    <p> Course name</p>
                    <div className="input-container">
                        <input
                            type="text"
                            value={courseName}
                            placeholder="e.g Introduction to Leadership"
                            onChange={(e) => setCourseName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="personal-info">
                    <p> Category</p>
                    <div className="dropdown-checkbox">
                        <Select
                            placeholder="Select Category"
                            options={classCategory}
                            onChange={handleSelectCategory}
                        />
                    </div>
                </div>
                <div className="personal-info">
                    <p> Description</p>
                    <div className="input-container">
                        <textarea
                            type="textarea"
                            value={description}
                            placeholder="Add class description"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </div>
                <hr />
                <div className="personal-info">
                    <p> Instructor</p>
                    <div className="dropdown-checkbox">
                        <Select
                            name="Instructor"
                            required
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            placeholder="Select Instructor"
                            value={selectedOption}
                            isMulti
                            options={instructors}
                            getOptionLabel={(instructor) =>
                                `${instructor.firstName} ${instructor.lastName}`
                            }
                            getOptionValue={(instructor) => instructor._id}
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <hr></hr>
                <div className="personal-info" id="session-title">
                    <p> Session</p>
                    <div className="add-session-container">
                        <Session sessions={sessions} setSessions={setSessions} />
                    </div>
                </div>
                <hr></hr>
                {errorMessage && (
                    <p className="error-message" id="end-position">
                        {errorMessage}
                    </p>
                )}
                <div className="btn-container">
                    {!isLoading ? (
                        <button className="submit-btn" type="submit" disabled={isSubmitting}>
                            SUBMIT
                        </button>
                    ) : (
                        <div className="submit-loading">
                            <img src="loading-small.gif" alt="loading" />
                        </div>
                    )}
                </div>

                {buttonPopUp && <AddClassPopUp />}
            </form>
        </div>
    );
};

export default AddClassCard;
