import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";

import { CgClose } from "react-icons/cg";

import "../css/modal-class-info.css";

const ModalActivityView = ({ toggleActivityView, activity }) => {
    const date = new Date(activity.createdAt);

    return (
        <div className="overlay">
            <div className="modal-content">
                <div className="title-container">
                    <CgClose id="back-icon" onClick={() => toggleActivityView()} />
                    <h1>Activity Details</h1>
                </div>

                <div className="info-container">
                    <div className="class-name">
                        <div className="category-description-box">
                            <div className="name">
                                <p>{activity.action}</p>

                                <div className="category">
                                    <p>
                                        {date.toLocaleDateString()} {date.toLocaleTimeString([], { hour12: true })}
                                    </p>
                                </div>
                            </div>

                            <div className="description">
                                <p className="lined-box">User:</p>
                                <div className="details-box">
                                    <p>{activity.user.email}</p>
                                </div>
                            </div>

                            <div className="instructor">
                                <p className="lined-box">Details: </p>
                                <div className="details-box">
                                    <p>{activity.details}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div></div>
                </div>
            </div>
        </div>
    );
};
export default ModalActivityView;
