import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/special-routes.css";

const Error = () => {
    const navigate = useNavigate();

    const handlesdashboard = () => {
        navigate("/");
    };

    return (
        <div className="vmain-container">
            <div className="vform-container">
                <div>
                    <img src="404-page.png" alt="verification-success" />
                </div>

                <div className="vcontainer">
                    <h5 id="error-404">404</h5>
                    <h5 id="not-found">Oops! Page Not Found</h5>

                    <p className="message-404">
                        We're sorrry the page you requested could not be found. Please make sure you typed the correct URL.
                    </p>

                    <button className="vlogin-button" onClick={handlesdashboard}>
                        DASHBOARD
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Error;
