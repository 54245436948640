import React from 'react'

const PasswordValidator = ({ password }) => {
    const hasEightChars = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialChar = /[@$!%*?&_]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    return (
        <ul>
            <li style={{ color: hasEightChars ? 'green' : 'red', fontWeight: 300, fontSize: 12 }}>
                At least 8 characters
            </li>
            <li style={{ color: hasUppercase ? 'green' : 'red', fontWeight: 300, fontSize: 12 }}>
                At least 1 uppercase letter
            </li>
            <li style={{ color: hasSpecialChar ? 'green' : 'red', fontWeight: 300, fontSize: 12 }}>
                At least 1 special character (@$!%*?&_)
            </li>
            <li style={{ color: hasLowercase ? 'green' : 'red', fontWeight: 300, fontSize: 12 }}>
                At least 1 lowercase letter
            </li>
            <li style={{ color: hasNumber ? 'green' : 'red', fontWeight: 300, fontSize: 12 }}>
                At least 1 number
            </li>
        </ul >
    );
};

export default PasswordValidator

