import React from "react";
import "../css/skeleton-modal.css";

const SkeletonModalClassInfo = () => {
    return (
        <div className="skeleton-modalclassinfo">
            <div className="class-name">
                <div className="name">
                    <div className="title"></div>

                    <div className="category"></div>
                </div>
                <div className="description">
                    <div className="lined-box"></div>
                    <div className="details-box"></div>
                </div>
                <div className="instructor">
                    <div className="lined-box"></div>
                    <div className="details-box"></div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonModalClassInfo;
