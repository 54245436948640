// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-container {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    background-color: #33413e;\n    align-content: center;\n    padding-left: calc(100vw * 20 / 1080);\n    color: white;\n    height: 50px;\n}\n\n.footer-container p {\n    margin: 0px;\n}\n\n.sz-name {\n    font-family: \"Montserrat\";\n    font-weight: 700;\n}\n\n.copyright {\n    margin-left: 5px !important;\n    font-family: \"Poppins\";\n    font-weight: 200;\n    font-size: 10px !important;\n    text-align: center;\n}\n\n.footer-container img {\n    width: 30px;\n    height: 30px;\n    margin-right: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/SpecialPages/css/footer.css"],"names":[],"mappings":"AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,qCAAqC;IACrC,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,gBAAgB;IAChB,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap\");\n\n.footer-container {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    background-color: #33413e;\n    align-content: center;\n    padding-left: calc(100vw * 20 / 1080);\n    color: white;\n    height: 50px;\n}\n\n.footer-container p {\n    margin: 0px;\n}\n\n.sz-name {\n    font-family: \"Montserrat\";\n    font-weight: 700;\n}\n\n.copyright {\n    margin-left: 5px !important;\n    font-family: \"Poppins\";\n    font-weight: 200;\n    font-size: 10px !important;\n    text-align: center;\n}\n\n.footer-container img {\n    width: 30px;\n    height: 30px;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
