import React, { useState } from "react";
import "./forgotPassword.css";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router";
import axios from "axios";
import PopUp from "../PopUp";
import { BsShieldLock } from "react-icons/bs";
import { getBaseUrl } from "../../../baseURL";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  // State to store the form input values
  const [formData, setFormData] = useState({
    email: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Event handler for form input changes
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = () => {
    navigate("/");
  };

  // Event handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (
      !formData.email ||
      !/^[A-Za-z0-9!#$%&'*+-/=?^_`{|}~]+([.][A-Za-z0-9!#$%&'*+-/=?^_`{|}~]+)*@\S+([.][A-Za-z]{2,})+$/.test(
        formData.email
      )
    ) {
      setErrorMessage("Invalid email address.");
    } else {
      if(isSubmitting){
        return
      }
  
      setIsSubmitting(true)
      try {
        const response = await axios.post(
          `${getBaseUrl()}/api/user/forgotpassword`,
          formData
        );
        if (response.data.success) {
          setSuccessMessage(response.data.message);
          setButtonPopUp(true);
        } else {
          setErrorMessage(response.data.message);
        }
      } catch (err) {
        console.log(err.message);
        if (err.response && err.response.status === 404) {
          setErrorMessage("User not found");
        } else {
          setErrorMessage("An error occurred while trying to reset password.");
          console.log(err);
        }
      }finally{
        setIsSubmitting(false);
      }
    }
  };

  const signupPop = () => {
    return (
      <>
        <div className="circle-box">
          <BsShieldLock className="checkicon" />
          <h3>PASSWORD RESET</h3>
        </div>
        <div className="popup-txt-contr">
          <div className="txt-cont">
            <p className="bold-txt">
              You're almost finished!
              <p>
                We'll be sending a link in your email shortly. In order to reset
                the password of your account
              </p>
            </p>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="forgot-reset-main-container">
      <form onSubmit={handleSubmit} className="form-container">
        <img
          src="forgot-password.png"
          alt="forgot-password png"
          id="forgot-reset-logo"
        />

        <div className="forgot-password-container">
          <h2 className="forgot-header">Forgot your Password?</h2>

          <div>
            <p>Enter your email to reset your password</p>
            <div className="input-container">
              <FaEnvelope className="icon" />
              <input
                required
                type="email"
                name="email"
                value={formData.email}
                placeholder="email address"
                onChange={handleChange}
                className="input-field"
              />
            </div>
            {errorMessage && (
              <p className="error-message" style={{ marginTop: 10 }}>
                {errorMessage}
              </p>
            )}
          </div>

          <button className="forgot-password-button" type="submit">
            RESET PASSWORD
          </button>

          <PopUp
            trigger={buttonPopUp}
            setTrigger={setButtonPopUp}
            popUp={signupPop}
          />

          <div style={{ textAlign: "center" }}>
            <span id="account-text">Remembered your account? </span>
            <span id="click-here" onClick={handleLogin}>
              Login here
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
