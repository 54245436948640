import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "../css/admin-left-dash.css";
import { AppContext } from "../../App";

import {
  MdHome,
  MdPerson,
  MdPeople,
  MdOutlineFileDownload,
  MdListAlt,
} from "react-icons/md";
import { TbBooks } from "react-icons/tb";
import { BiChevronRight, BiChevronDown } from "react-icons/bi";
import ModalExport from "../../SpecialPages/components/ModalExport";
import { getBaseUrl } from "../../baseURL";

const useToggle = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const toggle = () => setValue(!value);
  return [value, toggle];
};

const AdminLeftDashboard = () => {
  const { display, setDisplay } = useContext(AppContext);
  const [showProfileLinks, setShowProfileLinks] = useToggle(false);
  const [showUserLinks, setShowUserLinks] = useToggle(false);
  const [showClassesLinks, setShowClassesLinks] = useToggle(false);
  const [showExportLinks, setShowExportLinks] = useToggle(false);
  // const { appState, config } = useContext(AppContext);
  const [activityExport, setActivityExport] = useState(false);

  const toggleModalExport = () => {
    setActivityExport(!activityExport);
  };

  // const handleExport = async () => {
  //     try {
  //         const today = new Date().toISOString().slice(0, 10);
  //         const response = await axios.get("${getBaseUrl()}:3001/api/export/excel", {
  //             ...config,
  //             responseType: "blob", // Make sure response is treated as a binary blob
  //         });
  //         // Create a URL object from the blob response
  //         const url = window.URL.createObjectURL(new Blob([response.data]));

  //         // Create a new anchor element to initiate download
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", `${today}_LDTC_ACTIVITY_LOG_data.xlsx`);

  //         // Simulate a click on the anchor to initiate download
  //         const downloadConfirmation = window.confirm("Do you want to download this excel file?");
  //         if (downloadConfirmation) {
  //             document.body.appendChild(link);
  //             link.click();
  //         }
  //         // Clean up URL object after download is complete
  //         window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //         console.error(error);
  //     }
  // };

  return (
    <>
      <div
        className={`outer-container ${
          display === "AdminHome" ? "selected-link" : ""
        }`}
      >
        <MdHome className="icons" />

        <Link
          className="title-icons-link"
          onClick={() => setDisplay("AdminHome")}
        >
          HOME
        </Link>
      </div>

      <div
        className={`outer-container ${
          display === "AdminUpdateProfile" || display === "AdminUpdatePassword"
            ? "selected-link"
            : ""
        }`}
      >
        <MdPerson className="icons" />

        <div className="inner-container">
          <Link className="title-icons-link" onClick={setShowProfileLinks}>
            PROFILE
            {showProfileLinks ? (
              <BiChevronDown className="toggle-down" />
            ) : (
              <BiChevronRight className="toggle-up" />
            )}
          </Link>

          {showProfileLinks && (
            <>
              <Link
                className={`small-links ${
                  display === "AdminUpdateProfile" ? "selected-small-links" : ""
                }`}
                onClick={() => {
                  setDisplay("AdminUpdateProfile");
                }}
              >
                Edit Profile
              </Link>

              <Link
                className={`small-links ${
                  display === "AdminUpdatePassword"
                    ? "selected-small-links"
                    : ""
                }`}
                onClick={() => setDisplay("AdminUpdatePassword")}
              >
                Change Password
              </Link>
            </>
          )}
        </div>
      </div>

      <div
        className={`outer-container ${
          display === "AdminUsers" || display === "AdminAddUser"
            ? "selected-link"
            : ""
        }`}
      >
        <MdPeople className="icons" />

        <div className="inner-container">
          <Link className="title-icons-link" onClick={setShowUserLinks}>
            USERS
            {showUserLinks ? (
              <BiChevronDown className="toggle-down" />
            ) : (
              <BiChevronRight className="toggle-up" />
            )}
          </Link>

          {showUserLinks && (
            <>
              <Link
                className={`small-links ${
                  display === "AdminUsers" ? "selected-small-links" : ""
                }`}
                onClick={() => setDisplay("AdminUsers")}
              >
                View Users
              </Link>

              <Link
                className={`small-links ${
                  display === "AdminAddUser" ? "selected-small-links" : ""
                }`}
                onClick={() => setDisplay("AdminAddUser")}
              >
                Add User
              </Link>
            </>
          )}
        </div>
      </div>

      <div
        className={`outer-container ${
          display === "AdminViewClass" || display === "AdminAddClass"
            ? "selected-link"
            : ""
        }`}
      >
        <TbBooks className="icons" />

        <div className="inner-container">
          <Link className="title-icons-link" onClick={setShowClassesLinks}>
            COURSES
            {showClassesLinks ? (
              <BiChevronDown className="toggle-down" />
            ) : (
              <BiChevronRight className="toggle-up" />
            )}
          </Link>

          {showClassesLinks && (
            <>
              <Link
                className={`small-links ${
                  display === "AdminViewClass" ? "selected-small-links" : ""
                }`}
                onClick={() => setDisplay("AdminViewClass")}
              >
                View Courses
              </Link>
              <Link
                className={`small-links ${
                  display === "AdminAddClass" ? "selected-small-links" : ""
                }`}
                onClick={() => setDisplay("AdminAddClass")}
              >
                Add Course
              </Link>
            </>
          )}
        </div>
      </div>

      <div className="outer-container">
        <MdOutlineFileDownload className="icons" />
        <div className="inner-container">
          <Link className="title-icons-link" onClick={setShowExportLinks}>
            EXPORT
            {showExportLinks ? (
              <BiChevronDown className="toggle-down" />
            ) : (
              <BiChevronRight className="toggle-up" />
            )}
          </Link>

          {showExportLinks && (
            <Link
              className="small-links"
              data-hover="Export File"
              onClick={() => toggleModalExport()}
            >
              {activityExport ? (
                <ModalExport
                  toggleModalExport={toggleModalExport}
                  isModalClassInfo={false}
                  exportWhat={"all-data"}
                />
              ) : null}
              Export All to Excel
            </Link>
          )}
        </div>
      </div>
      <div
        className={`outer-container ${
          display === "AdminActivity" ? "selected-link" : ""
        }`}
      >
        <MdListAlt className="icons" />

        <Link
          className="title-icons-link"
          onClick={() => setDisplay("AdminActivity")}
        >
          ACTIVITY LOGS
        </Link>
      </div>
      <hr />
    </>
  );
};

export default AdminLeftDashboard;
