import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FaFileExport, FaSearch } from "react-icons/fa";
import "../css/admin-classes.css";
import CardActivity from "../../SpecialPages/components/CardActivity";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppContext } from "../../App";
import ModalExport from "../../SpecialPages/components/ModalExport";
import { getBaseUrl } from "../../baseURL";
const AdminClasses = () => {
  const [activity, setActivity] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activityExport, setActivityExport] = useState(false);
  const { config } = useContext(AppContext);

  let limit = 30;

  const toggleModalExport = () => {
    setActivityExport(!activityExport);
  };

  useEffect(() => {
    axios
      .get(
        `${getBaseUrl()}/api/activity?page=${currentPage}&limit=${limit}`,
        config
      )
      .then((res) => {
        const total = res.headers.get("X-Total-Count");
        setpageCount(Math.ceil(total / limit));
        setActivity([...activity, ...res.data]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  function ActivityLists() {
    if (activity.length > 0) {
      return (
        <InfiniteScroll
          dataLength={activity.length}
          next={() => {
            setCurrentPage(currentPage + 1);
          }}
          hasMore={currentPage < pageCount}
          loader={
            <div>
              <p className="end-message">Loading...</p>
            </div>
          }
        >
          <div className="activity-list">
            <div className="column-name">
              <div className="column-box" id="activity-column">
                <p>USER</p>
              </div>
              <div className="column-box" id="activity-column">
                <p>ACTION</p>
              </div>
              <div className="column-box" id="activity-column">
                <p>DETAILS</p>
              </div>
              <div className="column-box" id="activity-column">
                <p>TIME</p>
              </div>
            </div>
            {activity
              .filter((activity) => {
                return search.toLowerCase() === ""
                  ? true
                  : activity.action
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                      activity.details
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      activity.user
                        .toLowerCase()
                        .includes(search.toLowerCase()); // ||
              })
              .map((activity) => (
                <CardActivity activity={activity} key={activity._id} />
              ))}
            {search &&
              search.length > 0 &&
              !activity.filter(
                (activity) =>
                  activity.action
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  activity.details
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  activity.user.toLowerCase().includes(search.toLowerCase()) // ||
              ).length && (
                <div className="empty-classes">
                  <p>No results found for "{search}".</p>
                </div>
              )}
          </div>
        </InfiniteScroll>
      );
    } else {
      return (
        <div className="empty-users">
          <img src="zebra.png" alt="zebra png" id="zebra-logo" />

          <h1>There are no activity as of the moment</h1>
        </div>
      );
    }
  }

  return (
    <div className="view-card">
      <div className="title-container">
        <div className="title-box">
          <h1>ACTIVITY LOGS</h1>
        </div>
        <div className="search-box">
          <div className="input-container">
            <FaSearch className="paginationbox-icon" id="search-icon" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              className="input-field"
            />
          </div>
          <FaFileExport
            title="Export Activity"
            className="paginationbox-icon"
            onClick={() => toggleModalExport()}
          />
          {activityExport ? (
            <ModalExport
              toggleModalExport={toggleModalExport}
              isModalClassInfo={false}
              exportWhat={"activity"}
            />
          ) : null}
        </div>
      </div>

      <div className="activity-container">
        <div className="info-container">
          {isLoading ? (
            <div className="loading-animation">
              <img src="loading.gif" alt="loading" id="loading-gif-id" />
            </div>
          ) : (
            <>
              <ActivityLists />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminClasses;
