import React, { useContext, useState } from "react";
import { MdClose } from "react-icons/md";

import "../css/modal-sessions-info.css";
import { AppContext } from "../../App";
import { IoMdTrash } from "react-icons/io";
import { MdOutlineCopyAll, MdOutlineDone } from "react-icons/md";
import LinedBoxCategory from "./LinedBoxCategory";

const ModalSessionsInfo = ({ toggleSessionInfo, viewClassCard }) => {
    const { appState } = useContext(AppContext);
    const [copied, setCopied] = useState(false);
    const [isShow, setisShow] = useState(true);

    const options = {
        hour: "numeric",
        minute: "numeric",
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    const { startTime, endTime, capacity, createdAt, learners } = viewClassCard;
    const { title, description, category, instructor } = viewClassCard.sessionClass;

    const startTimeConverted = new Date(startTime);
    const endTimeConverted = new Date(endTime);
    const createdTimeConverted = new Date(createdAt);
    const created = createdTimeConverted.toLocaleString(undefined, options);

    let idColor = "";
    if (appState.currentRole === "learner") {
        idColor = "learner-color";
    } else if (appState.currentRole === "admin") {
        idColor = "admin-color";
    } else if (appState.currentRole === "instructor") {
        idColor = "instructor-color";
    } else if (appState.currentRole === "today") {
        idColor = "today-color";
    }

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const copyToClipboard = () => {
        const learnerEmails = learners.map((learner) => learner.email).join(", ");
        navigator.clipboard.writeText(learnerEmails);
        setCopied(true);
    };

    const handleAnimate = () => {
        setisShow(false);
        toggleSessionInfo();
    };

    return (
        <div className={`overlay ${isShow ? "fade-in" : "fade-out"}`} onClick={handleAnimate} id="classinfo-overlay">
            <div className={`modal-content ${isShow ? "fade-in" : "fade-out"}`} onClick={handleModalClick}>
                {appState.currentRole === "admin" ? (
                    <div className="admin-title-container">
                        <div className="title-and-icon">
                            <MdClose
                                id="back-icon"
                                onClick={() => {
                                    setisShow(false);
                                    toggleSessionInfo();
                                }}
                            />
                            <h1>SESSION DETAILS</h1>
                        </div>
                        <div className="edit-delete">
                            {/* <MdEdit id="edit-icon" onClick={() => setSessionDisplay("edit")} /> */}
                            <IoMdTrash id="trash-icon" />
                        </div>
                    </div>
                ) : null}
                {appState.currentRole === "instructor" ? (
                    <div className="instructor-title-container">
                        <div className="title-and-icon">
                            <MdClose
                                id="back-icon"
                                onClick={() => {
                                    setisShow(false);
                                    toggleSessionInfo();
                                }}
                            />
                            <p>SESSION DETAILS</p>
                        </div>
                    </div>
                ) : null}
                {appState.currentRole === "learner" ? (
                    <div className="learner-title-container">
                        <MdClose
                            id="back-icon"
                            onClick={() => {
                                setisShow(false);
                                toggleSessionInfo();
                            }}
                        />
                        <p>SESSION</p>
                    </div>
                ) : null}

                <div className="modal-card-wrapper">
                    <div className="class-card-container">
                        <div className="col-classTitle-container">
                            <p className="class-title">{title}</p>
                        </div>

                        <div className="col-container">
                            <LinedBoxCategory category={category} />
                        </div>

                        <div className="col-container">
                            <p className="col-title" id={`${idColor}`}>
                                TIME AND DATE
                            </p>
                            <div className="time-date-description">
                                <div className="session-time">
                                    <div className="time-start">
                                        <p className="time">
                                            {startTimeConverted.toLocaleTimeString([], {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })}
                                        </p>
                                        <p className="date">
                                            {startTimeConverted.toLocaleDateString([], {
                                                month: "short",
                                                day: "numeric",
                                            })}
                                        </p>
                                    </div>
                                    <div className="time-end">
                                        <p className="time">
                                            {endTimeConverted.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true })}
                                        </p>
                                        <p className="date">
                                            {endTimeConverted.toLocaleDateString([], {
                                                month: "short",
                                                day: "numeric",
                                            })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-container">
                            <p className="col-title">COURSE DESCRIPTION</p>
                            <p className="course-description">{description}</p>
                        </div>
                        <div className="col-container" id="instructor-col">
                            <p className="col-title">INSTRUCTORS</p>
                            <div className="course-description" id="instructors-list">
                                {instructor.map((i) => (
                                    <div className="instructor" key={i._id}>
                                        <p className="name">{i.firstName + " " + i.lastName}</p>
                                        <p className="email">{i.email}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="col-container">
                            <p className="col-title" id={`${idColor}`}>
                                NUMBER OF ENROLLED STUDENTS
                            </p>
                            <p className="course-description">
                                {learners.length}/{capacity} students
                            </p>
                        </div>
                        {appState.currentRole === "instructor" && (
                            <div className="col-container" id="enrolled-student-container">
                                {learners.length > 0 && (
                                    <>
                                        <div className="col-title" id={`${idColor}`}>
                                            <p className="enrolled-students">ENROLLED STUDENTS</p>
                                            <div onClick={copyToClipboard}>
                                                {copied ? (
                                                    <MdOutlineDone className="copy-student" title="Copied!" />
                                                ) : (
                                                    <MdOutlineCopyAll className="copy-student" title="Copy emails" />
                                                )}
                                            </div>
                                        </div>

                                        <div className="course-description">
                                            {learners.map((i) => (
                                                <div className="learners-info" key={i._id}>
                                                    <p className="learner-name">
                                                        {i.firstName} {i.lastName}
                                                    </p>
                                                    <p className="learner-email">{i.email}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                        <p className="created">Created {created}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSessionsInfo;
