import React, { useContext, useState } from "react";
import { MdClose } from "react-icons/md";
import { AppContext } from "../../App";

import "../css/modal-class-ppf.css";
import LinedBoxCategory from "./LinedBoxCategory";

const ModalClassPPF = ({ toggleClassInfo, session, roleColor }) => {
    const { appState } = useContext(AppContext);
    const [isShow, setisShow] = useState(true);

    const options = {
        hour: "numeric",
        minute: "numeric",
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    const { startTime, endTime, learners, capacity, createdAt } = session;
    const { instructor, title, description, category } = session.sessionClass;

    const startTimeConverted = new Date(startTime);
    const endTimeConverted = new Date(endTime);
    const createdTimeConverted = new Date(createdAt);
    const created = createdTimeConverted.toLocaleString(undefined, options);

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const handleAnimate = () => {
        setisShow(false);
        toggleClassInfo();
    };

    let idRole = "";
    let idColor = "";
    if (roleColor === "learner") {
        idRole = "learner-title-container";
        idColor = "learner-color";
    } else if (roleColor === "admin") {
        idRole = "admin-title-container";
        idColor = "admin-color";
    } else if (roleColor === "instructor") {
        idRole = "instructor-title-container";
        idColor = "instructor-color";
    } else if (roleColor === "today") {
        idRole = "today-title-container";
        idColor = "today-color";
    }

    return (
        <div className={`overlay ${isShow ? "fade-in" : "fade-out"}`} onClick={handleAnimate}>
            <div className={`modal-content ${isShow ? "fade-in" : "fade-out"}`} onClick={handleModalClick}>
                <div className="title-container" id={`${idRole}`}>
                    <MdClose
                        id="back-icon"
                        onClick={() => {
                            setisShow(false);
                            toggleClassInfo();
                        }}
                    />
                    <p>SESSION DETAILS</p>
                </div>
                <div className="card-wrapper" id="class-modal-card-wrapper">
                    <div className="class-card-container">
                        <div className="col-container">
                            <p className="class-title">{title}</p>
                        </div>

                        <div className="col-container">
                            <LinedBoxCategory category={category} />
                        </div>

                        <div className="col-container">
                            <p className="col-title" id={`${idColor}`}>
                                TIME AND DATE
                            </p>
                            <div className="time-date-description">
                                <div className="session-time">
                                    <div className="time-start">
                                        <p className="time">
                                            {startTimeConverted.toLocaleTimeString([], {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })}
                                        </p>
                                        <p className="date">
                                            {startTimeConverted.toLocaleDateString([], {
                                                month: "short",
                                                day: "numeric",
                                            })}
                                        </p>
                                    </div>
                                    <div className="time-end">
                                        <p className="time">
                                            {endTimeConverted.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true })}
                                        </p>
                                        <p className="date">
                                            {endTimeConverted.toLocaleDateString([], {
                                                month: "short",
                                                day: "numeric",
                                            })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-container">
                            <p className="col-title" id={`${idColor}`}>
                                COURSE DESCRIPTION
                            </p>
                            <div className="course-description">{description}</div>
                        </div>
                        <div className="col-container">
                            <p className="col-title" id={`${idColor}`}>
                                INSTRUCTORS
                            </p>
                            <div className="instructor-list">
                                {instructor.map((i) => (
                                    <div key={i._id}>{i.firstName + " " + i.lastName}</div>
                                ))}
                            </div>
                        </div>
                        <div className="col-container">
                            <p className="col-title" id={`${idColor}`}>
                                NUMBER OF ENROLLED STUDENTS
                            </p>
                            <p className="course-description">
                                {learners.length}/{capacity} students
                            </p>
                        </div>
                        {appState.currentRole === "instructor" && (
                            <div className="col-container">
                                <p className="col-title" id={`${idColor}`}>
                                    ENROLLED STUDENTS
                                </p>
                                <div className="course-description">
                                    {learners.map((i) => (
                                        <p className="learners-list" key={i._id}>
                                            {i.firstName + " " + i.lastName}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        )}

                        <p className="created">Created {created}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalClassPPF;
