//REACT IMPORT
import React from "react";
import { MdClose } from "react-icons/md";
import FormBodyClassEdit from "./FormBodyClassEdit";
// import { AppContext } from "../../App";

///CSS

//MODULE

const ModalClassEdit = ({ toggleClassEdit, course, closeModal }) => {
    // const { config } = useContext(AppContext);

    return (
        <div className="overlay">
            <div className="modal-content">
                <div className="title-container">
                    <MdClose id="back-icon" onClick={() => closeModal()} />
                    <h1 className="title-box">{"EDITING COURSE: " + course.title}</h1>
                </div>
                <div className="info-container">
                    <FormBodyClassEdit toggleClassEdit={toggleClassEdit} course={course} />
                </div>
            </div>
        </div>
    );
};

export default ModalClassEdit;
