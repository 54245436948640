//REACT IMPORT
import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";

//CSS
import "../css/learner-classes.css";

//MODULE
import { AppContext } from "../../App";
import ClassCatalogueCard from "./ClassCatalogueCard";
import { getBaseUrl } from "../../baseURL";
import ReactPaginate from "react-paginate";

const ClassCatalogue = () => {
    const { learnerDisplay, setLearnerDisplay, config } =
        useContext(AppContext);
    const [classes, setClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setpageCount] = useState(0);
    const [isTyping, setIsTyping] = useState(false);

    let limit = 10;
    const globalTimeout = useRef(null);

    const fetchClasses = async (page) => {
        setIsLoading(true);
        try {
            const res = await axios.get(
                `${getBaseUrl()}/api/class/filtered?page=${page}&limit=${limit}`,
                config
            );
            const total = res.headers["x-total-count"];
            setpageCount(Math.ceil(total / limit));
            setClasses(res.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const searchClasses = async (value) => {
        try {
            const res = await axios.get(
                `${getBaseUrl()}/api/class?search=${value}`,
                config
            );
            setClasses(res.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setIsTyping(true);
        const value = e.target.value;
        setSearch(value);
        if (globalTimeout.current) {
            clearTimeout(globalTimeout.current);
        }
        globalTimeout.current = setTimeout(() => {
            setIsTyping(false);
            setIsLoading(true);
            searchClasses(value);
        }, 1500);
    };

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        fetchClasses(selectedPage);
    };

    useEffect(() => {
        fetchClasses(1);
    }, []);

    return (
        <div className="classes-wrapper">
            {/* ================= TITLE CONTAINER ===================*/}
            <div className="title-container" id="title-container-catalog">
                <div className="class-catalogue">
                    <p>COURSES FOR YOU TO ENROLL</p>
                </div>

                <div className="search-box">
                    <div className="input-container">
                        <FaSearch
                            className="paginationbox-icon"
                            id="search-icon"
                        />
                        <input
                            style={{
                                fontFamily: "Poppins",
                            }}
                            type="text"
                            placeholder="Type to search"
                            onChange={handleSearchChange}
                            className="input-field"
                        />
                    </div>
                </div>
            </div>

            {/* ================= CARD WRAPPER  ===================*/}

            <div
                className="card-wrapper"
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "space-between",
                }}
            >
                {isTyping ? (
                    <div
                        className="no-classes"
                        style={{
                            marginTop: 35,
                        }}
                    >
                        <p>Searching...</p>
                    </div>
                ) : (
                    <>
                        {isLoading ? (
                            <div
                                className="loading-animation"
                                id="classes-loading"
                                style={{
                                    flexGrow: 1,
                                }}
                            >
                                <img
                                    src="loading.gif"
                                    alt="loading"
                                    id="loading-gif-id"
                                />
                            </div>
                        ) : !classes.length ? (
                            <div
                                className="no-classes"
                                style={{
                                    marginTop: 35,
                                }}
                            >
                                <p>There are no courses for you.</p>
                            </div>
                        ) : (
                            <div
                                className="card-container"
                                id={`class-catalogue-container`}
                            >
                                {classes.map((classInfo) => (
                                    <ClassCatalogueCard
                                        classInfo={classInfo}
                                        key={classInfo._id}
                                    />
                                ))}
                            </div>
                        )}
                        {search === "" && (
                            <div className="admin-pagination-box">
                                <ReactPaginate
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ClassCatalogue;
