import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaFileExport, FaSearch } from "react-icons/fa";
import "../css/admin-users.css";
import CardUsers from "../../SpecialPages/components/CardUsers";
import { AppContext } from "../../App";
import ModalExport from "../../SpecialPages/components/ModalExport";
import { getBaseUrl } from "../../baseURL";

const AdminUsers = () => {
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;
  const { config } = useContext(AppContext);
  const [activityExport, setActivityExport] = useState(false);
  const toggleModalExport = () => {
    setActivityExport(!activityExport);
  };
  const closeModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    axios
      .get(`${getBaseUrl()}/api/user?page=1&limit=${limit}`, config)
      .then((res) => {
        const total = res.headers.get("X-Total-Count");
        setpageCount(Math.ceil(total / limit));
        setUsers(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [showModal]);

  useEffect(() => {
    axios
      .get(
        `${getBaseUrl()}/api/user?page=${currentPage}&limit=${limit}`,
        config
      )
      .then((res) => {
        const total = res.headers.get("X-Total-Count");
        setpageCount(Math.ceil(total / limit));
        setUsers([...users, ...res.data]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  function UserLists({ users }) {
    if (users.length > 0) {
      return (
        <div className="users-lists">
          <div className="column-name">
            <div className="column-box">
              <p>FULL NAME</p>
            </div>
            <div className="column-box">
              <p>EMAIL</p>
            </div>
            <div className="column-box">
              <p>ROLE</p>
            </div>
            <div className="column-box">
              <p>REGISTRATION</p>
            </div>
            <div className="column-box">
              <p>OPTIONS</p>
            </div>
          </div>
          {users
            .filter((user) => {
              const fullName = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;
              return (
                user.email.toLowerCase().includes(search.toLowerCase()) ||
                user.role.toLowerCase().includes(search.toLowerCase()) ||
                fullName.includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase())
              );
            })
            .map((user) => (
              <CardUsers
                key={user._id}
                user={user}
                setUsers={setUsers}
                closeModal={closeModal}
              />
            ))}
          {search &&
            search.length > 0 &&
            !users.filter((user) => {
              const fullName = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;
              return (
                user.email.toLowerCase().includes(search.toLowerCase()) ||
                user.role.toLowerCase().includes(search.toLowerCase()) ||
                fullName.includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase())
              );
            }).length && (
              <div className="empty-classes">
                <p>No results found for "{search}".</p>
              </div>
            )}
        </div>
      );
    } else {
      return (
        <div className="empty-users">
          <img src="zebra.png" alt="zebra png" id="zebra-logo" />

          <h1>There are no users as of the moment</h1>
        </div>
      );
    }
  }
  return (
    <div className="view-card">
      <div className="title-container">
        <div className="title-box">
          <h1>USERS</h1>
        </div>
        <div className="search-box">
          <div className="input-container">
            <FaSearch className="paginationbox-icon" id="search-icon" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              className="input-field"
            />
          </div>
          <FaFileExport
            title="Export Users"
            className="paginationbox-icon"
            onClick={() => toggleModalExport()}
          />
          {activityExport ? (
            <ModalExport
              toggleModalExport={toggleModalExport}
              isModalClassInfo={false}
              exportWhat={"users"}
            />
          ) : null}
        </div>
      </div>

      <div className="info-container">
        {isLoading ? (
          <div className="loading-animation">
            <img src="loading.gif" alt="loading" id="loading-gif-id" />
          </div>
        ) : (
          <>
            <InfiniteScroll
              dataLength={users.length}
              next={() => {
                setCurrentPage(currentPage + 1);
              }}
              hasMore={currentPage < pageCount}
              loader={
                <p style={{ textAlign: "center" }}>
                  {/* <h4>Loading...</h4> */}
                </p>
              }
              endMessage={
                <p className="end-message">{/* No more users to show. */}</p>
              }
            >
              <UserLists users={users} />
            </InfiniteScroll>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
