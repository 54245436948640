import React, { useState } from "react";

import DatePicker from "react-datepicker";
import { AiFillPlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

import "../css/session.css";

const Session = ({ sessions, setSessions }) => {
    const [session, setSession] = useState({
        capacity: "4",
        startTime: new Date(Date.now()),
        endTime: new Date(Date.now()),
        id: Date.now(),
    });

    const handleButtonClick = () => {
        setSessions([...sessions, session]);
        setSession({
            capacity: "",
            startTime: new Date(),
            endTime: new Date(),
            id: Date.now(),
        });
    };
    const handleChange = (id, field, value) => {
        setSessions((prevSessions) =>
            prevSessions.map((session) => {
                if (session.id === id) {
                    return {
                        ...session,
                        [field]: value,
                    };
                }
                return session;
            })
        );
    };

    const handleRemoveClick = (id) => {
        setSessions((prevSessions) => prevSessions.filter((session) => session.id !== id));
    };
    return (
        <>
            <div className="session-btn">
                {sessions.map((session) => (
                    <div className="duplicate-session" key={session._id || session.id}>
                        <div className="container-sessions">
                            <div className="session-wrapper">
                                <div className="start-container">
                                    <div className="session-container-start">
                                        <p>DATE START</p>
                                        <div className="date-container">
                                            <DatePicker
                                                selected={
                                                    typeof session.startTime === "string" ? new Date(session.startTime) : session.startTime
                                                }
                                                onChange={(date) => {
                                                    handleChange(session.id, "startTime", date);
                                                    handleChange(session.id, "endTime", date);
                                                }}
                                                className="time-date"
                                                minDate={new Date()}
                                                showPopperArrow={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="session-container-start">
                                        <p>TIME START</p>
                                        <div className="date-container">
                                            <DatePicker
                                                selected={
                                                    typeof session.startTime === "string" ? new Date(session.startTime) : session.startTime
                                                }
                                                onChange={(date) => {
                                                    handleChange(session.id, "startTime", date);
                                                    handleChange(session.id, "endTime", date);
                                                }}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Start Time"
                                                dateFormat="h:mm aa"
                                                className="time-date"
                                                showPopperArrow={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="end-container">
                                    <div className="session-container-end">
                                        <p>DATE END</p>
                                        <div className="date-container">
                                            <DatePicker
                                                selected={typeof session.endTime === "string" ? new Date(session.endTime) : session.endTime}
                                                onChange={(date) => handleChange(session.id, "endTime", date)}
                                                className="time-date"
                                                minDate={session.startTime}
                                                showPopperArrow={false}
                                            />
                                        </div>
                                    </div>

                                    <div className="session-container-end">
                                        <p>TIME END</p>
                                        <div className="date-container">
                                            <DatePicker
                                                selected={session.endTime}
                                                onChange={(date) => handleChange(session.id, "endTime", date)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="End Time"
                                                dateFormat="h:mm aa"
                                                className="time-date"
                                                showPopperArrow={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="capacity-container">
                                <p> CAPACITY</p>

                                <div className="input-container">
                                    <input
                                        type="number"
                                        value={session.capacity}
                                        placeholder="min. 4 - max 20 learners"
                                        onChange={(e) => handleChange(session.id, "capacity", e.target.value)}
                                        min="4"
                                        max="20"
                                        className="capacity"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="session-container">
                            <AiOutlineMinusCircle className="minus-icon" onClick={() => handleRemoveClick(session.id)} />
                        </div>
                    </div>
                ))}
            </div>

            <div className="personal-info">
                <div className="add-date-container">
                    <button onClick={handleButtonClick} className="add-date-btn" type="button">
                        <p className="add-date-text">Add date</p>

                        <AiFillPlusCircle className="plus-icon" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default Session;
