import React, { useState, useContext } from "react";
import { AppContext } from "../../App";
import axios from "axios";
import Select from "react-select";

import { IoCheckmarkCircleOutline } from "react-icons/io5";

import "../css/modal-class-edit.css";
import { getBaseUrl } from "../../baseURL";

const classCategory = [
  { value: "SZU Core", label: "SZU Core" },
  {
    value: "MasterclaSZ for Service Delivery",
    label: "MasterclaSZ for Service Delivery",
  },
  {
    value: "MasterclaSZ for Shared Services",
    label: "MasterclaSZ for Shared Services",
  },
  {
    value: "SZU Mandatory",
    label: "SZU Mandatory",
  },
  { value: "SZU Elective", label: "SZU Elective" },
  { value: "STEP Pre-Onboarding", label: "STEP Pre-Onboarding" },
  { value: "MTEP Pre-Onboarding", label: "MTEP Pre-Onboarding" },

];

const FormBodyClassEdit = ({
  setClassDetailsWindow,
  toggleClassEdit,
  course,
  classEditRender,
}) => {
  const [courseName, setCourseName] = useState(course.title);
  const [description, setDescription] = useState(course.description);
  const [category, setCategory] = useState(course.category);
  const { config, appState } = useContext(AppContext);

  const handleSelectCategory = (selected) => {
    setCategory(selected.value);
  };

  const [buttonPopUp, setButtonPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    const updatedFormData = {
      title: courseName || course.title,
      description: description || course.description,
      category: category || course.category,
    };
    try {
      const result = await axios.put(
        `${getBaseUrl()}/api/class/${course._id}`,
        updatedFormData,
        config
      );
      if (result.status === 201) {
        setButtonPopUp(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const EditSessionPop = () => {
    const handleButtonClick = () => {
      setButtonPopUp(false);
      if (appState.currentRole === "instructor") {
        setClassDetailsWindow("class-details");
        classEditRender();
      }
      setSuccessMessage("Updated successfully");
    };

    return (
      <>
        <div className="popup" id="popup-overlay">
          <div className="popup-inner">
            <div className="circle-box">
              <IoCheckmarkCircleOutline className="checkicon" />
              <h3>SUCCESS!</h3>
            </div>
            <div className="popup-txt-contr">
              <div className="txt-cont">
                <p className="bold-txt">Session updated Successfully</p>
                <p>
                  A session has been successfully updated. Check your Calendar
                  for details.
                </p>
              </div>
              <div className="btn-cont">
                <button className="close-btn" onClick={handleButtonClick}>
                  OK
                </button>
              </div>
            </div>
            {/* <div className="popup-txt-contr">
                            <div className="txt-cont"></div>
                            <div className="btn-cont"></div>
                        </div> */}
          </div>
        </div>
      </>
    );
  };
  return (
    // <div className="info-container" id={appState.currentRole === "instructor" && `instructor-edit-class`}>
    <>
      <form onSubmit={handleSubmit} className="class-update-container">
        <div className="personal-info">
          <p> Course name: </p>
          <div className="input-container">
            <input
              type="text"
              value={courseName}
              placeholder={course.title}
              onChange={(e) => setCourseName(e.target.value)}
            />
          </div>
        </div>
        <div className="personal-info">
          <p> Category:</p>
          <div className="dropdown-checkbox">
            <Select
              placeholder={category}
              options={classCategory}
              onChange={handleSelectCategory}
            />
          </div>
        </div>
        <div className="personal-info">
          <p> Description:</p>
          <div className="input-container">
            <textarea
              type="textarea"
              value={description}
              placeholder={course.description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
        <hr />
        {errorMessage && (
          <p className="error-message" id="end-position">
            {errorMessage}
          </p>
        )}
        <div className="btn-container">
          <button className={`submit-btn ${course}`} type="submit">
            UPDATE
          </button>
        </div>
      </form>

      {buttonPopUp && <EditSessionPop toggleClassEdit={toggleClassEdit} />}
    </>
  );
};

export default FormBodyClassEdit;
