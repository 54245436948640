import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AppContext } from "../../App";

import "../css/instructor-dashboard.css";
import ClassesToday from "./ClassesToday";
import MyCalendar from "./MyCalendar";
import { getBaseUrl } from "../../baseURL";

const InstructorOverview = () => {
  const { setInstructorDisplay, config } = useContext(AppContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${getBaseUrl()}/api/user/getUser`, config)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="first-container">
        {user ? (
          <div className="instructor-info-wrapper">
            <div className="logo-container">
              <img
                src="instructor.png"
                alt="instructor"
                id="instructor-image"
              />
            </div>
            <div className="instructor-boxname">
              <h1 id="instructor">Instructor</h1>

              <div id="instructor-info">
                <p id="employee-name">{`${user.firstName} ${user.lastName}`}</p>
                <p id="account-name">{user.jobLevel}</p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="classes-today-info">
          <ClassesToday />
        </div>
      </div>

      <div className="second-container">
        <div className="calendar-container">
          <div className="title-container">
            <p>CALENDAR</p>
            <button
              className="add-classbtn"
              onClick={() => {
                setInstructorDisplay("InstructorViewClasses");
              }}
            >
              COURSES
            </button>
          </div>
          <div className="calendar-container">
            <MyCalendar />
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructorOverview;
