import "../css/admin-classes.css";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FaFileExport, FaSearch } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import CardClasses from "../../SpecialPages/components/CardClasses";
import { AppContext } from "../../App";
import ModalExport from "../../SpecialPages/components/ModalExport";
import { getBaseUrl } from "../../baseURL";

const AdminClasses = () => {
  const [courses, setCourses] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const { config } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [activityExport, setActivityExport] = useState(false);
  const toggleModalExport = () => {
    setActivityExport(!activityExport);
  };
  const closeModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    axios
      .get(`${getBaseUrl()}/api/class?page=1&limit=${limit}`, config)
      .then((res) => {
        const total = res.headers.get("X-Total-Count");
        setpageCount(Math.ceil(total / limit));
        setCourses(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [showModal, config]);

  useEffect(() => {
    axios
      .get(
        `${getBaseUrl()}/api/class?page=${currentPage}&limit=${limit}`,
        config
      )
      .then((res) => {
        const total = res.headers.get("X-Total-Count");
        setpageCount(Math.ceil(total / limit));
        setCourses([...courses, ...res.data]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [currentPage, config]);

  function CoursesLists() {
    if (courses.length > 0) {
      return (
        <div className="courses-list">
          <div className="column-name">
            <div className="column-box">
              <p>COURSES</p>
            </div>
            <div className="column-box">
              <p>CATEGORY</p>
            </div>
            <div className="column-box">
              <p>REGISTRATION</p>
            </div>
            <div className="column-box">
              <p>OPTIONS</p>
            </div>
          </div>
          {courses
            .filter((course) => {
              return search.toLowerCase() === ""
                ? true
                : course.title.toLowerCase().includes(search.toLowerCase()) ||
                    course.category
                      .toLowerCase()
                      .includes(search.toLowerCase());
            })
            .map((course) => (
              <CardClasses
                course={course}
                key={course._id}
                closeModal={closeModal}
              />
            ))}
          {search &&
            search.length > 0 &&
            !courses.filter(
              (course) =>
                course.title.toLowerCase().includes(search.toLowerCase()) ||
                course.category.toLowerCase().includes(search.toLowerCase())
            ).length && (
              <div className="empty-classes">
                <p>No results found for "{search}".</p>
              </div>
            )}
        </div>
      );
    } else {
      return (
        <div className="empty-users">
          <img src="zebra.png" alt="zebra png" id="zebra-logo" />

          <h1>There are no classes as of the moment</h1>
        </div>
      );
    }
  }

  return (
    <div className="view-card">
      <div className="title-container">
        <div className="title-box">
          <h1>COURSES</h1>
        </div>
        <div className="search-box">
          <div className="input-container">
            <FaSearch className="paginationbox-icon" id="search-icon" />
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              className="input-field"
            />
          </div>
          <FaFileExport
            title="Export Courses"
            className="paginationbox-icon"
            onClick={() => toggleModalExport()}
          />
          {activityExport ? (
            <ModalExport
              toggleModalExport={toggleModalExport}
              isModalClassInfo={false}
              exportWhat={"classes"}
            />
          ) : null}
        </div>
      </div>

      <div className="info-container">
        {isLoading ? (
          <div className="loading-animation">
            <img src="loading.gif" alt="loading.gif" id="loading-gif-id" />
          </div>
        ) : (
          <>
            <InfiniteScroll
              dataLength={courses.length}
              next={() => {
                setCurrentPage(currentPage + 1);
              }}
              hasMore={currentPage < pageCount}
              loader={<p style={{ textAlign: "center" }}>Loading...</p>}
              // endMessage={<p className="end-message">No more class to show.</p>}
            >
              <CoursesLists />
            </InfiniteScroll>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminClasses;
