import React, { useState, useContext } from "react";
import "../css/modal-user-delete.css";
import axios from "axios";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { PiSealWarningFill } from "react-icons/pi";
import { AppContext } from "../../App";
import { getBaseUrl } from "../../baseURL";

const ModalClassDelete = ({
  toggleClassDelete,
  course,
  closeModal,
  isModalClassInfo,
  toggleClassInfo,
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const { config } = useContext(AppContext);

  const popupId = isModalClassInfo ? "popup-overlay" : undefined;

  const handleDelete = async () => {
    try {
      await axios
        .delete(`${getBaseUrl()}/api/class/${course._id}`, config)
        .then((res) => {
          if (res.status === 201) {
            setConfirmed(true);
            setShowDeleteSuccess(true);
          }
        });
    } catch (error) {
      setErrorMessage("An error has been encountered.");
    }
  };

  const DeleteSuccessful = () => {
    return (
      <>
        <div className="popup" id={popupId}>
          <div className="popup-inner">
            <div className="circle-box">
              <IoCheckmarkCircleOutline className="checkicon" />
              <h3>SUCCESS!</h3>
            </div>
            <div className="popup-txt-contr">
              <div className="txt-cont">
                <p>Course Deleted Successfully</p>
                <p className="bold-txt">
                  You have successfully deleted "{course.title}"".
                </p>
              </div>
              <div className="btn-cont">
                <button
                  className="close-btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {!confirmed && (
        <div className="popup" id={popupId}>
          <div className="popup-inner">
            <div className="circle-box" id="warning">
              <PiSealWarningFill className="checkicon" />
              <h3>DELETE CLASS?</h3>
            </div>
            <div className="popup-txt-contr">
              <div className="txt-cont">
                <p id="confirmation">Are you sure you want to delete:</p>
                <p className="bold-txt">{course.title}</p>
              </div>

              <div className="btn-cont delete-contr">
                <button className="close-btn " onClick={handleDelete} id="yes">
                  YES
                </button>
                <button
                  className="close-btn"
                  onClick={() => toggleClassDelete()}
                  id="cancel"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {errorMessage}
      {showDeleteSuccess && (
        <DeleteSuccessful
          course={course}
          closeModal={closeModal}
          isModalClassInfo={true}
          toggleClassInfo={toggleClassInfo}
          toggleClassDelete={toggleClassDelete}
        />
      )}
    </>
  );
};

export default ModalClassDelete;
