import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { CgClose } from "react-icons/cg";
import { AppContext } from "../../App";

import "../css/class-catalogue-modal.css";
import ClassSessionCard from "./ClassSessionCard";
import { getBaseUrl } from "../../baseURL";

const ClassCatalogueModal = ({ toggleClassInfo, _id, classInfo }) => {
  const { config } = useContext(AppContext);
  const [sessions, setSessions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShow, setisShow] = useState(true);

  const options = {
    hour: "numeric",
    minute: "numeric",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // const { instructor, title, description, category, createdAt, _id } = classInfo;
  const createdTimeConverted = new Date(classInfo.createdAt);
  const created = createdTimeConverted.toLocaleString(undefined, options);

  const closeModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`${getBaseUrl()}/api/class/${_id}/session`, config)
        .then((res) => {
          setSessions(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 800);
    // eslint-disable-next-line
  }, [showModal]);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleAnimate = () => {
    setisShow(false);
    toggleClassInfo();
  };

  return (
    <div
      className={`overlay ${isShow ? "fade-in" : "fade-out"}`}
      onClick={handleAnimate}
      id="enroll-overlay"
    >
      <div
        className={`modal-content ${isShow ? "fade-in" : "fade-out"}`}
        onClick={handleModalClick}
      >
        <div className="title-container">
          <CgClose
            className="close-icon"
            onClick={() => {
              setisShow(false);
              toggleClassInfo();
            }}
          />
          <p>COURSE DETAILS</p>
        </div>
        <div className="modal-card-wrapper">
          <div className="class-card-container">
            <div className="col-classTitle-container">
              <p className="class-title">{classInfo.title}</p>
              <p className="category">{classInfo.category}</p>
            </div>
            <div className="col-container">
              <p className="col-title">COURSE DESCRIPTION</p>
              <p className="course-description"><p>{classInfo.description}</p></p>
            </div>

            <div className="col-container">
              <p className="col-title">INSTRUCTOR/S</p>
              <div className="course-description">
                <p>
                  {classInfo.instructor
                    .map((inst) => " " + inst.firstName + " " + inst.lastName)
                    .join(", ")}
                </p>
              </div>

              <p className="course-description"></p>
            </div>
            <div className="col-container">
              {isLoading ? (
                //LETS TRY SKELETON LOADING
                <div className="skeleton-loading">
                  <p className="col-title"> </p>
                  <div className="sessions-table">
                    <div className="column-name">
                      <div className="column-box">
                        <p></p>
                      </div>
                      <div className="column-box">
                        <p></p>
                      </div>
                      <div className="column-box">
                        <p></p>
                      </div>
                      <div className="column-box">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // <div className="loading-animation">
                //     <img src="loading.gif" alt="loading" id="loading-gif-id" />
                // </div>
                <>
                  {sessions.length > 0 ? (
                    <>
                      <p className="col-title">SESSIONS</p>
                      <div className="sessions-table">
                        <div className="column-name">
                          <div className="column-box">
                            <p>
                              START <br></br>
                            </p>
                          </div>
                          <div className="column-box">
                            <p>
                              END <br></br>
                            </p>
                          </div>
                          <div className="column-box">
                            <p>CAPACITY</p>
                          </div>
                          <div className="column-box">
                            <p>OPTIONS</p>
                          </div>
                        </div>

                        <div className="user-data">
                          {sessions.map((session) => (
                            <ClassSessionCard
                              session={session}
                              key={session._id}
                              closeModal={closeModal}
                            />
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <p className="no-sessions">NO SESSIONS AVAILABLE</p>
                  )}
                </>
              )}
            </div>

            <p className="created">Created {created}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassCatalogueModal;
