import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import axios from "axios";

import PopUp from "../PopUp";
import PasswordValidator from "../PasswordValidator";
import "./forgotPassword.css";

import { BsShieldCheck } from "react-icons/bs";
import { FaLock, FaEyeSlash, FaEye } from "react-icons/fa";
import { getBaseUrl } from "../../../baseURL";

const ForgotChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const resetToken = new URLSearchParams(location.search).get("resetToken");
  const [buttonPopUp, setButtonPopUp] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    if (!resetToken || resetToken.length !== 40) {
      setErrorMessage("Invalid reset link. Please try again");
      navigate("/notfound");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("The passwords you entered do not match");
    } else {
      try {
        const res = await axios.put(
          `${getBaseUrl()}/api/user/resetpassword?resetToken=${resetToken}`,
          { password }
        );
        if (res.data.success) {
          setSuccessMessage(res.data.message);
          setButtonPopUp(true);
        } else {
          setErrorMessage(res.data.message);
        }
      } catch (err) {
        setErrorMessage(err.response.data.error);
      }
    }
  };

  const handleSignUp = () => {
    navigate("/");
  };

  const signupPop = () => {
    return (
      <>
        <div className="circle-box">
          <BsShieldCheck className="checkicon" />
          <h3>SUCCESS!</h3>
        </div>
        <div className="popup-txt-contr">
          <div className="txt-cont">
            <p>You're password has been reset!</p>
            <p>Redirecting to login</p>
          </div>
          <div className="btn-cont"></div>
        </div>
      </>
    );
  };
  return (
    <div className="forgot-reset-main-container">
      <form onSubmit={handleSubmit} className="form-container">
        <img
          src="reset-password.png"
          alt="resest-password"
          id="forgot-reset-logo"
        />

        <div className="forgot-password-container">
          <h2>Change your password</h2>

          <div>
            <p>Enter your your new password</p>

            <div className="set-password">
              <PasswordValidator password={password} />
            </div>
          </div>

          <div className="input-container">
            <FaLock className="icon" />
            <input
              required
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New password"
              className="input-field"
            />
          </div>

          <div className="input-container">
            <FaLock className="icon" />
            <input
              required
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password"
              className="input-field"
            />
          </div>

          {errorMessage && (
            <p className="error-message" id="end-position">
              {errorMessage}
            </p>
          )}

          <button className="reset-password-button" type="submit">
            RESET PASSWORD
          </button>
          <PopUp
            trigger={buttonPopUp}
            setTrigger={setButtonPopUp}
            popUp={signupPop}
          />

          <div style={{ textAlign: "center" }}>
            <span id="account-text">Remembered your account? </span>
            <span id="click-here" onClick={handleSignUp}>
              Login here
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotChangePassword;
