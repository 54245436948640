import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../App";
import axios from "axios";
import "../css/admin-overview.css";
import { getBaseUrl } from "../../baseURL";

const AdminOverview = () => {
  const { appState, config } = useContext(AppContext);
  const [users, setUsers] = useState({});
  const [sessions, setSessions] = useState({});
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersRes, sessionsRes, userDetailsRes] = await Promise.all([
          axios.get(`${getBaseUrl()}/api/user/overview`, config),
          axios.get(`${getBaseUrl()}/api/session`, config),
          axios.get(`${getBaseUrl()}/api/user/getUser`, config),
        ]);
        const active = await sessionsRes.data.filter(
          (session) => new Date(session.endTime) > new Date()
        );
        const completed = await sessionsRes.data.filter(
          (session) => new Date(session.endTime) < new Date()
        );
        const today = await sessionsRes.data.filter(
          (session) =>
            new Date(session.startTime).toDateString() ===
            new Date().toDateString()
        );
        setUsers(usersRes.data);
        setSessions({ active, completed, today });
        setUserDetails(userDetailsRes.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="main-container">
      {Object.keys(userDetails).length === 0 ? null : (
        <div className="admin-name">
          <img src="admin.png" alt="admin" id="admin-image" />
          <div id="admin-boxname">
            <h1 id="administrator">
              {userDetails.role[0].toUpperCase() + userDetails.role.slice(1)}
            </h1>
            <div id="admin-info">
              <p id="employee-name">
                {userDetails.firstName + " " + userDetails.lastName}
              </p>
              <p id="job-position">{userDetails.jobLevel}</p>
            </div>
          </div>
        </div>
      )}
      <div className="card-overview">
        <div className="info-container">
          <div className="title-container">
            <p>Overview</p>
          </div>
          <div className="stats-container">
            <div id="learners-count">
              <div id="wrapper">
                <p className="count">
                  {users.learners ? users.learners.length : null}
                </p>

                <p className="count-name">LEARNERS</p>
              </div>
            </div>

            <div id="instructors-count">
              <div id="wrapper">
                <p className="count">
                  {users.instructors ? users.instructors.length : null}
                </p>
                <p className="count-name">INSTRUCTORS</p>
              </div>
            </div>

            <div id="admins-count">
              <div id="wrapper">
                <p className="count">
                  {users.admins ? users.admins.length : null}
                </p>
                <p className="count-name">ADMINS</p>
              </div>
            </div>

            <div id="active-classes">
              <div id="wrapper">
                <p className="count">
                  {sessions.active ? sessions.active.length : null}
                </p>
                <p className="count-name">ACTIVE SESSIONS</p>
              </div>
            </div>

            <div id="classes-completed">
              <div id="wrapper">
                <p className="count">
                  {sessions.completed ? sessions.completed.length : null}
                </p>
                <p className="count-name">SESSIONS COMPLETED</p>
              </div>
            </div>

            <div id="classes-today">
              <div id="wrapper">
                <p className="count">
                  {sessions.today ? sessions.today.length : null}
                </p>
                <p className="count-name">SESSIONS TODAY</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOverview;
